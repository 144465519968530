
  
export const pathForbidden = "/403"
export const pathHome = "/crm"
export const pathSignin = "/crm/login"
export const pathExamen = "/examen/:idColegio"
export const pathAreaAlumno = "/area-alumno"
export const pathSigninAlumno = '/login'
export const pathFormularioNewAlumno = '/formulario-alumnos'
export const pathFormularioModifyAlumno = '/edit-alumno'
export const pathFormularioNewColegio = '/formulario-colegios'
export const pathImagesExamen = '/images/examenSat'

export const urls = [
  "/formulario-colegios",
  "/formulario-alumnos",
  "/InformeW2A/PDF",
  "/informe-alumnos"
 ]

export const addItemsLoaded = 50

export const provincias = {
  a_coruna: {texto:'A Coruña', visible:true},
  alava: {texto:'Álava', visible:true},
  albacete: {texto:'Albacete', visible:true},
  alicante: {texto:'Alicante', visible:true},
  almeria: {texto:'Almería', visible:true},
  asturias: {texto:'Asturias', visible:true},
  avila: {texto:'Ávila', visible:true},
  badajoz: {texto:'Badajoz', visible:true},
  baleares: {texto:'Baleares', visible:true},
  barcelona: {texto:'Barcelona', visible:true},
  burgos: {texto:'Burgos', visible:true},
  caceres: {texto:'Cáceres', visible:true},
  cadiz: {texto:'Cádiz', visible:true},
  cantabria: {texto:'Cantabria', visible:true},
  castellon: {texto:'Castellón', visible:true},
  ceuta: {texto:'Ceuta', visible:true},
  ciudad_real: {texto:'Ciudad Real', visible:true},
  cordoba: {texto:'Córdoba', visible:true},
  cuenca: {texto:'Cuenca', visible:true},
  girona: {texto:'Girona', visible:true},
  granada: {texto:'Granada', visible:true},
  guadalajara: {texto:'Guadalajara', visible:true},
  guipuzcoa: {texto:'Guipúzcoa', visible:true},
  huelva: {texto:'Huelva', visible:true},
  huesca: {texto:'Huesca', visible:true},
  jaen: {texto:'Jaén', visible:true},
  la_rioja: {texto:'La rioja', visible:true},
  las_palmas: {texto:'Las palmas', visible:true},
  leon: {texto:'León', visible:true},
  lleida: {texto:'Lleida', visible:true},
  lugo: {texto:'Lugo', visible:true},
  madrid: {texto:'Madrid', visible:true},
  malaga: {texto:'Málaga', visible:true},
  melilla: {texto:'Melilla', visible:true},
  murcia: {texto:'Murcia', visible:true},
  navarra: {texto:'Navarra', visible:true},
  ourense: {texto:'Ourense', visible:true},
  palencia: {texto:'Palencia', visible:true},
  pontevedra: {texto:'Pontevedra', visible:true},
  salamanca: {texto:'Salamanca', visible:true},
  tenerife: {texto:'Tenerife', visible:true},
  segovia: {texto:'Segovia', visible:true},
  sevilla: {texto:'Sevilla', visible:true},
  soria: {texto:'Soria', visible:true},
  tarragona: {texto:'Tarragona', visible:true},
  teruel: {texto:'Teruel', visible:true},
  toledo: {texto:'Toledo', visible:true},
  valencia: {texto:'Valencia', visible:true},
  valladolid: {texto:'Valladolid', visible:true},
  vizcaya: {texto:'Vizcaya', visible:true},
  zamora: {texto:'Zamora', visible:true},
  zaragoza: {texto:'Zaragoza', visible:true}
}

export const opcionesCursoActual = ['4º ESO','1º BACHILLERATO']

export const opcionesNivelIngles = ['Bajo','Medio','Alto','Bilingüe']

export const opcionesTitulacionIngles = ['A2','B1','B2','C1','C2','Ninguna']

export const opcionesSexo = ['Hombre', 'Mujer', 'Prefiero no contestar']

export const opcionesEstudiarFueraSpain = ['Sí, seguro','Es una opción','No, no lo haría']

export const opcionesTipoCarrera = [
  "Rama jurídica, abogado, juez...",
  "Rama empresa, economista, administración de empresas...",
  "Rama atención personas, psicología, trabajo social...",
  "Rama comunicación, periodismo, marketing...",
  "Artes escénicas, música...",
  "Artes plásticas, bellas artes...",
  "Artes digitales, diseño, animación...",
  "Filologías e idiomas...",
  "Historia...",
  "Salud, medicina, enfermería...",
  "Ciencias aplicadas, biología...",
  "Ciencias específicas, física, química, matemáticas...",
  "Ingeniería y arquitectura",
  "Deporte",
]

export const opcionesTipoInscripcion = ['Académica','Deportiva']

export const opcionesTiposActividadAcademica = ['Arte','Música','Empresa','Ciencia','Idiomas','Deporte no federado', 'Scouts o grupos de ocio','Otros']

export const opcionesYearsDedication = ["1","2","3","4"]

export const opcionesHorasSemanales = ["1","2","3","4","5","6","7","8","9","10"]

export const opcionesLugarExperiencia = ['EE.UU','Canada','Inglaterra','Escocia','Irlanda', 'Europa', 'Sudamérica', 'África', 'Asia', 'Australia']

export const opcionesMesesExperiencia = ['1','2','3','4','Más de 5']

export const opcionesObjetivoExperiencia = ['Estudios','Ocio']

export const opcionesDeporte = ['Atletismo','Baloncesto','Deportes de invierno','Esgrima','Fútbol','Gimnasia Deportiva','Gimnasia Rítmica','Golf','Hípica','Hockey Hielo','Hockey Hierba','Hockey Patines','Lucha Olímpica','Natación','Remo','Tenis','Voleibol','Waterpolo','Otros']

export const opcionesTipoDeporte = ['Equipo','Individual']

export const opcionesDivisionDeporte = ['Competición local','Competición regional', 'Competición regional en fase final', 'Competición nacional']

export const opcionesFederado = ['Si','No']

export const opcionesCategoriaDeporte = ['Cadete','Junior','Senior']

export const opcionesTipoColegios = ['Privado', 'Concertado', 'Público']

export const genderOptions = ['Hombre', 'Mujer', 'Prefiero no contestar']

export const opcionesSedeSat = ['Barcelona','Bilbao','Galicia','Gran Canaria','Madrid','Sevilla','Valladolid','Valencia']

export const opcionesPreferenciaPaises = [
  {id:'EE.UU', text:'EE.UU', idPregunta:125},
  {id:'Canadá', text:'Canadá', idPregunta:126},
  {id:'Inglaterra', text:'Inglaterra', idPregunta:127},
  {id:'Escocia', text:'Escocia', idPregunta:128},
  {id:'Irlanda', text:'Irlanda', idPregunta:129},
  {id:'España', text:'España', idPregunta:130},
]
export const opcionesPreferenciaPaisesIds = {
  6:{id:'EE.UU', text:'EE.UU'},
  5:{id:'Canadá', text:'Canadá'},
  4:{id:'Inglaterra', text:'Inglaterra'},
  3:{id:'Escocia', text:'Escocia'},
  2:{id:'Irlanda', text:'Irlanda'},
  1:{id:'España', text:'España'},
}

export const opcionesCuestionario = {
  preguntas:{
    1: {
      tipo:"reordenar",
      pregunta:"Tienes una tarde libre, y puedes elegir entre una lista de cosas que puedes hacer. Ordena según tus INTERESES qué actividades harías, siendo la 1º (más arriba) la que más te apetecería, y la 8º (más abajo) la que menos.",
      respuestasIniciales:["1","2","3","4","5","6","7","8"],
      respuestas:[
        {idPregunta:"1", text:'Coger un buen libro, y sentarme a leer'},
        {idPregunta:"2", text:'Jugar a un juego (de mesa o videojuego) que tenga puzles o problemas en los que tenga que pensar'},
        {idPregunta:"3", text:'Hacer una maqueta'},
        {idPregunta:"4", text:'Ir a una fiesta donde tampoco conozco a tanta gente'},
        {idPregunta:"5", text:'Visitar algún sitio que me apetezca (turístico, cine, concierto…) yo solo'},
        {idPregunta:"6", text:'Hacer una excursión al campo con mi mascota'},
        {idPregunta:"7", text:'Ir a un concierto'},
        {idPregunta:"8", text:'Ir a hacer mi deporte favorito'}
      ]
    },
    2:{
      tipo:"radiobutton",
      pregunta:`A continuación verás una lista de actividades, y a la derecha podrás marcar tu INTERÉS por las mismas, desde "no me interesa nada" (1) hasta "me encanta, ya lo hago; o me encantaría hacerlo" (4)`,
      respuestasIniciales:["0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0"],
      respuestas:[
        {idPregunta:"9", valor:"0", check:false, text:'Descubrir nuevos libros y autores interesantes'},
        {idPregunta:"10", valor:"0", check:false, text:'Sentarme a escribir, ya sea un artículo, una narración, poesía…'},
        {idPregunta:"11", valor:"0", check:false, text:'Hacer experimentos, o construir algo (con ordenadores, elementos químicos…)'},
        {idPregunta:"12", valor:"0", check:false, text:'Jugar a juegos de mesa que tengan alguna dificultad'},
        {idPregunta:"13", valor:"0", check:false, text:'Hacer maquetas, puzles, manualidades…'},
        {idPregunta:"14", valor:"0", check:false, text:'Usar programas de edición de imagen, vídeo (Ilustrator, Photoshop, Premier…)'},
        {idPregunta:"15", valor:"0", check:false, text:'Hacer nuevos amigos'},
        {idPregunta:"16", valor:"0", check:false, text:'Explicar un tema a alguien de tal forma que lo entienda'},
        {idPregunta:"17", valor:"0", check:false, text:'Me interesa ser independiente, valerme por mí mismo'},
        {idPregunta:"18", valor:"0", check:false, text:'Quiero asumir responsabilidades'},
        {idPregunta:"19", valor:"0", check:false, text:'Ir a una excursión al monte para ver animales y/o plantas'},
        {idPregunta:"20", valor:"0", check:false, text:'Dar un gran paseo con mi mascota, o pasar tiempo con ella'},
        {idPregunta:"21", valor:"0", check:false, text:'Componer música'},
        {idPregunta:"22", valor:"0", check:false, text:'Tocar un instrumento en un grupo'},
        {idPregunta:"23", valor:"0", check:false, text:'Practicar un deporte, el que más me apetezca'},
        {idPregunta:"24", valor:"0", check:false, text:'Hacer actividad física, yendo al gimnasio, a alguna clase de yoga, pilates o similar'},
      ]
    },
    3: {
      tipo:"radiobutton",
      respuestasIniciales:["0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0"],
      pregunta:`A continuación verás una lista de actividades, y a la derecha podrás marcar tu HABILIDAD por las mismas, desde "se me da fatal"  (1) hasta "se me da genial"  (4)`,
      respuestas:[
        {idPregunta:"25", valor:"0", check:false, text:'Comprender las ideas principales cuando leo una noticia'},
        {idPregunta:"26", valor:"0", check:false, text:'Exponer mis ideas de forma ordenada, ya sea hablando o escribiendo.'},
        {idPregunta:"27", valor:"0", check:false, text:'Comprender estadísticas de encuestas o censos'},
        {idPregunta:"28", valor:"0", check:false, text:'Realizar operaciones matemáticas de cabeza'},
        {idPregunta:"29", valor:"0", check:false, text:'Dibujar motivos o imágenes con precisión'},
        {idPregunta:"30", valor:"0", check:false, text:'Interpretar planos y orientarme cuando visito lugares nuevos'},
        {idPregunta:"31", valor:"0", check:false, text:'Aconsejar a mis amigos cuando tienen problemas personales'},
        {idPregunta:"32", valor:"0", check:false, text:'Hablar en público'},
        {idPregunta:"33", valor:"0", check:false, text:'Saber lo que estoy sintiendo, y controlar mis emociones'},
        {idPregunta:"34", valor:"0", check:false, text:'Conocer mis puntos fuertes y mis aspectos a mejorar'},
        {idPregunta:"35", valor:"0", check:false, text:'Cuidar de plantas'},
        {idPregunta:"36", valor:"0", check:false, text:'Cuidar y adiestrar animales'},
        {idPregunta:"37", valor:"0", check:false, text:'Leer partituras'},
        {idPregunta:"38", valor:"0", check:false, text:'Aprender a tocar un instrumento'},
        {idPregunta:"38", valor:"0", check:false, text:'Realizar actividades que requieran de precisión, hacer y pintar maquetas…'},
        {idPregunta:"40", valor:"0", check:false, text:'Tengo buena puntería'},
      ]
    },
    4:{
      tipo:"reordenar",
      respuestasIniciales:["41","42","43","44","45","46","47","48"],
      pregunta:`A continuación veras una lista de tareas típicamente escolares, Ordena según tu HABILIDAD qué actividades se te dan mejor o peor, siendo  "Se me da genial" la 1º (más arriba) y "Se me da fatal" la 8º (más abajo).`,
      respuestas:[
        {idPregunta:"41", text:'Hacer comentarios de texto'},
        {idPregunta:"42", text:'Hacer problemas de física, química, o matemáticas'},
        {idPregunta:"43", text:'Ejercicios de dibujo técnico'},
        {idPregunta:"44", text:'Liderar trabajos en grupo'},
        {idPregunta:"45", text:'Ejercicios donde tenga que describirme a mí mismo'},
        {idPregunta:"46", text:'Trabajos de biología'},
        {idPregunta:"47", text:'Tocar los instrumentos que me mandan en clase de música'},
        {idPregunta:"48", text:'La mayoría de ejercicios de la clase de educación física'},

      ]
    },
    5:{
      tipo:"multiple",
      respuestasIniciales:[{nota:"0",gusto:"0"},{nota:"0",gusto:"0"},{nota:"0",gusto:"0"},{nota:"0",gusto:"0"},{nota:"0",gusto:"0"},{nota:"0",gusto:"0"},{nota:"0",gusto:"0"},{nota:"0",gusto:"0"},{nota:"0",gusto:"0"},{nota:"0",gusto:"0"},{nota:"0",gusto:"0"},{nota:"0",gusto:"0"},{nota:"0",gusto:"0"},{nota:"0",gusto:"0"},],
      pregunta:`A continuación veras una lista de asignaturas que has cursado, o estás cursando. Has de poner la nota APROXIMADA que sueles, o esperas, sacar en estas asignaturas, así como el grado en el que te gusten.`,
      respuestas:[
        { idPregunta:"49", preguntas:{nota:"0",gusto:"0"}, text:'Lengua Castellana y Literatura'},
        { idPregunta:"50", preguntas:{nota:"0",gusto:"0"}, text:'Idiomas (Inglés, Francés, Alemán…)'},
        { idPregunta:"51", preguntas:{nota:"0",gusto:"0"}, text:'Filosofía'},
        { idPregunta:"52", preguntas:{nota:"0",gusto:"0"}, text:'Economía'},

        { idPregunta:"53", preguntas:{nota:"0",gusto:"0"}, text:'Geografía e Historia'},
        { idPregunta:"54", preguntas:{nota:"0",gusto:"0"}, text:'Latín'},
        { idPregunta:"55", preguntas:{nota:"0",gusto:"0"}, text:'Matemáticas'},
        { idPregunta:"56", preguntas:{nota:"0",gusto:"0"}, text:'Física y Química'},
        { idPregunta:"57", preguntas:{nota:"0",gusto:"0"}, text:'Biología y Geología'},
        { idPregunta:"58", preguntas:{nota:"0",gusto:"0"}, text:'Dibujo técnico'},
        { idPregunta:"59", preguntas:{nota:"0",gusto:"0"}, text:'Tecnología'},
        { idPregunta:"60", preguntas:{nota:"0",gusto:"0"}, text:'Educación Física'},
        { idPregunta:"61", preguntas:{nota:"0",gusto:"0"}, text:'Música'},
        { idPregunta:"62", preguntas:{nota:"0",gusto:"0"}, text:'Educación plástica y visual'},
      ]
    },
    6:{
      tipo:"radiobutton",
      respuestasIniciales:["0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0"],
      pregunta:`Cuando te incorpores al mundo laboral, quieres trabajos donde…`,
      respuestas:[
        {idPregunta:"63", valor:"0", check:false, text:'Tenga que contar y explicar conceptos (con noticias, lecciones…)'},
        {idPregunta:"64", valor:"0", check:false, text:'Tenga que convencer a alguien (clientes, votantes…)'},
        {idPregunta:"65", valor:"0", check:false, text:'Tenga que hacer cálculos'},
        {idPregunta:"66", valor:"0", check:false, text:'Tenga que hablar de temas como dinero, física, química o tecnología'},
        {idPregunta:"67", valor:"0", check:false, text:'Tenga que diseñar piezas, imágenes, construcciones…'},
        {idPregunta:"68", valor:"0", check:false, text:'Tenga que crear arte visual, como cuadros, cine, escultura, fotografía…'},
        {idPregunta:"69", valor:"0", check:false, text:'Tenga que defender los derechos de otras personas'},
        {idPregunta:"70", valor:"0", check:false, text:'Tenga que ayudar en temas personales, aportando a otras personas'},
        {idPregunta:"71", valor:"0", check:false, text:'Tenga que reflexionar profundamente sobre la condición humana y el porqué de muchas cosas'},
        {idPregunta:"72", valor:"0", check:false, text:'Tenga que usar mi experiencia personal para ayudar a los demás'},
        {idPregunta:"73", valor:"0", check:false, text:'Tenga que estar en contacto directo con la naturaleza'},
        {idPregunta:"74", valor:"0", check:false, text:'Tenga que hacer experimentos para comprender mejor el mundo que me rodea'},
        {idPregunta:"75", valor:"0", check:false, text:'Tenga que tocar algún instrumento'},
        {idPregunta:"76", valor:"0", check:false, text:'Tenga que estar en contacto directo con la música'},
        {idPregunta:"77", valor:"0", check:false, text:'Tenga que tener precisión con mis manos'},
        {idPregunta:"78", valor:"0", check:false, text:'Tenga que hacer deporte y/o actividad física'},
      ]
    },
    7:{
      tipo:"radiobutton",
      respuestasIniciales:["0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0"],
      pregunta:`A continuación verás características que suelen tener los trabajos, has de pensar qué valoras y cuáles serían tus prioridades para cuando comiences a trabajar`,
      respuestas:[
        {idPregunta:"79", valor:"0", check:false, text:'Valoro tener tiempo para mi después de mi jornada laboral'},
        {idPregunta:"80", valor:"0", check:false, text:'Valoraría trabajar en horarios poco comunes (fines de semana , festivos…)  si mi trabajo me gustase'},
        {idPregunta:"81", valor:"0", check:false, text:'Valoro el poder ir eligiendo los proyectos laborales donde trabajar'},
        {idPregunta:"82", valor:"0", check:false, text:'Valoro el que me vayan dando trabajo que hacer, y no tener que buscarlo yo'},
        {idPregunta:"83", valor:"0", check:false, text:'Valoraría que mi trabajo fuese supervisado por un superior'},
        {idPregunta:"84", valor:"0", check:false, text:'Prefiero ser mi propio jefe'},
        {idPregunta:"85", valor:"0", check:false, text:'Valoro positivamente que mi profesión sea de prestigio'},
        {idPregunta:"86", valor:"0", check:false, text:'El prestigio en mi profesión no es algo prioritario'},
        {idPregunta:"87", valor:"0", check:false, text:'Valoro que mi trabajo se centre en ayudar a las personas'},
        {idPregunta:"88", valor:"0", check:false, text:'Para mi, no es prioritario que, en mi trabajo, tenga que ayudar a personas'},
        {idPregunta:"89", valor:"0", check:false, text:'En mi futuro trabajo he de poder crear y ser creativo'},
        {idPregunta:"90", valor:"0", check:false, text:'Prefiero un trabajo en el que tenga mecanizado mi labor'},
        {idPregunta:"91", valor:"0", check:false, text:'Valoro un trabajo en el que tenga que tener mucho contacto con la gente'},
        {idPregunta:"92", valor:"0", check:false, text:'Prefiero un trabajo en el que desarrolle mis tareas yo solo'},
        {idPregunta:"93", valor:"0", check:false, text:'Prefiero un trabajo en el que tenga que asumir responsabilidades'},
        {idPregunta:"94", valor:"0", check:false, text:'Valoro que en mi trabajo los problemas y éxitos los asuma mi responsable'},
        {idPregunta:"95", valor:"0", check:false, text:'Valoro de un trabajo la seguridad económica que ofrezca'},
        {idPregunta:"96", valor:"0", check:false, text:'Si me gusta mi trabajo, asumiría no tener tanta seguridad económica como en otros'},
        {idPregunta:"97", valor:"0", check:false, text:'Una prioridad para mi es tener un buen sueldo'},
        {idPregunta:"98", valor:"0", check:false, text:'Mi salario no es algo tan importante, hay otras prioridades'},
        {idPregunta:"99", valor:"0", check:false, text:'Valoro la rutina en mi trabajo, y hacer casi siempre las mismas tareas'},
        {idPregunta:"100", valor:"0", check:false, text:'Prefiero un trabajo en el que haga tareas diferentes'},
      ]
    },
    8:{
      tipo:"reordenar",
      respuestasIniciales:["101","102","103","104","105","106","107","108","109","110","111",],
      pregunta:`A continuación veras una lista de características que pueden tener un trabajo. Ordena según tus PRIORIDADES, siendo  "Totalmente prioritario para mi" la 1º (más arriba) y "Nada prioritario para mi" la 8º (más abajo).`,
      respuestas:[
        {idPregunta:"101", text:'Tener tiempo libre fuera de mi trabajo'},
        {idPregunta:"102", text:'Que mi trabajo me permita tener independencia para trabajar'},
        {idPregunta:"103", text:'Que mi trabajo esté supervisado por un superior'},
        {idPregunta:"104", text:'Que mi trabajo me aporte prestigio laboral y/o personal'},
        {idPregunta:"105", text:'Que mi trabajo se centre, mayoritariamente, en ayudar a los demás'},
        {idPregunta:"106", text:'Que mi trabajo tenga una carga creativa constante'},
        {idPregunta:"107", text:'Que mi trabajo consista en tener mucho contacto con personas'},
        {idPregunta:"108", text:'Asumir responsabilidades'},
        {idPregunta:"109", text:'Que mi trabajo me de seguridad y estabilidad'},
        {idPregunta:"110", text:'Buen sueldo'},
        {idPregunta:"111", text:'Que mi trabajo sea sencillo y rutinario'},
      ]
    },
    9:{
      tipo:"checkbox",
      respuestasIniciales:["","",'false','false','false','false','false','false','false','false','false','false','false' ],
      pregunta:`En el caso de que quieras que tu camino académico después del Bachillerato esté encaminado en profesiones como las que ves a continuación, marca las que te interesarían.`,
      respuestas:[
        {idPregunta:"112", valor:"", type:"input", text:'Profesión de tu madre'},
        {idPregunta:"113", valor:"", type:"input", text:'Profesión de tu padre'},
        {idPregunta:"114", check:'false', type:"checkbox", text:'Agricultura'},
        {idPregunta:"115", check:'false', type:"checkbox", text:'Conductor, chófer, transportista'},
        {idPregunta:"116", check:'false', type:"checkbox", text:'Construcción, urbanística o mecánica'},
        {idPregunta:"117", check:'false', type:"checkbox", text:'Electricidad y electrónica'},
        {idPregunta:"118", check:'false', type:"checkbox", text:'Hostelería'},
        {idPregunta:"119", check:'false', type:"checkbox", text:'Estilismo'},
        {idPregunta:"120", check:'false', type:"checkbox", text:'Técnico de sonido o imagen'},
        {idPregunta:"121", check:'false', type:"checkbox", text:'Patronaje y moda'},
        {idPregunta:"122", check:'false', type:"checkbox", text:'Técnico en automoción'},
        {idPregunta:"123", check:'false', type:"checkbox", text:'Trabajo en eventos'},
        {idPregunta:"124", check:'false', type:"checkbox", text:'Artes escénicas o instrumentos'},
      ]
    },
    10:{
      tipo:"reordenar",
      respuestasIniciales:["125","126","127","128","129","130"],
      pregunta:`A continuación veras una lista de países , ordena según tu INTERÉS por irte a estudiar a estos destinos. El país que más te guste arriba, y el que menos abajo.  `,
      respuestas:[
        {idPregunta:"125", text:'Estados Unidos'},
        {idPregunta:"126", text:'Canadá'},
        {idPregunta:"127", text:'Inglaterra'},
        {idPregunta:"128", text:'Irlanda'},
        {idPregunta:"129", text:'Escocia'},
        {idPregunta:"130", text:'España'},
      ]
    }
  },
  puntuaciones:{

    "HG-L":{ids:["9","10","25","26","41"]},
    "HG-LM":{ids:["11","12","27","28","42"]},
    "HG-VE":{ids:["13","14","29","30","43"]},
    "HG-Ie":{ids:["15","16","31","32","44"]},
    "HG-Ia":{ids:["17","18","33","34","45"]},
    "HG-N":{ids:["19","20","35","36","46"]},
    "HG-M":{ids:["21","22","37","38","47"]},
    "HG-K":{ids:["23","24","39","40","48"]},

    "IG-L":{ids:["1","9","10","63","64"]},
    "IG-LM":{ids:["2","11","12","65","66"]},
    "IG-VE":{ids:["3","13","14","67","68"]},
    "IG-Ie":{ids:["4","15","16","69","70"]},
    "IG-Ia":{ids:["5","17","18","71","72"]},
    "IG-N":{ids:["6","19","20","73","74"]},
    "IG-M":{ids:["7","21","22","75","76"]},
    "IG-K":{ids:["8","23","24","77","78"]},

    "HA-CS-J":{ids:["24","26","31","32","41","44","49","49-b","51","51-b"]},
    "HA-CS-E":{ids:["27","28","44","52","52-b","55","55-b"]},
    "HA-CS-AP":{ids:["36","37","38","39","49","49-b","50","50-b","56","56-b"]},
    "HA-CS-C":{ids:["31","32","45","52","52-b"]},
    "HA-A-EM":{ids:["37","38","40","47","61","61-b","62","62-b"]},
    "HA-A-P":{ids:["29","39","43","58","58-b","62"]},
    "HA-A-D":{ids:["29","39","43","58","58-b","59","59-b","62","62-b"]},
    "HA-H-FI":{ids:["25","26","41","49","49-b","50","50-b","51","51-b","54","54-b"]},
    "HA-H-H":{ids:["25","26","41","49","49-b","51","51-b","53","53-b","54","54-b"]},
    "HA-C-S":{ids:["31","32","46","56","56-b","57","57-b"]},
    "HA-C-A":{ids:["35","36","46"]},
    "HA-C-E":{ids:["27","28","46","55","55-b","56","56-b","57","57-b"]},
    "HA-IA":{ids:["27","28","29","30","39","42","43","55","55-b","56","56-b","58","58-b","59","59-b"]},
    "HA-D":{ids:["39","40","48","60","60-b"]},

    "IA-CS-J":{ids:["1","9","10","49","49-b","51","51-b","63","64","69","70","71"]},
    "IA-CS-E":{ids:["2","18","52","52-b","55","55-b","65","66"]},
    "IA-CS-AP":{ids:["4","5","15","16","17","51","51-b","63","65","69","70","72"]},
    "IA-CS-C":{ids:["16","51","51-b","63","64","66","68"]},
    "IA-A-EM":{ids:["7","10","21","22","61","61-b","62","62-b","68","75","76","77"]},
    "IA-A-P":{ids:["3","13","14","58","58-b","62","62-b","67","68","77"]},
    "IA-A-D":{ids:["3","13","14","58","58-b","59","59-b","62","62-b","67","68","77"]},
    "IA-H-FI":{ids:["1","9","10","49","49-b","50","50-b","51","51-b","54","54-b","63","64"]},
    "IA-H-H":{ids:["1","9","10","49","49-b","51","51-b","53","53-b","54","54-b","63","71"]},
    "IA-C-S":{ids:["4","11","12","13","15","16","18","19","20","56","56-b","57","57-b","66","69","70","72","74"]},
    "IA-C-A":{ids:["6","11","12","13","19","20","56","56-b","57","57-b","66","73","74"]},
    "IA-C-E":{ids:["2","3","11","12","13","19","20","55","55-b","56","56-b","57","57-b","65","74"]},
    "IA-IA":{ids:["2","3","11","12","13","14","55","55-b","56","56-b","58","58-b","59","59-b","65","66","67","74"]},
    "IA-D":{ids:["8","13","28","29", "60", "60-b"]},

    "VT-TL":{
      ids:["79","80","101"],
      idsInversos:["80"]
    },
    "VT-I":{
      ids:["81","82","102"],
      idsInversos:["82"]
    },
    "VT-TS":{
      ids:["83","84","103"],
      idsInversos:["84"]
    },
    "VT-P":{
      ids:["85","86","104"],
      idsInversos:["86"]
    },
    "VT-A":{
      ids:["87","88","105"],
      idsInversos:["88"]
    },
    "VT-C":{
      ids:["89","90","106"],
      idsInversos:["90"]
    },
    "VT-RS":{
      ids:["91","92","107"],
      idsInversos:["92"]
    },
    "VT-Are":{
      ids:["93","94","108"],
      idsInversos:["94"]
    },
    "VT-SO":{
      ids:["95","96","109"],
      idsInversos:["96"]
    },
    "VT-BS":{
      ids:["97","98","110"],
      idsInversos:["98"]
    },
    "VT-Aru":{
      ids:["99","100","111"],
      idsInversos:["100"]
    },


    "Per-SA-Social":{
      ids:["91","92","107","15","16","31","32","44"],
      idsInversos:["92"]
    },
    "Per-SA-Autonomo":{
      ids:["81","82","102"],
      idsInversos:["82"]
    },
    "Per-PRp-Prestigioso":{
      ids:["93","94","108","97","98","110"],
      idsInversos:["94","98"]
    },
    "Per-PRp-RelacionPersonal":{
      ids:["81","82","102"," 87","88","105","93","94","108"],
      idsInversos:["82","88","94"]
    },
    "Per-CR-Creativo":{
      ids:["89","90","106"],
      idsInversos:["90"]
    },
    "Per-CR-Rutinario":{
      ids:["95","96","109","99","100","111"],
      idsInversos:["96","100"]
    },
    "PER-EE-Emprendedor":{
      ids:["79","80","101 ","81","82","102","93","94","108"],
      idsInversos:["80","82","94"]
    },
    "PER-EE-Estabilidad":{
      ids:["95","96","109","97","98","110"],
      idsInversos:["96","98"]
    },


  }
}

export const opcionesListaStatus = {
  no_interes:{valor:'No interés',class:'opcion_no_interes', texto:'No interés', preferencia:1},
  contrato:{valor:'Contrato',class:'opcion_contrato', texto:'Contrato', preferencia:2},
  contactado:{valor:'Contactado',class:'opcion_contactado', texto:'Contactado', preferencia:3},
  muy_interesado:{valor:'Muy interesado',class:'opcion_muy_interesado', texto:'Muy interesado', preferencia:4},
  futuro:{valor:'A futuro',class:'opcion_a_futuro', texto:'A futuro', preferencia:5}
}
export const opcionesValoracionDeportivaWA2 = ['NCAA DIV 3 / NAIA DIV 2','NCAA DIV 2 / NAIA DIV 1', 'NCAA DIV 1 / NCAA DIV 2']

export const opcionesSedes = {
  barcelona: {texto:'Barcelona', visible:true},
  galicia: {texto:'Galicia', visible:true},
  las_palmas: {texto:'Las Palmas', visible:true},
  madrid: {texto:'Madrid', visible:true},
  pais_vasco: {texto:'País Vasco', visible:true},
  sevilla: {texto:'Sevilla', visible:true},
  tenerife: {texto:'Tenerife', visible:true},
  valencia: {texto:'Valencia', visible:true},
  valladolid: {texto:'Valladolid', visible:true},
}

export const filtrosColegiosListado = {
  provincias:{
    title:'Provincias',
    type:'checkbox',
    todos:{
      text:'Todos',
      checked:true
    },
    items:{
      a_coruna: {text:'A Coruña',text_info:'A Coruña', checked:true},
      alava: {text:'Álava',text_info:'Álava', checked:true},
      albacete: {text:'Albacete',text_info:'Albacete', checked:true},
      alicante: {text:'Alicante',text_info:'Alicante', checked:true},
      almeria: {text:'Almería',text_info:'Almería', checked:true},
      asturias: {text:'Asturias',text_info:'Asturias', checked:true},
      avila: {text:'Ávila',text_info:'Ávila', checked:true},
      badajoz: {text:'Badajoz',text_info:'Badajoz', checked:true},
      baleares: {text:'Baleares',text_info:'Baleares', checked:true},
      barcelona: {text:'Barcelona',text_info:'Barcelona', checked:true},
      burgos: {text:'Burgos',text_info:'Burgos', checked:true},
      caceres: {text:'Cáceres',text_info:'Cáceres', checked:true},
      cadiz: {text:'Cádiz',text_info:'Cádiz', checked:true},
      cantabria: {text:'Cantabria',text_info:'Cantabria', checked:true},
      castellon: {text:'Castellón',text_info:'Castellón', checked:true},
      ceuta: {text:'Ceuta',text_info:'Ceuta', checked:true},
      ciudad_real: {text:'Ciudad Real',text_info:'Ciudad Real', checked:true},
      cordoba: {text:'Córdoba',text_info:'Córdoba', checked:true},
      cuenca: {text:'Cuenca',text_info:'Cuenca', checked:true},
      girona: {text:'Girona',text_info:'Girona', checked:true},
      granada: {text:'Granada',text_info:'Granada', checked:true},
      guadalajara: {text:'Guadalajara',text_info:'Guadalajara', checked:true},
      guipuzcoa: {text:'Guipúzcoa',text_info:'Guipúzcoa', checked:true},
      huelva: {text:'Huelva',text_info:'Huelva', checked:true},
      huesca: {text:'Huesca',text_info:'Huesca', checked:true},
      jaen: {text:'Jaén',text_info:'Jaén', checked:true},
      la_rioja: {text:'La rioja',text_info:'La rioja', checked:true},
      las_palmas: {text:'Las palmas',text_info:'Las palmas', checked:true},
      leon: {text:'León',text_info:'León', checked:true},
      lleida: {text:'Lleida',text_info:'Lleida', checked:true},
      lugo: {text:'Lugo',text_info:'Lugo', checked:true},
      madrid: {text:'Madrid',text_info:'Madrid', checked:true},
      malaga: {text:'Málaga',text_info:'Málaga', checked:true},
      melilla: {text:'Melilla',text_info:'Melilla', checked:true},
      murcia: {text:'Murcia',text_info:'Murcia', checked:true},
      navarra: {text:'Navarra',text_info:'Navarra', checked:true},
      ourense: {text:'Ourense',text_info:'Ourense', checked:true},
      palencia: {text:'Palencia',text_info:'Palencia', checked:true},
      pontevedra: {text:'Pontevedra',text_info:'Pontevedra', checked:true},
      salamanca: {text:'Salamanca',text_info:'Salamanca', checked:true},
      tenerife: {text:'Tenerife',text_info:'Tenerife', checked:true},
      segovia: {text:'Segovia',text_info:'Segovia', checked:true},
      sevilla: {text:'Sevilla',text_info:'Sevilla', checked:true},
      soria: {text:'Soria',text_info:'Soria', checked:true},
      tarragona: {text:'Tarragona',text_info:'Tarragona', checked:true},
      teruel: {text:'Teruel',text_info:'Teruel', checked:true},
      toledo: {text:'Toledo',text_info:'Toledo', checked:true},
      valencia: {text:'Valencia',text_info:'Valencia', checked:true},
      valladolid: {text:'Valladolid',text_info:'Valladolid', checked:true},
      vizcaya: {text:'Vizcaya',text_info:'Vizcaya', checked:true},
      zamora: {text:'Zamora',text_info:'Zamora', checked:true},
      zaragoza: {text:'Zaragoza',text_info:'Zaragoza', checked:true}
    }
  }
}

export const filtrosAlumnosListado = {
  tipoInscripcion:{
    title:'Tipo de inscripción',
    type:'radiobutton',
    hidden:false,
    todos:{
      text:'Todos',
      checked:true
    },
    items:{
      'Deportiva':{text:'Deportiva',text_info:'Inscripción deportiva',checked:false},
      'Académica':{text:'Académica',text_info:'Inscripción académica',checked:false},
    }
  },

  status_alumnos_deportivos:{
    title:'Estado',
    type:'checkbox',
    hidden:true,
    todos:{
      text:'Todos',
      checked:true
    },
    items:{
      sin_estado:{text:'Sin estado',text_info:'Sin estado',checked:true},
      no_interes:{text:'No interés',text_info:'No interés',checked:true},
      contrato:{text:'Contrato',text_info:'Contrato',checked:true},
      contactado:{text:'Contactado',text_info:'Contactado',checked:true},
      muy_interesado:{text:'Muy interesado',text_info:'Muy interesado',checked:true},
      futuro:{text:'A futuro',text_info:'A futuro',checked:true},
    }
  },

  curso:{
    title:'Curso actual',
    type:'radiobutton',
    todos:{
      text:'Todos',
      checked:true
    },
    items:{
      '4º ESO':{text:'4º ESO',text_info:'4º ESO',checked:false},
      '1º BACHILLERATO':{text:'1º BACHILLERATO',text_info:'1º BACHILLERATO',checked:false},
    }
  },
  cuestionario:{
    title:'Cuestionario',
    type:'radiobutton',
    todos:{
      text:'Todos',
      checked:true
    },
    items:{
      'si':{text:'Si',text_info:'Con cuestionario',checked:false},
      'no':{text:'No',text_info:'Sin cuestionario',checked:false},
    }
  },
  sat:{
    title:'Examen SAT',
    type:'radiobutton',
    todos:{
      text:'Todos',
      checked:true
    },
    items:{
      'si':{text:'Si',text_info:'Con cuestionario',checked:false},
      'no':{text:'No',text_info:'Sin cuestionario',checked:false},
    }
  },
  bachillerato_dual:{
    title:'Bachillerato dual',
    type:'radiobutton',
    todos:{
      text:'Todos',
      checked:true
    },
    items:{
      'si':{text:'Si',text_info:'Bachillerato dual : si',checked:false},
      'no':{text:'No',text_info:'Bachillerato dual : no',checked:false},
    }
  },
  endesa:{
    title:'Endesa',
    type:'radiobutton',
    todos:{
      text:'Todos',
      checked:true
    },
    items:{
      'si':{text:'Si',text_info:'Endesa : si',checked:false},
      'no':{text:'No',text_info:'Endesa : no',checked:false},
    }
  },  
  autorizacion:{
    title:'Recibir información',
    type:'radiobutton',
    todos:{
      text:'Todos',
      checked:true
    },
    items:{
      'si':{text:'Si',text_info:'recibir información: SI',checked:false},
      'no':{text:'No',text_info:'recibir información: NO',checked:false},
    }
  },
}

export const localesDateCountdown = ['año','mes','día','hora','minutos','segundos']
export const localespluralDateCountdown = ['años','meses','días','horas','minutos','segundos']

export const rawScoreSat = {
  0: { reading: 10, writing: 10, maths: 200 },
  1: { reading: 10, writing: 10, maths: 200 },
  2: { reading: 12, writing: 10, maths: 210 },
  3: { reading: 14, writing: 10, maths: 240 },
  4: { reading: 15, writing: 11, maths: 260 },
  5: { reading: 16, writing: 12, maths: 280 },
  6: { reading: 17, writing: 13, maths: 310 },
  7: { reading: 18, writing: 13, maths: 320 },
  8: { reading: 19, writing: 14, maths: 330 },
  9: { reading: 20, writing: 15, maths: 340 },
  10: { reading: 21, writing: 16, maths: 370 },
  11: { reading: 22, writing: 16, maths: 380 },
  12: { reading: 23, writing: 17, maths: 390 },
  13: { reading: 24, writing: 18, maths: 420 },
  14: { reading: 25, writing: 19, maths: 430 },
  15: { reading: 25, writing: 19, maths: 440 },
  16: { reading: 26, writing: 20, maths: 450 },
  17: { reading: 27, writing: 21, maths: 470 },
  18: { reading: 28, writing: 21, maths: 480 },
  19: { reading: 29, writing: 22, maths: 480 },
  20: { reading: 30, writing: 23, maths: 500 },
  21: { reading: 31, writing: 23, maths: 510 },
  22: { reading: 32, writing: 24, maths: 520 },
  23: { reading: 32, writing: 25, maths: 520 },
  24: { reading: 33, writing: 25, maths: 540 },
  25: { reading: 35, writing: 26, maths: 550 },
  26: { reading: 36, writing: 26, maths: 560 },
  27: { reading: 37, writing: 27, maths: 570 },
  28: { reading: 38, writing: 28, maths: 580 },
  29: { reading: 39, writing: 29, maths: 590 },
  30: { reading: 40, writing: 29, maths: 600 },
  31: { writing: 30, maths: 610 },
  32: { writing: 30, maths: 620 },
  33: { writing: 31, maths: 630 },
  34: { writing: 32, maths: 650 },
  35: { writing: 32, maths: 660 },
  36: { writing: 33, maths: 670 },
  37: { writing: 34, maths: 680 },
  38: { writing: 34, maths: 690 },
  39: { writing: 35, maths: 700 },
  40: { writing: 36, maths: 710 },
  41: { writing: 37, maths: 740 },
  42: { writing: 38, maths: 750 },
  43: { writing: 39, maths: 760 },
  44: { writing: 40, maths: 800 },
  45: { maths: 800 }
}

export const universitiesAmerica = {

  "Abilene Christian University": {
    "Name": "Abilene Christian University",
    "Location": "Abilene, TX",
    "Average SAT": 1124,
    "Sports Division": "NCAA DI",
    "Country": "EEUU",
    "Value": {
      "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"],
      "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"]
    },
    "Logo": 'Abilene Christian University.jpg'
  },
  "Alabama State University": {
    "Name": "Alabama State University",
    "Location": "",
    "Average SAT": "",
    "Sports Division": "",
    "Country": "EEUU",
    "Value": {
      "ES": ["", "", ""],
      "EN": ["", "", ""]
    },
    "Logo": 'Alabama State University.jpg'
  },
  "Academy of Art University": {
      "Name": "Academy of Art University",
      "Location": "San Francisco, CA",
      "Average SAT": 1200,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota de SAT", "Tu ranking de clase", "Los essays y redacciones"],
        "EN": ["La nota de SAT", "Tu ranking de clase", "Los essays y redacciones"]
      },
      "Logo": 'Academy of Art University.jpg'
  },
  "Adams State University": {
      "Name": "Adams State University",
      "Location": "Alamosa, CO",
      "Average SAT": 980,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "La nota de SAT", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "La nota de SAT", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Adams State University.jpg'
  },
  "Adelphi University" : {
      "Name": "Adelphi University",
      "Location": "Garden City, NY",
      "Average SAT": 1156,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["El rigor de las clases y esfuerzo adicional", "La nota Media del expediente académico", "Los essays y redacciones"],
        "EN": ["El rigor de las clases y esfuerzo adicional", "La nota Media del expediente académico", "Los essays y redacciones"]
      },
      "Logo": 'Adelphi University.jpg'
  },
  "Adrian College": {
      "Name": "Adrian College",
      "Location": "Adrian, MI",
      "Average SAT": 1140,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "La nota de SAT", "Contacto con antiguos estudiantes"],
        "EN": ["La nota Media del expediente académico", "La nota de SAT", "Contacto con antiguos estudiantes"]
      },
      "Logo": 'Adrian College.jpg'
  },
  "Alabama State": {
      "Name": "Alabama State",
      "Location": "Montgomery, AL",
      "Average SAT": 862,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Las cartas de recomendación", "Los essays y redacciones"],
        "EN": ["La nota Media del expediente académico", "Las cartas de recomendación", "Los essays y redacciones"]
      },
      "Logo": 'Alabama State.jpg'
  },
  "Alaska Pacific University": {
      "Name": "Alaska Pacific University",
      "Location": "Anchorage, AK",
      "Average SAT": 1120,
      "Sports Division": "",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "La nota de SAT"]
      },
      "Logo": 'Alaska Pacific University.jpg'
  },
  "Albany State University": {
      "Name": "Albany State University",
      "Location": "Albany, GA",
      "Average SAT": 900,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Geographical Residence", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "Geographical Residence", "La nota de SAT"]
      },
      "Logo": 'Albany State University.jpg'
  },
  "Albertus Magnus College": {
      "Name": "Albertus Magnus College",
      "Location": "New Haven, CT",
      "Average SAT": 980,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Albertus Magnus College.jpg'
  },
  "Alcorn State University": {
      "Name": "Alcorn State University",
      "Location": "Alcorn, MS",
      "Average SAT": 970,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Alcorn State University.jpg'
  },
  "Alfred University": {
      "Name": "Alfred University",
      "Location": "Alfred, NY",
      "Average SAT": 1110,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"]
      },
      "Logo": 'Alfred University.jpg'
  },
  "Allen University": {
      "Name": "Allen University",
      "Location": "Columbia, SC",
      "Average SAT": 850,
      "Sports Division": "NAIA",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Las cartas de recomendación", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "Las cartas de recomendación", "La nota de SAT"]
      },
      "Logo": 'Allen University.jpg'
  },
  "American International College": {
      "Name": "American International College",
      "Location": "Springfield, MA",
      "Average SAT": 996,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "Los essays y redacciones"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "Los essays y redacciones"]
      },
      "Logo": 'American International College.jpg'
  },
  "American University": {
      "Name": "American University",
      "Location": "Washington D.C.",
      "Average SAT": 1260,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Level of Applicant's Interest", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Level of Applicant's Interest", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'American University.jpg'
  },
  "Amherst College": {
      "Name": "Amherst College",
      "Location": "Amherst, MA",
      "Average SAT": 1492,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"]
      },
      "Logo": 'Amherst College.jpg'
  },
  "Amridge University": {
      "Name": "Amridge University",
      "Location": "Montgomery, AL",
      "Average SAT": 850,
      "Sports Division": "",
      "Country": "EEUU",
      "Value": {
        "ES": ["Los essays y redacciones", "Tu ranking de clase", "Carácter y cualidades personales"],
        "EN": ["Los essays y redacciones", "Tu ranking de clase", "Carácter y cualidades personales"]
      },
      "Logo": 'Amridge University.jpg'
  },
  "Andrews University": {
      "Name": "Andrews University",
      "Location": "Berrien Springs, MI",
      "Average SAT": 1010,
      "Sports Division": "NAIA",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Carácter y cualidades personales", "Las cartas de recomendación"],
        "EN": ["La nota Media del expediente académico", "Carácter y cualidades personales", "Las cartas de recomendación"]
      },
      "Logo": 'Andrews University.jpg'
  },
  "Angelo State University": {
      "Name": "Angelo State University",
      "Location": "San Angelo, TX",
      "Average SAT": 1020,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["Tu ranking de clase", "La nota de SAT", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["Tu ranking de clase", "La nota de SAT", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Angelo State University.jpg'
  },
  "Apalachian State University": {
      "Name": "Apalachian State University",
      "Location": "Boone, NC",
      "Average SAT": 1185,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Apalachian State University.jpg'
  },
  "Ashford University": {
      "Name": "Ashford University",
      "Location": "San Diego, CA",
      "Average SAT": 970,
      "Sports Division": "NAIA",
      "Country": "EEUU",
      "Value": {
        "ES": ["Tu ranking de clase", "El rigor de las clases y esfuerzo adicional", "Carácter y cualidades personales"],
        "EN": ["Tu ranking de clase", "El rigor de las clases y esfuerzo adicional", "Carácter y cualidades personales"]
      },
      "Logo": 'Ashford University.jpg'
  },
  "Atlanta Christian College Point University": {
      "Name": "Atlanta Christian College Point University",
      "Location": "West Point, GA",
      "Average SAT": 978,
      "Sports Division": "NCCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Carácter y cualidades personales", "Interés por la religión"],
        "EN": ["La nota Media del expediente académico", "Carácter y cualidades personales", "Interés por la religión"]
      },
      "Logo": 'Atlanta Christian College Point University.jpg'
  },
  "Avila University": {
      "Name": "Avila University",
      "Location": "Kansas City, MO",
      "Average SAT": 930,
      "Sports Division": "NAIA",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'Avila University.jpg'
  },
  "Babson College": {
      "Name": "Babson College",
      "Location": "Wellesley, MA",
      "Average SAT": 1320,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"]
      },
      "Logo": 'Babson College.jpg'
  },
  "Bard College": {
      "Name": "Bard College",
      "Location": "Annandale-on-Hudson, NY",
      "Average SAT": 1330,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"]
      },
      "Logo": 'Bard College.jpg'
  },
  "Barton College": {
      "Name": "Barton College",
      "Location": "Wilson, NC",
      "Average SAT": 1049,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'Barton College.jpg'
  },
  "Baruch College": {
      "Name": "Baruch College",
      "Location": "New York City, NY",
      "Average SAT": 1273,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'Baruch College.jpg'
  },
  "Bates College": {
      "Name": "Bates College",
      "Location": "Lewiston, ME",
      "Average SAT": 1360,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"]
      },
      "Logo": 'Bates College.jpg'
  },
  "Baylor University": {
      "Name": "Baylor University",
      "Location": "Waco, TX",
      "Average SAT": 1275,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["Tu ranking de clase", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["Tu ranking de clase", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'Baylor University.jpg'
  },
  "Bentley University": {
      "Name": "Bentley University",
      "Location": "Waltham, MA",
      "Average SAT": 1290,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'Bentley University.jpg'
  },
  "Berea College": {
      "Name": "Berea College",
      "Location": "Berea, KY",
      "Average SAT": 1101,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["Entrevista personal", "La nota Media del expediente académico", "Los essays y redacciones"],
        "EN": ["Entrevista personal", "La nota Media del expediente académico", "Los essays y redacciones"]
      },
      "Logo": 'Berea College.jpg'
  },
  "Bethel College": {
      "Name": "Bethel College",
      "Location": "Mishawaka, ID",
      "Average SAT": 1067,
      "Sports Division": "NAIA",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "La nota de SAT", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "La nota de SAT", "Carácter y cualidades personales"]
      },
      "Logo": 'Bethel College.jpg'
  },
  "Bethune-Cookman University": {
      "Name": "Bethune-Cookman University",
      "Location": "Daytona Beach, FL",
      "Average SAT": 864,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'Bethune-Cookman University.jpg'
  },
  "Boston University": {
      "Name": "Boston University",
      "Location": "Boston, Ma",
      "Average SAT": 1380,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["El rigor de las clases y esfuerzo adicional", "La nota Media del expediente académico", "Contacto con antiguos estudiantes"],
        "EN": ["El rigor de las clases y esfuerzo adicional", "La nota Media del expediente académico", "Contacto con antiguos estudiantes"]
      },
      "Logo": 'Boston University.jpg'
  },
  "Bowdoin University": {
      "Name": "Bowdoin University",
      "Location": "Brunswick, ME",
      "Average SAT": 1390,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"]
      },
      "Logo": 'Bowdoin University.jpg'
  },
  "Bowie State University": {
      "Name": "Bowie State University",
      "Location": "Bowie, MD",
      "Average SAT": 1050,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'Bowie State University.jpg'
  },
  "Bradley University": {
      "Name": "Bradley University",
      "Location": "Peoria, IL",
      "Average SAT": 1179,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "Tu ranking de clase"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "Tu ranking de clase"]
      },
      "Logo": 'Bradley University.jpg'
  },
  "Brevard College": {
      "Name": "Brevard College",
      "Location": "Brevard, NC",
      "Average SAT": 1070,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Level of Applicant's Interest", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Level of Applicant's Interest", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Brevard College.jpg'
  },
  "Briar Cliff University": {
      "Name": "Briar Cliff University",
      "Location": "Sioux City, IA",
      "Average SAT": 1010,
      "Sports Division": "NAIA",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "La nota de SAT", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "La nota de SAT", "Carácter y cualidades personales"]
      },
      "Logo": 'Briar Cliff University.jpg'
  },
  "Brigham Young University Idaho": {
      "Name": "Brigham Young University Idaho",
      "Location": "Rexburg, ID",
      "Average SAT": 1160,
      "Sports Division": "",
      "Country": "EEUU",
      "Value": {
        "ES": ["Carácter y cualidades personales", "Las cartas de recomendación", "Interés por la religión"],
        "EN": ["Carácter y cualidades personales", "Las cartas de recomendación", "Interés por la religión"]
      },
      "Logo": 'Brigham Young University Idaho.jpg'
  },
  "Brooklyn College": {
      "Name": "Brooklyn College",
      "Location": "New York, NY",
      "Average SAT": 1160,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'Brooklyn College.jpg'
  },
  "Brown University": {
      "Name": "Brown University",
      "Location": "Providence, RI",
      "Average SAT": 1488,
      "Sports Division": "NCAA DI ",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"]
      },
      "Logo": 'Brown University.jpg'
  },
  "Bucknell University": {
      "Name": "Bucknell University",
      "Location": "Lewisburg, PA",
      "Average SAT": 1332,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"]
      },
      "Logo": 'Bucknell University.jpg'
  },
  "Buena Vista University": {
      "Name": "Buena Vista University",
      "Location": "Storm Lake, IA",
      "Average SAT": 1110,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "Las cartas de recomendación"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "Las cartas de recomendación"]
      },
      "Logo": 'Buena Vista University.jpg'
  },
  "California Institute of Technology": {
      "Name": "California Institute of Technology",
      "Location": "Pasadena, CA",
      "Average SAT": 1560,
      "Sports Division": "NCAA DIII",
      "Value": {
        "ES": ["Los essays y redacciones", "Carácter y cualidades personales", "Las cartas de recomendación"],
        "EN": ["Los essays y redacciones", "Carácter y cualidades personales", "Las cartas de recomendación"]
      },
      "Logo": 'California Institute of Technology.jpg'
  },
  "California Polytechnic State University Pomona": {
      "Name": "California Polytechnic State University Pomona",
      "Location": "Pomona, CA",
      "Average SAT": 1121,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'California Polytechnic State University Pomona.jpg'
  },
  "California Polytechnic State University San Luis Obispo": {
      "Name": "California Polytechnic State University San Luis Obispo",
      "Location": "San Luis Obispo, CA",
      "Average SAT": 1293,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'California Polytechnic State University San Luis Obispo.jpg'
  },
  "California State University Eastbay": {
      "Name": "California State University Eastbay",
      "Location": "Hayward, CA",
      "Average SAT": 995,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": [ "La nota Media del expediente académico", "La nota de SAT", "Tu ranking de clase"],
        "EN": [ "La nota Media del expediente académico", "La nota de SAT", "Tu ranking de clase"]
      },
      "Logo": 'California State University Eastbay.jpg'
  },
  "California State University Long Beach": {
      "Name": "California State University Long Beach",
      "Location": "Long Beach, CA",
      "Average SAT": 1025,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "La nota de SAT"]
      },
      "Logo": 'California State University Long Beach.jpg'
  },
  "California State University Los Angeles": {
      "Name": "California State University Los Angeles",
      "Location": "Los Angeles, CA",
      "Average SAT": 745,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Geographical Residence", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Geographical Residence", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'California State University Los Angeles.jpg'
  },
  "California State Long Beach": {
      "Name": "California State Long Beach",
      "Location": "Long Beach, CA",
      "Average SAT": 1025,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "La nota de SAT"]
      },
      "Logo": 'California State University Long Beach.jpg'
  },
  "Calvary University": {
      "Name": "Calvary University",
      "Location": "Kansas City, MO",
      "Average SAT": 1120,
      "Sports Division": "ACCA",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"]
      },
      "Logo": 'Calvary University.jpg'
  },
  "Cameron University": {
      "Name": "Cameron University",
      "Location": "Lawton, OK",
      "Average SAT": 1040,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "La nota de SAT"]
      },
      "Logo": 'Cameron University.jpg'
  },
  "Carleton College": {
      "Name": "Carleton College",
      "Location": "Northfield, MN",
      "Average SAT": 1445,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Carleton College.jpg'
  },
  "Carnegie Mellon University": {
      "Name": "Carnegie Mellon University",
      "Location": "Pittsburgh, PA",
      "Average SAT": 1495,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "Actividades Extraescolares"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "Actividades Extraescolares"]
      },
      "Logo": 'Carnegie Mellon University.jpg'
  },
  "Central Connecticut State": {
      "Name": "Central Connecticut State",
      "Location": "New Britain, CT",
      "Average SAT": 1060,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "Las cartas de recomendación"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "Las cartas de recomendación"]
      },
      "Logo": 'Central Connecticut State.jpg'
  },
  "Chowan University": {
      "Name": "Chowan University",
      "Location": "Murfreesboro, NC",
      "Average SAT": 813,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Carácter y cualidades personales", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Carácter y cualidades personales", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Chowan University.jpg'
  },
  "CIS-Endicott University": {
      "Name": "CIS-Endicott University",
      "Location": "Madrid, Spain",
      "Average SAT": 850,
      "Sports Division": "",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Carácter y cualidades personales", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Carácter y cualidades personales", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'CIS-Endicott University.jpg'
  },
  "City University of New York- Medgar Evers College": {
      "Name": "City University of New York- Medgar Evers College",
      "Location": "New York, NY",
      "Average SAT": 820,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'City University of New York- Medgar Evers College.jpg'
  },
  "Clemson University": {
      "Name": "Clemson University",
      "Location": "Clemson, SC",
      "Average SAT": 1305,
      "Sports Division": "NCAA DI ",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Clemson University.jpg'
  },
  "Colby College": {
      "Name": "Colby College",
      "Location": "Waterville, ME",
      "Average SAT": 1410,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Carácter y cualidades personales", "Las cartas de recomendación"],
        "EN": ["La nota Media del expediente académico", "Carácter y cualidades personales", "Las cartas de recomendación"]
      },
      "Logo": 'Colby College.jpg'
  },
  "College of the Ozarks": {
      "Name": "College of the Ozarks",
      "Location": "Point Lookout, MO",
      "Average SAT": 1180,
      "Sports Division": "NAIA",
      "Country": "EEUU",
      "Value": {
        "ES": ["Carácter y cualidades personales", "Tu ranking de clase", "Entrevistas"],
        "EN": ["Carácter y cualidades personales", "Tu ranking de clase", "Entrevistas"]
      },
      "Logo": 'College of the Ozarks.jpg'
  },
  "Colorado State University": {
      "Name": "Colorado State University",
      "Location": "Fort Collins, CO",
      "Average SAT": 1200,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "Los essays y redacciones"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "Los essays y redacciones"]
      },
      "Logo": 'Colorado State University.jpg'
  },
  "Colorado State University Pueblo": {
      "Name": "Colorado State University Pueblo",
      "Location": "Pueblo, CO",
      "Average SAT": 1030,
      "Sports Division": "NCAA DII ",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "La nota de SAT", "Tu ranking de clase"],
        "EN": ["La nota Media del expediente académico", "La nota de SAT", "Tu ranking de clase"]
      },
      "Logo": 'Colorado State University Pueblo.jpg'
  },
  "Columbia University": {
      "Name": "Columbia University",
      "Location": "New York, NY",
      "Average SAT": 1515,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"]
      },
      "Logo": 'Columbia University.jpg'
  },
  "Connecticut College": {
      "Name": "Connecticut College",
      "Location": "New London, CT",
      "Average SAT": 1335,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Carácter y cualidades personales", "Tu ranking de clase"],
        "EN": ["La nota Media del expediente académico", "Carácter y cualidades personales", "Tu ranking de clase"]
      },
      "Logo": 'Connecticut College.jpg'
  },
  "Cooper Union": {
      "Name": "Cooper Union",
      "Location": "New York, NY",
      "Average SAT": 1393,
      "Sports Division": "",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "Talento y habilidades"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "Talento y habilidades"]
      },
      "Logo": 'Cooper Union.jpg'
  },
  "Corban University": {
      "Name": "Corban University",
      "Location": "Salem, OR",
      "Average SAT": 1135,
      "Sports Division": "NAIA",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Las cartas de recomendación"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Las cartas de recomendación"]
      },
      "Logo": 'Corban University.jpg'
  },
  "Cornell University": {
      "Name": "Cornell University",
      "Location": "Ithaca, NY",
      "Average SAT": 1470,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"]
      },
      "Logo": 'Cornell University.jpg'
  },
  "Culver-Stockton College": {
      "Name": "Culver-Stockton College",
      "Location": "Canton, MO",
      "Average SAT": 1010,
      "Sports Division": "NAIA",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "La nota de SAT", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "La nota de SAT", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Culver-Stockton College.jpg'
  },
  "D'Youville College": {
      "Name": "D'Youville College",
      "Location": "Buffalo, NY",
      "Average SAT": 1103,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["El rigor de las clases y esfuerzo adicional", "La nota de SAT", "Tu ranking de clase"],
        "EN": ["El rigor de las clases y esfuerzo adicional", "La nota de SAT", "Tu ranking de clase"]
      },
      "Logo": `D'Youville.jpg`
  },
  "Dakota Wesleyan University": {
      "Name": "Dakota Wesleyan University",
      "Location": "Mitchell, SD",
      "Average SAT": 1080,
      "Sports Division": "NAIA",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "La nota de SAT", "Tu ranking de clase"],
        "EN": ["La nota Media del expediente académico", "La nota de SAT", "Tu ranking de clase"]
      },
      "Logo": 'Dakota Wesleyan University.jpg'
  },
  "Davidson College": {
      "Name": "Davidson College",
      "Location": "Davidson, NC",
      "Average SAT": 1420,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["Carácter y cualidades personales", "Las cartas de recomendación", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["Carácter y cualidades personales", "Las cartas de recomendación", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Davidson College.jpg'
  },
  "Defiance College": {
      "Name": "Defiance College",
      "Location": "Defiance, OH",
      "Average SAT": 970,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'Defiance College.jpg'
  },
  "Delaware State": {
      "Name": "Delaware State",
      "Location": "Dover, DE",
      "Average SAT": 905,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "La nota de SAT", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "La nota de SAT", "Carácter y cualidades personales"]
      },
      "Logo": 'Delaware State.jpg'
  },
  "Delaware State University": {
      "Name": "Delaware State University",
      "Location": "Dover, DE",
      "Average SAT": 905,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "La nota de SAT", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "La nota de SAT", "Carácter y cualidades personales"]
      },
      "Logo": 'Delaware State University.jpg'
  },
  "DePauw College": {
      "Name": "DePauw College",
      "Location": "Greencastle, ID",
      "Average SAT": 1260,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "Los essays y redacciones"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "Los essays y redacciones"]
      },
      "Logo": 'DePauw College.jpg'
  },
  "Dickinson State University": {
      "Name": "Dickinson State University",
      "Location": "Dickinson, ND",
      "Average SAT": 946,
      "Sports Division": "NAIA",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Las cartas de recomendación"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Las cartas de recomendación"]
      },
      "Logo": 'Dickinson State University.jpg'
  },
  "Drew University": {
      "Name": "Drew University",
      "Location": "Madison, NJ",
      "Average SAT": 1200,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Interview", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Interview", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Drew University.jpg'
  },
  "Duke University": {
      "Name": "Duke University",
      "Location": "Durham, NC",
      "Average SAT": 1485,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"]
      },
      "Logo": 'Duke University.jpg'
  },
  "East Tennessee University": {
      "Name": "East Tennessee University",
      "Location": "Johnson City, TN",
      "Average SAT": 1060,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'East Tennessee University.jpg'
  },
  "Edward Waters College": {
      "Name": "Edward Waters College",
      "Location": "Jacksonville, FL",
      "Average SAT": 1180,
      "Sports Division": "NAIA",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Las cartas de recomendación", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Las cartas de recomendación", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Edward Waters College.jpg'
  },
  "Emmanuel College": {
      "Name": "Emmanuel College",
      "Location": "Boston, MA",
      "Average SAT": 1179,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Las cartas de recomendación"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Las cartas de recomendación"]
      },
      "Logo": 'Emmanuel College.jpg'
  },
  "Emory University": {
      "Name": "Emory University",
      "Location": "Atlanta, GA",
      "Average SAT": 1435,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Carácter y cualidades personales", "Actividades Extraescolares"],
        "EN": ["La nota Media del expediente académico", "Carácter y cualidades personales", "Actividades Extraescolares"]
      },
      "Logo": 'Emory University.jpg'
  },
  "Florida College": {
      "Name": "Florida College",
      "Location": "Terrace, FL",
      "Average SAT": 1100,
      "Sports Division": "NJCAA",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Las cartas de recomendación"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Las cartas de recomendación"]
      },
      "Logo": 'Florida College.jpg'
  },
  "Franklin Pierce University": {
      "Name": "Franklin Pierce University",
      "Location": "Rindge, NH",
      "Average SAT": 1040,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "Los essays y redacciones"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "Los essays y redacciones"]
      },
      "Logo": 'Franklin Pierce University.jpg'
  },
  "Gallaudet University": {
      "Name": "Gallaudet University",
      "Location": "Washington D.C.",
      "Average SAT": 910,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Las cartas de recomendación"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Las cartas de recomendación"]
      },
      "Logo": 'Gallaudet University.jpg'
  },
  "Grambling State University": {
      "Name": "Grambling State University",
      "Location": "Grambling, LA",
      "Average SAT": 920,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Las cartas de recomendación"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Las cartas de recomendación"]
      },
      "Logo": 'Grambling State University.jpg'
  },
  "George Washington University": {
      "Name": "George Washington University",
      "Location": "Washington D.C.",
      "Average SAT": 1355,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "Los essays y redacciones"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "Los essays y redacciones"]
      },
      "Logo": 'George Washington University.jpg'
  },
  "Georgetown University": {
      "Name": "Georgetown University",
      "Location": "Washington D.C.",
      "Average SAT": 1435,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"]
      },
      "Logo": 'Georgetown University.jpg'
  },
  "Georgia Institute of Technology": {
      "Name": "Georgia Institute of Technology",
      "Location": "Atlanta, GA",
      "Average SAT": 1295,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Actividades Extraescolares", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Actividades Extraescolares", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Georgia Institute of Technology.jpg'
  },
  "Georgia State University": {
      "Name": "Georgia State University",
      "Location": "Atlanta, GA",
      "Average SAT": 1054,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'Georgia State University.jpg'
  },
  "Georgian Court University": {
      "Name": "Georgian Court University",
      "Location": "Lakewood, NJ",
      "Average SAT": 1016,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'Georgian Court University.jpg'
  },
  "Gettysburg College": {
      "Name": "Gettysburg College",
      "Location": "Gettysburg, PA",
      "Average SAT": 1340,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Las cartas de recomendación"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Las cartas de recomendación"]
      },
      "Logo": 'Gettysburg College.jpg'
  },
  "Gonzaga University": {
      "Name": "Gonzaga University",
      "Location": "Spokane, WA",
      "Average SAT": 1257,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Carácter y cualidades personales", "First generation college student"],
        "EN": ["La nota Media del expediente académico", "Carácter y cualidades personales", "First generation college student"]
      },
      "Logo": 'Gonzaga University.jpg'
  },
  "Graceland University": {
      "Name": "Graceland University",
      "Location": "Lamoni, IA",
      "Average SAT": 990,
      "Sports Division": "NAIA",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "La nota de SAT", "Tu ranking de clase"],
        "EN": ["La nota Media del expediente académico", "La nota de SAT", "Tu ranking de clase"]
      },
      "Logo": 'Graceland University.jpg'
  },
  "Greensboro College": {
      "Name": "Greensboro College",
      "Location": "Greensboro, NC",
      "Average SAT": 1020,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Carácter y cualidades personales", "Level of Applicant's Interest"],
        "EN": ["La nota Media del expediente académico", "Carácter y cualidades personales", "Level of Applicant's Interest"]
      },
      "Logo": 'Greensboro College.jpg'
  },
  "Hampton University": {
      "Name": "Hampton University",
      "Location": "Hampton, VA",
      "Average SAT": 1053,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"]
      },
      "Logo": 'Hampton University.jpg'
  },
  "Harvard University": {
      "Name": "Harvard University",
      "Location": "Cambridge, MA",
      "Average SAT": 1520,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Contacto con antiguos estudiantes", "Los essays y redacciones"],
        "EN": ["La nota Media del expediente académico", "Contacto con antiguos estudiantes", "Los essays y redacciones"]
      },
      "Logo": 'Harvard University.jpg'
  },
  "Harvey Mudd College": {
      "Name": "Harvey Mudd College",
      "Location": "Claremont, CA",
      "Average SAT": 1520,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Las cartas de recomendación"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Las cartas de recomendación"]
      },
      "Logo": 'Harvey Mudd College.jpg'
  },
  "Haverford College": {
      "Name": "Haverford College",
      "Location": "Haverford, PA",
      "Average SAT": 1470,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"]
      },
      "Logo": 'Haverford College.jpg'
  },
  "Hendrix College": {
      "Name": "Hendrix College",
      "Location": "Conway, AR",
      "Average SAT": 1255,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Hendrix College.jpg'
  },
  "Hofstra University": {
      "Name": "Hofstra University",
      "Location": "Hempstead, NY",
      "Average SAT": 1222,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Tu ranking de clase"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Tu ranking de clase"]
      },
      "Logo": 'Hofstra University.jpg'
  },
  "Holy Names University": {
      "Name": "Holy Names University",
      "Location": "Oakland, CA",
      "Average SAT": 950,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Las cartas de recomendación"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Las cartas de recomendación"]
      },
      "Logo": 'Holy Names University.jpg'
  },
  "Hope International University": {
      "Name": "Hope International University",
      "Location": "Fullerton, CA",
      "Average SAT": 1005,
      "Sports Division": "NAIA",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Religious Affiliation or Commitment"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Religious Affiliation or Commitment"]
      },
      "Logo": 'Hope International University.jpg'
  },
  "Huntingdon College": {
      "Name": "Huntingdon College",
      "Location": "Montgomery, AL",
      "Average SAT": 1091,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'Huntingdon College.jpg'
  },
  "Huston-Tillotson University": {
      "Name": "Huston-Tillotson University",
      "Location": "Austin, TX",
      "Average SAT": 945,
      "Sports Division": "NAIA",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "La nota de SAT"]
      },
      "Logo": 'Huston-Tillotson University.jpg'
  },
  "Illinois College": {
      "Name": "Illinois College",
      "Location": "Jacksonville, IL",
      "Average SAT": 1105,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Carácter y cualidades personales", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Carácter y cualidades personales", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Illinois College.jpg'
  },
  "Indiana University Bloomington": {
      "Name": "Indiana University Bloomington",
      "Location": "Bloomington, IN",
      "Average SAT": 1246,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Indiana University Bloomington.jpg'
  },
  "Indiana Wesleyan University": {
      "Name": "Indiana Wesleyan University",
      "Location": "Marion, IN",
      "Average SAT": 1130,
      "Sports Division": "NAIA",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "La nota de SAT", "Los essays y redacciones"],
        "EN": ["La nota Media del expediente académico", "La nota de SAT", "Los essays y redacciones"]
      },
      "Logo": 'Indiana Wesleyan University.jpg'
  },
  "Jacksonville State University": {
      "Name": "Jacksonville State University",
      "Location": "Jacksonville, AL",
      "Average SAT": 1060,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'Jacksonville State University.jpg'
  },
  "James Madison University": {
      "Name": "James Madison University",
      "Location": "Harrisonburg, VA",
      "Average SAT": 1180,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "Contacto con antiguos estudiantes"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "Contacto con antiguos estudiantes"]
      },
      "Logo": 'James Madison University.jpg'
  },
  "Johns Hopkins University": {
      "Name": "Johns Hopkins University",
      "Location": "Baltimore, MD",
      "Average SAT": 1520,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"]
      },
      "Logo": 'Johns Hopkins University.jpg'
  },
  "Johnson C. Smith University": {
      "Name": "Johnson C. Smith University",
      "Location": "Charlotte, NC",
      "Average SAT": 860,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "La nota de SAT", "Los essays y redacciones"],
        "EN": ["La nota Media del expediente académico", "La nota de SAT", "Los essays y redacciones"]
      },
      "Logo": 'Johnson C. Smith University.jpg'
  },
  "Lafayette College": {
      "Name": "Lafayette College",
      "Location": "Easton, PA",
      "Average SAT": 1342,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "Los essays y redacciones"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "Los essays y redacciones"]
      },
      "Logo": 'Lafayette College.jpg'
  },
  "LaGrange College": {
      "Name": "LaGrange College",
      "Location": "Lagrange, GA",
      "Average SAT": 1095,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'LaGrange College.jpg'
  },
  "Langston University": {
      "Name": "Langston University",
      "Location": "Langston, OK",
      "Average SAT": 890,
      "Sports Division": "NAIA",
      "Country": "EEUU",
      "Value": {
        "ES": ["El rigor de las clases y esfuerzo adicional", "La nota Media del expediente académico", "Actividades Extraescolares"],
        "EN": ["El rigor de las clases y esfuerzo adicional", "La nota Media del expediente académico", "Actividades Extraescolares"]
      },
      "Logo": 'Langston University.jpg'
  },
  "Lehigh University": {
      "Name": "Lehigh University",
      "Location": "Bethlehem, PA",
      "Average SAT": 1347,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'Lehigh University.jpg'
  },
  "Lehman College": {
      "Name": "Lehman College",
      "Location": "New York, NY",
      "Average SAT": 957,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "La nota de SAT", "Los essays y redacciones"],
        "EN": ["La nota Media del expediente académico", "La nota de SAT", "Los essays y redacciones"]
      },
      "Logo": 'Lehman College.jpg'
  },
  "Lenoir-Rhyne University": {
      "Name": "Lenoir-Rhyne University",
      "Location": "Hickory, NC",
      "Average SAT": 1065,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "La nota de SAT", "Los essays y redacciones"],
        "EN": ["La nota Media del expediente académico", "La nota de SAT", "Los essays y redacciones"]
      },
      "Logo": 'Lenoir-Rhyne University.jpg'
  },
  "LeTourneau University": {
      "Name": "LeTourneau University",
      "Location": "Longview, TX",
      "Average SAT": 1185,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Carácter y cualidades personales", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Carácter y cualidades personales", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'LeTourneau University.jpg'
  },
  "Liberty University": {
      "Name": "Liberty University",
      "Location": "Lynchburg, VA",
      "Average SAT": 1150,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Liberty University.jpg'
  },
  "Limestone College": {
      "Name": "Limestone College",
      "Location": "Gaffney, SC",
      "Average SAT": 1054,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'Limestone College.jpg'
  },
  "Long Beach State University": {
      "Name": "Long Beach State University",
      "Location": "Long Beach, CA",
      "Average SAT": 1025,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "La nota de SAT"]
      },
      "Logo": 'Long Beach State University.jpg'
  },
  "Loyola Marymount University": {
      "Name": "Loyola Marymount University",
      "Location": "Los Angeles, CA",
      "Average SAT": 1269,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"]
      },
      "Logo": 'Loyola Marymount University.jpg'
  },
  "Luther College": {
      "Name": "Luther College",
      "Location": "Decorah, IA",
      "Average SAT": 1169,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "Las cartas de recomendación"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "Las cartas de recomendación"]
      },
      "Logo": 'Luther College.jpg'
  },
  "Manhattan College": {
      "Name": "Manhattan College",
      "Location": "New York, NY",
      "Average SAT": 1147,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'Manhattan College.jpg'
  },
  "Marist College": {
      "Name": "Marist College",
      "Location": "Poughkeepsie, NY",
      "Average SAT": 1215,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "Los essays y redacciones"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "Los essays y redacciones"]
      },
      "Logo": 'Marist College.jpg'
  },
  "Marywood University": {
      "Name": "Marywood University",
      "Location": "Scranton, PA",
      "Average SAT": 1098,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Carácter y cualidades personales", "Tu ranking de clase"],
        "EN": ["La nota Media del expediente académico", "Carácter y cualidades personales", "Tu ranking de clase"]
      },
      "Logo": 'Marywood University.jpg'
  },
  "Massachussetts Institute of Technology": {
      "Name": "Massachussetts Institute of Technology",
      "Location": "Cambridge, MA",
      "Average SAT": 1528,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["Carácter y cualidades personales", "La nota Media del expediente académico", "Los essays y redacciones"],
        "EN": ["Carácter y cualidades personales", "La nota Media del expediente académico", "Los essays y redacciones"]
      },
      "Logo": 'Massachussetts Institute of Technology.jpg'
  },
  "Mayville State University": {
      "Name": "Mayville State University",
      "Location": "Mayville, ND",
      "Average SAT": 1040,
      "Sports Division": "NAIA",
      "Country": "EEUU",
      "Value": {
        "ES": ["El rigor de las clases y esfuerzo adicional", "La nota Media del expediente académico", "Los essays y redacciones"],
        "EN": ["El rigor de las clases y esfuerzo adicional", "La nota Media del expediente académico", "Los essays y redacciones"]
      },
      "Logo": 'Mayville State University.jpg'
  },
  "Mercer University": {
      "Name": "Mercer University",
      "Location": "Macon, GA",
      "Average SAT": 1250,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Level of Applicant's Interest", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Level of Applicant's Interest", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Mercer University.jpg'
  },
  "Miami University Oxford": {
      "Name": "Miami University Oxford",
      "Location": "Oxford, OH",
      "Average SAT": 1285,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"]
      },
      "Logo": 'Miami University Oxford.jpg'
  },
  "MidAmerica Nazarene University": {
      "Name": "MidAmerica Nazarene University",
      "Location": "Olathe, KS",
      "Average SAT": 1055,
      "Sports Division": "NAIA",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "La nota de SAT", "Tu ranking de clase"],
        "EN": ["La nota Media del expediente académico", "La nota de SAT", "Tu ranking de clase"]
      },
      "Logo": 'MidAmerica Nazarene University.jpg'
  },
  "Miles College": {
      "Name": "Miles College",
      "Location": "Fairfield, AL",
      "Average SAT": 890,
      "Sports Division": "NAIA",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "La nota de SAT", "Tu ranking de clase"],
        "EN": ["La nota Media del expediente académico", "La nota de SAT", "Tu ranking de clase"]
      },
      "Logo": 'Miles College.jpg'
  },
  "Milwaukee School of Engineering": {
      "Name": "Milwaukee School of Engineering",
      "Location": "Milwaukee, WI",
      "Average SAT": 1249,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "La nota de SAT", "Actividades Extraescolares"],
        "EN": ["La nota Media del expediente académico", "La nota de SAT", "Actividades Extraescolares"]
      },
      "Logo": 'Milwaukee School of Engineering.jpg'
  },
  "Montana Tech of the University of Montana": {
      "Name": "Montana Tech of the University of Montana",
      "Location": "Butte, MT",
      "Average SAT": 1208,
      "Sports Division": "NAIA",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "La nota de SAT"]
      },
      "Logo": 'Montana Tech of the University of Montana.jpg'
  },
  "Morehead State University": {
      "Name": "Morehead State University",
      "Location": "Morehead, KY",
      "Average SAT": 933,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'Morehead State University.jpg'
  },
  "Mount Mary University": {
      "Name": "Mount Mary University",
      "Location": "Milwaukee, WI",
      "Average SAT": 1010,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "Carácter y cualidades personales"]
      },
      "Logo": 'Mount Mary University.jpg'
  },
  "New York University": {
      "Name": "New York University",
      "Location": "New York, NY",
      "Average SAT": 1382,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'New York University.jpg'
  },
  "North Carolina Central University": {
      "Name": "North Carolina Central University",
      "Location": "Durham, NC",
      "Average SAT": 865,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["El rigor de las clases y esfuerzo adicional", "La nota Media del expediente académico", "Tu ranking de clase"],
        "EN": ["El rigor de las clases y esfuerzo adicional", "La nota Media del expediente académico", "Tu ranking de clase"]
      },
      "Logo": 'North Carolina Central University.jpg'
  },
  "North Central College": {
      "Name": "North Central College",
      "Location": "Naperville, IL",
      "Average SAT": 1210,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Carácter y cualidades personales", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Carácter y cualidades personales", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'North Central College.jpg'
  },
  "Northwestern University": {
      "Name": "Northwestern University",
      "Location": "Evanston, IL",
      "Average SAT": 1490,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Northwestern University.jpg'
  },
  "Notre Dame University": {
      "Name": "Notre Dame University",
      "Location": "Nortre Dame, ID",
      "Average SAT": 1445,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["El rigor de las clases y esfuerzo adicional", "La nota Media del expediente académico", "Los essays y redacciones"],
        "EN": ["El rigor de las clases y esfuerzo adicional", "La nota Media del expediente académico", "Los essays y redacciones"]
      },
      "Logo": 'Notre Dame University.jpg'
  },
  "Ohio Northern University": {
      "Name": "Ohio Northern University",
      "Location": "Ada, OH",
      "Average SAT": 1190,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'Ohio Northern University.jpg'
  },
  "Ohio University": {
      "Name": "Ohio University",
      "Location": "Athens, OH",
      "Average SAT": 1170,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'Ohio University.jpg'
  },
  "Penn State Shuylkill": {
      "Name": "Penn State Shuylkill",
      "Location": "Shuylkill Haven, PA",
      "Average SAT": 1005,
      "Sports Division": "NAIA",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "La nota de SAT", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "La nota de SAT", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Penn State Shuylkill.jpg'
  },
  "Penn State University Park": {
      "Name": "Penn State University Park",
      "Location": "State College, PA",
      "Average SAT": 1247,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "La nota de SAT", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "La nota de SAT", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Penn State University Park.jpg'
  },
  "Peru State College": {
      "Name": "Peru State College",
      "Location": "Peru, NE",
      "Average SAT": 1040,
      "Sports Division": "NAIA",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "La nota de SAT", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "La nota de SAT", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Peru State College.jpg'
  },
  "Piedmont College": {
      "Name": "Piedmont College",
      "Location": "Demorest, GA",
      "Average SAT": 1046,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "La nota de SAT", "Los essays y redacciones"],
        "EN": ["La nota Media del expediente académico", "La nota de SAT", "Los essays y redacciones"]
      },
      "Logo": 'Piedmont College.jpg'
  },
  "Pitzer College": {
      "Name": "Pitzer College",
      "Location": "Claremont, CA",
      "Average SAT": 1394,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"]
      },
      "Logo": 'Pitzer College.jpg'
  },
  "Point University": {
      "Name": "Point University",
      "Location": "West Point, GA",
      "Average SAT": 978,
      "Sports Division": "NCCAA",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Carácter y cualidades personales", "Interés por la religión"],
        "EN": ["La nota Media del expediente académico", "Carácter y cualidades personales", "Interés por la religión"]
      },
      "Logo": 'Point University.jpg'
  },
  "Post University": {
      "Name": "Post University",
      "Location": "Waterbury, CT",
      "Average SAT": 990,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Carácter y cualidades personales", "Actividades Extraescolares"],
        "EN": ["La nota Media del expediente académico", "Carácter y cualidades personales", "Actividades Extraescolares"]
      },
      "Logo": 'Post University.jpg'
  },
  "Rennselaer Polytechnic Institute": {
      "Name": "Rennselaer Polytechnic Institute",
      "Location": "Troy, NY",
      "Average SAT": 1399,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Rennselaer Polytechnic Institute.jpg'
  },
  "Rhodes College": {
      "Name": "Rhodes College",
      "Location": "Memphis, TN",
      "Average SAT": 1315,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Rhodes College.jpg'
  },
  "Rice University": {
      "Name": "Rice University",
      "Location": "Houston, TX",
      "Average SAT": 1535,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"]
      },
      "Logo": 'Rice University.jpg'
  },
  "Rockford University": {
      "Name": "Rockford University",
      "Location": "Rockford, IL",
      "Average SAT": 950,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "La nota de SAT", "Los essays y redacciones"],
        "EN": ["La nota Media del expediente académico", "La nota de SAT", "Los essays y redacciones"]
      },
      "Logo": 'Rockford University.jpg'
  },
  "Rocky Mountain College": {
      "Name": "Rocky Mountain College",
      "Location": "Billings, MT",
      "Average SAT": 1048,
      "Sports Division": "NAIA",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Level of Applicant's Interest", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "Level of Applicant's Interest", "La nota de SAT"]
      },
      "Logo": 'Rocky Mountain College.jpg'
  },
  "Rose Hulman Institute of Technology": {
      "Name": "Rose Hulman Institute of Technology",
      "Location": "Terre Haute, IN",
      "Average SAT": 1346,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "Tu ranking de clase"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "Tu ranking de clase"]
      },
      "Logo": 'Rose Hulman Institute of Technology.jpg'
  },
  "Rowan University": {
      "Name": "Rowan University",
      "Location": "Glassboro, NJ",
      "Average SAT": 1281,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["El rigor de las clases y esfuerzo adicional", "La nota Media del expediente académico", "Los essays y redacciones"],
        "EN": ["El rigor de las clases y esfuerzo adicional", "La nota Media del expediente académico", "Los essays y redacciones"]
      },
      "Logo": 'Rowan University.jpg'
  },
  "Saint Michael's College": {
      "Name": "Saint Michael's College",
      "Location": "Colchester, VT",
      "Average SAT": 1225,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": `Saint Michael's College.jpg` 
  },
  "Salve Regina University": {
      "Name": "Salve Regina University",
      "Location": "Newport, RI",
      "Average SAT": 1155,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Salve Regina University.jpg'
  },
  "San Diego State University": {
      "Name": "San Diego State University",
      "Location": "San Diego, CA",
      "Average SAT": 1185,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'San Diego State University.jpg'
  },
  "San Jose State University": {
      "Name": "San Jose State University",
      "Location": "San Jose, CA",
      "Average SAT": 1140,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'San Jose State University.jpg'
  },
  "Scripps College": {
      "Name": "Scripps College",
      "Location": "Claremont, CA",
      "Average SAT": 1384,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"]
      },
      "Logo": 'Scripps College.jpg'
  },
  "Simmons University": {
      "Name": "Simmons University",
      "Location": "Boston, MA",
      "Average SAT": 1220,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "La nota de SAT", "Los essays y redacciones"],
        "EN": ["La nota Media del expediente académico", "La nota de SAT", "Los essays y redacciones"]
      },
      "Logo": 'Simmons University.jpg'
  },
  "Smith College": {
      "Name": "Smith College",
      "Location": "Northampton, MA",
      "Average SAT": 1390,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"]
      },
      "Logo": 'Smith College.jpg'
  },
  "South Dakota School of Mines and Technology": {
      "Name": "South Dakota School of Mines and Technology",
      "Location": "Rapid City, SD",
      "Average SAT": 1220,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'South Dakota School of Mines and Technology.jpg'
  },
  "Spelman College": {
      "Name": "Spelman College",
      "Location": "Atlanta, GA",
      "Average SAT": 1146,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"]
      },
      "Logo": 'Spelman College.jpg'
  },
  "Spring Hill College": {
      "Name": "Spring Hill College",
      "Location": "Mobile, AL",
      "Average SAT": 1116,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'Spring Hill College.jpg'
  },
  "Stanford University": {
      "Name": "Stanford University",
      "Location": "Stanford, CA",
      "Average SAT": 1465,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"]
      },
      "Logo": 'Stanford University.jpg'
  },
  "Sterling College": {
      "Name": "Sterling College",
      "Location": "Craftsbury, VT",
      "Average SAT": 900,
      "Sports Division": "",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Interview"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Interview"]
      },
      "Logo": 'Sterling College.jpg'
  },
  "Sterling College Kansas": {
      "Name": "Sterling College Kansas",
      "Location": "Sterling, KS",
      "Average SAT": 1030,
      "Sports Division": "NAIA",
      "Country": "EEUU",
      "Value": {
        "ES": ["Carácter y cualidades personales", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["Carácter y cualidades personales", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'Sterling College Kansas.jpg'
  },
  "Stevens Institute of Technology": {
      "Name": "Stevens Institute of Technology",
      "Location": "Hoboken, NJ",
      "Average SAT": 1385,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'Stevens Institute of Technology.jpg'
  },
  "Stevenson University": {
      "Name": "Stevenson University",
      "Location": "Pikesville, MD",
      "Average SAT": 1108,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Las cartas de recomendación", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Las cartas de recomendación", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Stevenson University.jpg'
  },
  "SUNY College at Brockport": {
      "Name": "SUNY College at Brockport",
      "Location": "Brockport, NY",
      "Average SAT": 1095,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'SUNY College at Brockport.jpg'
  },
  "SUNY College of Agriculture and Technology at Morrisville": {
      "Name": "SUNY College of Agriculture and Technology at Morrisville",
      "Location": "Morrisville, NY",
      "Average SAT": 1010,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'SUNY College of Agriculture and Technology at Morrisville.jpg'
  },
  "SUNY University at Stonybrook": {
      "Name": "SUNY University at Stonybrook",
      "Location": "Stonybrook, NY",
      "Average SAT": 1304,
      "Sports Division": "NCAA DI ",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'SUNY University at Stonybrook.jpg'
  },
  "Swarthmore College": {
      "Name": "Swarthmore College",
      "Location": "Swarthmore, PA",
      "Average SAT": 1490,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"]
      },
      "Logo": 'Swarthmore College.jpg'
  },
  "Tabor College": {
      "Name": "Tabor College",
      "Location": "Hillsboro, KS",
      "Average SAT": 940,
      "Sports Division": "NAIA",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "La nota de SAT"]
      },
      "Logo": 'Tabor College.jpg'
  },
  "Texas A&M University- Commerce": {
      "Name": "Texas A&M University- Commerce",
      "Location": "Commerce, TX",
      "Average SAT": 1025,
      "Sports Division": "NCAA DII ",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Texas A&M University- Commerce.jpg'
  },
  "Texas Christian University": {
      "Name": "Texas Christian University",
      "Location": "Forth Worth, TX",
      "Average SAT": 1230,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Texas Christian University.jpg'
  },
  "Texas College": {
      "Name": "Texas College",
      "Location": "Tyler, TX",
      "Average SAT": 1110,
      "Sports Division": "NAIA",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Texas College.jpg'
  },
  "Texas State University": {
      "Name": "Texas State University",
      "Location": "San Marcos, TX",
      "Average SAT": 1110,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["Tu ranking de clase", "La nota de SAT", "Los essays y redacciones"],
        "EN": ["Tu ranking de clase", "La nota de SAT", "Los essays y redacciones"]
      },
      "Logo": 'Texas State University.jpg'
  },
  "Texas Wesleyan University": {
      "Name": "Texas Wesleyan University",
      "Location": "Fort Worth, TX",
      "Average SAT": 1090,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'Texas Wesleyan University.jpg'
  },
  "The College of New Jersey": {
      "Name": "The College of New Jersey",
      "Location": "Ewing Township, NJ",
      "Average SAT": 1250,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["Tu ranking de clase", "Actividades Extraescolares", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["Tu ranking de clase", "Actividades Extraescolares", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'The College of New Jersey.jpg'
  },
  "The University of Chicago": {
      "Name": "The University of Chicago",
      "Location": "Chicago, IL",
      "Average SAT": 1530,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["Los essays y redacciones", "Carácter y cualidades personales", "Actividades Extraescolares"],
        "EN": ["Los essays y redacciones", "Carácter y cualidades personales", "Actividades Extraescolares"]
      },
      "Logo": 'The University of Chicago.jpg' 
  },
  "Trinity Baptist College": {
      "Name": "Trinity Baptist College",
      "Location": "Jacksonville, FL",
      "Average SAT": 950,
      "Sports Division": "NCCAA",
      "Country": "EEUU",
      "Value": {
        "ES": ["Carácter y cualidades personales", "Las cartas de recomendación", "Interés por la religión"],
        "EN": ["Carácter y cualidades personales", "Las cartas de recomendación", "Interés por la religión"]
      },
      "Logo": 'Trinity Baptist College.jpg'
  },
  "Troy University": {
      "Name": "Troy University",
      "Location": "Troy, AL",
      "Average SAT": 1075,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'Troy University.jpg'
  },
  "Tufts University": {
      "Name": "Tufts University",
      "Location": "Medford, MA",
      "Average SAT": 1475,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"]
      },
      "Logo": 'Tufts University.jpg'
  },
  "Tulane University": {
      "Name": "Tulane University",
      "Location": "New Orleans, LA",
      "Average SAT": 1410,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Tulane University.jpg'
  },
  "Tusculum College": {
      "Name": "Tusculum College",
      "Location": "Greeneville, TN",
      "Average SAT": 1010,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["El rigor de las clases y esfuerzo adicional", "La nota de SAT", "La nota Media del expediente académico"],
        "EN": ["El rigor de las clases y esfuerzo adicional", "La nota de SAT", "La nota Media del expediente académico"]
      },
      "Logo": 'Tusculum College.jpg'
  },
  "UCLA": {
      "Name": "UCLA",
      "Location": "Los Angeles, CA",
      "Average SAT": 1365,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'UCLA.jpg'
  },
  "Union College": {
      "Name": "Union College",
      "Location": "Schenectedy, NY",
      "Average SAT": 1350,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Union College.jpg'
  },
  "University of Alabama": {
      "Name": "University of Alabama",
      "Location": "Tuscaloosa, AL",
      "Average SAT": 1177,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'University of Alabama.jpg'
  },
  "University of Arizona": {
      "Name": "University of Arizona",
      "Location": "Tucson, AZ",
      "Average SAT": 1182,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'University of Arizona.jpg'
  },
  "University of California Berkeley": {
      "Name": "University of California Berkeley",
      "Location": "Berkeley, CA",
      "Average SAT": 1430,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'University of California Berkeley.jpg'
  },
  "University of California Los Angeles": {
      "Name": "University of California Los Angeles",
      "Location": "Los Angeles, CA",
      "Average SAT": 1365,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'University of California Los Angeles.jpg'
  },
  "University of Central Florida": {
      "Name": "University of Central Florida",
      "Location": "Orlando, FL",
      "Average SAT": 1235,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'University of Central Florida.jpg'
  },
  "University of Colorado": {
      "Name": "University of Colorado",
      "Location": "Boulder, CO",
      "Average SAT": 1248,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'University of Colorado.jpg'
  },
  "University of Connecticut": {
      "Name": "University of Connecticut",
      "Location": "Mansfield, CT",
      "Average SAT": 1250,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'University of Connecticut.jpg'
  },
  "University of Illinois at Urbana-Champaign": {
      "Name": "University of Illinois at Urbana-Champaign",
      "Location": "Champaign and Urbana, IL",
      "Average SAT": 1420,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "Los essays y redacciones"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "Los essays y redacciones"]
      },
      "Logo": 'University of Illinois at Urbana-Champaign.jpg'
  },
  "University of Maryland": {
      "Name": "University of Maryland",
      "Location": "College Park, MD",
      "Average SAT": 1380,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'University of Maryland.jpg'
  },
  "University of Maryland Eastern Shore": {
      "Name": "University of Maryland Eastern Shore",
      "Location": "Princess Anne, MD",
      "Average SAT": 950,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Carácter y cualidades personales", "Tu ranking de clase"],
        "EN": ["La nota Media del expediente académico", "Carácter y cualidades personales", "Tu ranking de clase"]
      },
      "Logo": 'University of Maryland Eastern Shore.jpg'
  },
  "University of Miami": {
      "Name": "University of Miami",
      "Location": "Coral Gables, FL",
      "Average SAT": 1316,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"]
      },
      "Logo": 'University of Miami.jpg'
  },
  "University of Michigan": {
      "Name": "University of Michigan",
      "Location": "Ann Arbor, MI",
      "Average SAT": 1415,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "Los essays y redacciones"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "Los essays y redacciones"]
      },
      "Logo": 'University of Michigan.jpg'
  },
  "University of Minnesota": {
      "Name": "University of Minnesota",
      "Location": "Minneapolis, MN",
      "Average SAT": 1358,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'University of Minnesota.jpg'
  },
  "University of Mobile": {
      "Name": "University of Mobile",
      "Location": "Mobile, AL",
      "Average SAT": 985,
      "Sports Division": "NAIA",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "La nota de SAT", "Tu ranking de clase"],
        "EN": ["La nota Media del expediente académico", "La nota de SAT", "Tu ranking de clase"]
      },
      "Logo": 'University of Mobile.jpg'
  },
  "University of Mount Olive": {
      "Name": "University of Mount Olive",
      "Location": "Mount Olive, NC",
      "Average SAT": 1010,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Carácter y cualidades personales", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Carácter y cualidades personales", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'University of Mount Olive.jpg'
  },
  "University of New Haven": {
      "Name": "University of New Haven",
      "Location": "New Haven, CT",
      "Average SAT": 1112,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "La nota de SAT", "Los essays y redacciones"],
        "EN": ["La nota Media del expediente académico", "La nota de SAT", "Los essays y redacciones"]
      },
      "Logo": 'University of New Haven.jpg'
  },
  "University of New Mexico": {
      "Name": "University of New Mexico",
      "Location": "Albuquerque, NM",
      "Average SAT": 1050,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'University of New Mexico.jpg'
  },
  "University of North Carolina at Chapel Hill": {
      "Name": "University of North Carolina at Chapel Hill",
      "Location": "Chapel Hill, NC",
      "Average SAT": 1347,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["Los essays y redacciones", "Carácter y cualidades personales", "Actividades Extraescolares"],
        "EN": ["Los essays y redacciones", "Carácter y cualidades personales", "Actividades Extraescolares"]
      },
      "Logo": 'University of North Carolina at Chapel Hill.jpg'
  },
  "University of Pennsylvania": {
      "Name": "University of Pennsylvania",
      "Location": "Philadelphia, PA",
      "Average SAT": 1490,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"]
      },
      "Logo": 'University of Pennsylvania.jpg'
  },
  "University of Portland": {
      "Name": "University of Portland",
      "Location": "Portland, OR",
      "Average SAT": 1260,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'University of Portland.jpg'
  },
  "University of Redlands": {
      "Name": "University of Redlands",
      "Location": "Redlands, CA",
      "Average SAT": 1210,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Las cartas de recomendación"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Las cartas de recomendación"]
      },
      "Logo": 'University of Redlands.jpg'
  },
  "University of San Diego": {
      "Name": "University of San Diego",
      "Location": "San Diego, CA",
      "Average SAT": 1263,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'University of San Diego.jpg' 
  },
  "University of Scranton": {
      "Name": "University of Scranton",
      "Location": "Scranton, PA",
      "Average SAT": 1190,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'University of Scranton.jpg'
  },
  "University of South Alabama": {
      "Name": "University of South Alabama",
      "Location": "Mobile, AL",
      "Average SAT": 1105,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "La nota de SAT", "Tu ranking de clase"],
        "EN": ["La nota Media del expediente académico", "La nota de SAT", "Tu ranking de clase"]
      },
      "Logo": 'University of South Alabama.jpg'
  },
  "University of South Florida": {
      "Name": "University of South Florida",
      "Location": "Tampa, FL",
      "Average SAT": 1230,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "La nota de SAT", "Tu ranking de clase"],
        "EN": ["La nota Media del expediente académico", "La nota de SAT", "Tu ranking de clase"]
      },
      "Logo": 'University of South Florida.jpg'
  },
  "University of Southern California": {
      "Name": "University of Southern California",
      "Location": "Los Angeles, CA",
      "Average SAT": 1400,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Las cartas de recomendación"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Las cartas de recomendación"]
      },
      "Logo": 'University of Southern California.jpg'
  },
  "University of Texas Austin": {
      "Name": "University of Texas Austin",
      "Location": "Austin, TX",
      "Average SAT": 1275,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"]
      },
      "Logo": 'University of Texas Austin.jpg'
  },
  "University of the Cumberlands": {
      "Name": "University of the Cumberlands",
      "Location": "Williamsburg, KY",
      "Average SAT": 1080,
      "Sports Division": "NAIA",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "La nota de SAT", "Los essays y redacciones"],
        "EN": ["La nota Media del expediente académico", "La nota de SAT", "Los essays y redacciones"]
      },
      "Logo": 'University of the Cumberlands.jpg'
  },
  "University of the Sciences in Philadelphia": {
      "Name": "University of the Sciences in Philadelphia",
      "Location": "Philadelphia, PA",
      "Average SAT": 1260,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "La nota de SAT", "Los essays y redacciones"],
        "EN": ["La nota Media del expediente académico", "La nota de SAT", "Los essays y redacciones"]
      },
      "Logo": 'University of the Sciences in Philadelphia.jpg'
  },
  "University of Tulsa": {
      "Name": "University of Tulsa",
      "Location": "Tulsa, OK",
      "Average SAT": 1280,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'University of Tulsa.jpg'
  },
  "University of Virginia": {
      "Name": "University of Virginia",
      "Location": "Charlottesville, VA",
      "Average SAT": 1365,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Carácter y cualidades personales", "Tu ranking de clase"],
        "EN": ["La nota Media del expediente académico", "Carácter y cualidades personales", "Tu ranking de clase"]
      },
      "Logo": 'University of Virginia.jpg'
  },
  "University of Wyoming": {
      "Name": "University of Wyoming",
      "Location": "Lamarie, WY",
      "Average SAT": 1165,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'University of Wyoming.jpg'
  },
  "University of Bridgeport": {
      "Name": "University of Bridgeport",
      "Location": "Bridgeport, CT",
      "Average SAT": 955,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'University of Bridgeport.jpg' 
  },
  "Upper Iowa University": {
      "Name": "Upper Iowa University",
      "Location": "Fayette, IA",
      "Average SAT": 1080,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "La nota de SAT", "Tu ranking de clase"],
        "EN": ["La nota Media del expediente académico", "La nota de SAT", "Tu ranking de clase"]
      },
      "Logo": 'Upper Iowa University.jpg'
  },
  "Utah State": {
      "Name": "Utah State",
      "Location": "Logan, UT",
      "Average SAT": 1110,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "La nota de SAT", "Tu ranking de clase"],
        "EN": ["La nota Media del expediente académico", "La nota de SAT", "Tu ranking de clase"]
      },
      "Logo": 'Utah State.jpg'
  },
  "Valparaiso University": {
      "Name": "Valparaiso University",
      "Location": "Valparaiso, IN",
      "Average SAT": 1169,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'Valparaiso University.jpg'
  },
  "Vanguard University": {
      "Name": "Vanguard University",
      "Location": "Cosa Mesa, CA",
      "Average SAT": 1025,
      "Sports Division": "NAIA",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Tu ranking de clase"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Tu ranking de clase"]
      },
      "Logo": 'Vanguard University.jpg' 
  },
  "Virginia Tech": {
      "Name": "Virginia Tech",
      "Location": "Blacksburg, VA",
      "Average SAT": 1268,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Virginia Tech.jpg'
  },
  "Warner University": {
      "Name": "Warner University",
      "Location": "Lake Wales, FL",
      "Average SAT": 1030,
      "Sports Division": "NAIA",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "Level of Applicant's Interest"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "Level of Applicant's Interest"]
      },
      "Logo": 'Warner University.jpg'
  },
  "Washburn University": {
      "Name": "Washburn University",
      "Location": "Topeka, KS",
      "Average SAT": 1110,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'Washburn University.jpg'
  },
  "Washington and Lee University": {
      "Name": "Washington and Lee University",
      "Location": "Lexington, VA",
      "Average SAT": 1410,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["Carácter y cualidades personales", "Tu ranking de clase", "Actividades Extraescolares"],
        "EN": ["Carácter y cualidades personales", "Tu ranking de clase", "Actividades Extraescolares"]
      },
      "Logo": 'Washington and Lee University.jpg'
  },
  "Weber State": {
      "Name": "Weber State",
      "Location": "Ogden, UT",
      "Average SAT": 1080,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota de SAT", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota de SAT", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Weber State.jpg'
  },
  "Western Carolina University": {
      "Name": "Western Carolina University",
      "Location": "Cullowhee, NC",
      "Average SAT": 1120,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Western Carolina University.jpg'
  },
  "William Patterson University": {
      "Name": "William Patterson University",
      "Location": "Wayne, NJ",
      "Average SAT": 1025,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "Contacto con antiguos estudiantes"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "Contacto con antiguos estudiantes"]
      },
      "Logo": 'William Patterson University.jpg'
  },
  "William Penn University": {
      "Name": "William Penn University",
      "Location": "Oskaloosa, IA",
      "Average SAT": 920,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "La nota de SAT"]
      },
      "Logo": 'William Penn University.jpg'
  },
  "Yale University": {
      "Name": "Yale University",
      "Location": "New Haven, CT",
      "Average SAT": 1505,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"]
      },
      "Logo": 'Yale University.jpg'
  },
  "Young Harris College": {
      "Name": "Young Harris College",
      "Location": "Young Harris, GA",
      "Average SAT": 1075,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "La nota de SAT", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "La nota de SAT", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Young Harris College.jpg'
  },
  "Hamilton College": {
      "Name": "Hamilton College",
      "Location": "Clinton, NY",
      "Average SAT": 1450,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Hamilton College.jpg'
  },
  "Oberlin College": {
      "Name": "Oberlin College",
      "Location": "Oberlin, OH",
      "Average SAT": 1365,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Oberlin College.jpg'
  },
  "Washington University in Saint Louis": {
      "Name": "Washington University in Saint Louis",
      "Location": "St. Louis, MO",
      "Average SAT": 1505,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"]
      },
      "Logo": 'Washington University in Saint Louis.jpg'
  },
  "Trinity University": {
      "Name": "Trinity University",
      "Location": "San Antonio, TX",
      "Average SAT": 1325,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Trinity University.jpg'
  },
  "Wesleyan University": {
      "Name": "Wesleyan University",
      "Location": "Middletown, CT",
      "Average SAT": 1400,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["El rigor de las clases y esfuerzo adicional", "La nota Media del expediente académico", "Los essays y redacciones"],
        "EN": ["El rigor de las clases y esfuerzo adicional", "La nota Media del expediente académico", "Los essays y redacciones"]
      },
      "Logo": 'Wesleyan University.jpg'
  },
  "Chapman University": {
      "Name": "Chapman University",
      "Location": "Orange, CA",
      "Average SAT": 1270,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"]
      },
      "Logo": 'Chapman University.jpg'
  },
  "University of Rochester": {
      "Name": "University of Rochester",
      "Location": "Rochester, NY",
      "Average SAT": 1370,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["Carácter y cualidades personales", "Las cartas de recomendación", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["Carácter y cualidades personales", "Las cartas de recomendación", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'University of Rochester.jpg'
  },
  "Case Western Reserve University": {
      "Name": "Case Western Reserve University",
      "Location": "Cleveland, OH",
      "Average SAT": 1425,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "Actividades Extraescolares"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "Actividades Extraescolares"]
      },
      "Logo": 'Case Western Reserve University.jpg'
  },
  "Middlebury College": {
      "Name": "Middlebury College",
      "Location": "Middlebury, VT",
      "Average SAT": 1404,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Carácter y cualidades personales", "Tu ranking de clase"],
        "EN": ["La nota Media del expediente académico", "Carácter y cualidades personales", "Tu ranking de clase"]
      },
      "Logo": 'Middlebury College.jpg'
  },
  "Williams College": {
      "Name": "Williams College",
      "Location": "Williamstown, MA",
      "Average SAT": 1468,
      "Sports Division": "NCAA DIII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Carácter y cualidades personales", "Tu ranking de clase"],
        "EN": ["La nota Media del expediente académico", "Carácter y cualidades personales", "Tu ranking de clase"]
      },
      "Logo": 'Williams College.jpg' 
  },
  "Seattle Pacific University": {
      "Name": "Seattle Pacific University",
      "Location": "Seattle, WA",
      "Average SAT": 1190,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Las cartas de recomendación"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Las cartas de recomendación"]
      },
      "Logo": 'Seattle Pacific University.jpg'
  },
  "University of Tampa": {
      "Name": "University of Tampa",
      "Location": "Tampa, FL",
      "Average SAT": 1180,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'University of Tampa.jpg'
  },
  "Drury University": {
      "Name": "Drury University",
      "Location": "Springfield, MO",
      "Average SAT": 1190,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "La nota de SAT", "Los essays y redacciones"],
        "EN": ["La nota Media del expediente académico", "La nota de SAT", "Los essays y redacciones"]
      },
      "Logo": 'Drury University.jpg' 
  },
  "Flagler College": {
      "Name": "Flagler College",
      "Location": "Saint Augustine, FL",
      "Average SAT": 1055,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Level of Applicant's Interest"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Level of Applicant's Interest"]
      },
      "Logo": 'Flagler College.jpg'
  },
  "Hillsdale College": {
      "Name": "Hillsdale College",
      "Location": "Hillsdale, MI",
      "Average SAT": 1350,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"]
      },
      "Logo": 'Hillsdale College.jpg'
  },
  "Florida Southern University": {
      "Name": "Florida Southern University",
      "Location": "Lakeland, FL",
      "Average SAT": 1160,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "Los essays y redacciones"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "Los essays y redacciones"]
      },
      "Logo": 'Florida Southern University.jpg'
  },
  "Stonehill College": {
      "Name": "Stonehill College",
      "Location": "Easton, MA",
      "Average SAT": 1173,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Stonehill College.jpg'
  },
  "Rollins College": {
      "Name": "Rollins College",
      "Location": "Winter Park, FL",
      "Average SAT": 1273,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "Los essays y redacciones"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "Los essays y redacciones"]
      },
      "Logo": 'Rollins College.jpg'
  },
  "Truman State University": {
      "Name": "Truman State University",
      "Location": "Kirksville, MO",
      "Average SAT": 1303,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Truman State University.jpg'
  },
  "University of California San Diego": {
      "Name": "University of California San Diego",
      "Location": "San Diego, CA",
      "Average SAT": 1257,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'University of California San Diego.jpg'
  },
  "Colorado School of Mines": {
      "Name": "Colorado School of Mines",
      "Location": "Golden, CO",
      "Average SAT": 1400,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"],
        "EN": ["La nota Media del expediente académico", "Tu ranking de clase", "El rigor de las clases y esfuerzo adicional"]
      },
      "Logo": 'Colorado School of Mines.jpg' 
  },
  "Ashland University": {
      "Name": "Ashland University",
      "Location": "Ashland, OH",
      "Average SAT": 1090,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'Ashland University.jpg'
  },
  "Augustana College": {
      "Name": "Augustana College",
      "Location": "Sioux Falls, SD",
      "Average SAT": 1240,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Las cartas de recomendación"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Las cartas de recomendación"]
      },
      "Logo": 'Augustana College.jpg'
  },
  "Bellarmine University": {
      "Name": "Bellarmine University",
      "Location": "Louisville, KY",
      "Average SAT": 1130,
      "Sports Division": "NCAA DII",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Carácter y cualidades personales", "Level of Applicant's Interest"],
        "EN": ["La nota Media del expediente académico", "Carácter y cualidades personales", "Level of Applicant's Interest"]
      },
      "Logo": 'Bellarmine University.jpg'
  },
  "Boston College": {
      "Name": "Boston College",
      "Location": "Chestnut Hill, MA",
      "Average SAT": 1405,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"],
        "EN": ["La nota Media del expediente académico", "El rigor de las clases y esfuerzo adicional", "La nota de SAT"]
      },
      "Logo": 'Boston College.jpg'
  },
  "Princeton University": {
      "Name": "Princeton University",
      "Location": "Princeton, NJ",
      "Average SAT": 1500,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"]
      },
      "Logo": 'Princeton University.jpg'
  },
  "Dartmouth College": {
      "Name": "Dartmouth College",
      "Location": "Hanover, NH",
      "Average SAT": 1478,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"]
      },
      "Logo": 'Dartmouth College.jpg'
  },
  "University of Florida": {
      "Name": "University of Florida",
      "Location": "Gainesville, FL",
      "Average SAT": 1318,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"]
      },
      "Logo": 'University of Florida.jpg'
  },
  "Vanderbilt University": {
      "Name": "Vanderbilt University",
      "Location": "Nashville, TN",
      "Average SAT": 1475,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"]
      },
      "Logo": 'Vanderbilt University.jpg'
  },
  "Wake Forest University": {
      "Name": "Wake Forest University",
      "Location": "Winston-Salem, NC",
      "Average SAT": 1350,
      "Sports Division": "NCAA DI",
      "Country": "EEUU",
      "Value": {
        "ES": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"],
        "EN": ["La nota Media del expediente académico", "Los essays y redacciones", "Carácter y cualidades personales"]
      },
      "Logo": 'Wake Forest University.jpg'
  }
}

export const universitiesEuropa = {
  "Inglaterra": {
    "Anglia Ruskin University": {
      "Name": "Anglia Ruskin University",
      "País": "UK ",
      "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Essay y Redacción Personal ",],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Essay y Redacción Personal ",]
      },
      "Logo": 'Anglia Ruskin University.jpg'
    },
    "Aston University": {
        "Name": "Aston University",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular",],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular",]
        },
        "Logo": 'Aston University.jpg'
    },
    "University of Bedfordshire": {
        "Name": "University of Bedfordshire",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Bedfordshire.jpg'
    },
    "Birbeck, University of London": {
        "Name": "Birbeck, University of London",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Birbeck, University of London.jpg'
    },
    "Birmingham City University": {
        "Name": "Birmingham City University",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 5.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 5.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Birmingham City University.jpg'
    },
    "Blackburn College": {
        "Name": "Blackburn College",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 5.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 5.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Blackburn College.jpg'
    },
    "University of Bolton": {
        "Name": "University of Bolton",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Bolton.jpg'
    },
    "Bournemouth University": {
        "Name": "Bournemouth University",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Essay y Redacción Personal "],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Essay y Redacción Personal "]
        },
        "Logo": 'Bournemouth University.jpg'
    },
    "University of Bradford": {
        "Name": "University of Bradford",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Bradford.jpg'
    },
    "University of Brighton": {
        "Name": "University of Brighton",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Essay y Redacción Personal "],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Essay y Redacción Personal "]
        },
        "Logo": 'University of Brighton.jpg'
    },
    "Brunel University London": {
        "Name": "Brunel University London",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Essay y Redacción Personal "],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Essay y Redacción Personal "]
        },
        "Logo": 'Brunel University London.jpg'
    },
    "Buckinghamshire New University": {
        "Name": "Buckinghamshire New University",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Buckinghamshire New University.jpg'
    },
    "Cardiff University": {
        "Name": "Cardiff University",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 4.0 o equivalente ", "Nota Media Global Expediente ", "Essay y Redacción Personal "],
        "EN": ["Nivel de IELTS de 4.0 o equivalente ", "Nota Media Global Expediente ", "Essay y Redacción Personal "]
        },
        "Logo": 'Cardiff University.jpg'
    },
    "University of Central Lancashire": {
        "Name": "University of Central Lancashire",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Central Lancashire.jpg'
    },
    "University of Chester": {
        "Name": "University of Chester",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Chester.jpg'
    },
    "University of Chichester": {
        "Name": "University of Chichester",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "El nivel de interés del Alumno por la Universidad "],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "El nivel de interés del Alumno por la Universidad "]
        },
        "Logo": 'University of Chichester.jpg'
    },
    "City University of London": {
        "Name": "City University of London",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 5.5 o equivalente ", "Nota Media Global Expediente ", "El Ranking de clase del Alumno"],
        "EN": ["Nivel de IELTS de 5.5 o equivalente ", "Nota Media Global Expediente ", "El Ranking de clase del Alumno"]
        },
        "Logo": 'City University of London.jpg'
    },
    "Coventry University": {
        "Name": "Coventry University",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 5.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 5.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Coventry University.jpg'
    },
    "University of Cumbria": {
        "Name": "University of Cumbria",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Cumbria.jpg'
    },
    "University of Derby": {
        "Name": "University of Derby",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Derby.jpg'
    },
    "Edge Hill University": {
        "Name": "Edge Hill University",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Edge Hill University.jpg'
    },
    "The University of Edinburgh": {
        "Name": "The University of Edinburgh",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Essay y redacción personal ", "Cartas de recomendación y referencias "],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Essay y redacción personal ", "Cartas de recomendación y referencias "]
        },
        "Logo": 'The University of Edinburgh.jpg'
    },
    "University of Essex": {
        "Name": "University of Essex",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Essex.jpg'
    },
    "Falmouth University": {
        "Name": "Falmouth University",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 7.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 7.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Falmouth University.jpg'
    },
    "Glasgow Caledonian University": {
        "Name": "Glasgow Caledonian University",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Glasgow Caledonian University.jpg'
    },
    "Goldsmiths University of London": {
        "Name": "Goldsmiths University of London",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 5.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 5.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Goldsmiths University of London.jpg'
    },
    "University of Greenwich": {
        "Name": "University of Greenwich",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Essay y Redacción Personal "],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Essay y Redacción Personal "]
        },
        "Logo": 'University of Greenwich.jpg'
    },
    "University of Hertfordshire": {
        "Name": "University of Hertfordshire",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 5.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 5.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Hertfordshire.jpg'
    },
    "University of Hull": {
        "Name": "University of Hull",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Hull.jpg'
    },
    "Imperial College London": {
        "Name": "Imperial College London",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 7.0 o equivalente ", "Essay y redacción personal ", "Entrevista Personal "],
        "EN": ["Nivel de IELTS de 7.0 o equivalente ", "Essay y redacción personal ", "Entrevista Personal "]
        },
        "Logo": 'Imperial College London.jpg'
    },
    "Keele University": {
        "Name": "Keele University",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Keele University.jpg'
    },
    "King's College London": {
        "Name": "King's College London",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": `King's College London.jpg`
    },
    "Kingston University London": {
        "Name": "Kingston University London",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "El nivel de interés del Alumno por la Universidad "],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "El nivel de interés del Alumno por la Universidad "]
        },
        "Logo": 'Kingston University London.jpg'
    },
    "University of Leeds": {
        "Name": "University of Leeds",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Essay y Redacción Personal "],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Essay y Redacción Personal "]
        },
        "Logo": 'University of Leeds.jpg'
    },
    "Leeds Beckett University": {
        "Name": "Leeds Beckett University",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Essay y redacción personal "],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Essay y redacción personal "]
        },
        "Logo": 'Leeds Beckett University.jpg'
    },
    "University of Lincoln": {
        "Name": "University of Lincoln",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Lincoln.jpg'
    },
    "The University of Liverpool": {
        "Name": "The University of Liverpool",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'The University of Liverpool.jpg'
    },
    "Liverpool Hope University": {
        "Name": "Liverpool Hope University",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Liverpool Hope University.jpg'
    },
    "London Metropolitan University": {
        "Name": "London Metropolitan University",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'London Metropolitan University.jpg'
    },
    "London School of Economics and Political Science (LSE)": {
        "Name": "London School of Economics and Political Science (LSE)",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 7.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 7.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'London School of Economics and Political Science (LSE).jpg'
    },
    "London South Bank University": {
        "Name": "London South Bank University",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'London South Bank University.jpg'
    },
    "Loughborough University": {
        "Name": "Loughborough University",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Loughborough University.jpg'
    },
    "Manchester Metropolitan University": {
        "Name": "Manchester Metropolitan University",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Manchester Metropolitan University.jpg'
    },
    "Newman University": {
        "Name": "Newman University",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Newman University.jpg'
    },
    "University of Northampton": {
        "Name": "University of Northampton",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Northampton.jpg'
    },
    "Oxford Brookes University": {
        "Name": "Oxford Brookes University",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Oxford Brookes University.jpg'
    },
    "Oxford School of Drama": {
        "Name": "Oxford School of Drama",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Oxford School of Drama.jpg'
    },
    "University of Plymouth": {
        "Name": "University of Plymouth",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Entrevista Personal "],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Entrevista Personal "]
        },
        "Logo": 'University of Plymouth.jpg'
    },
    "Queen Margaret University": {
        "Name": "Queen Margaret University",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Queen Margaret University.jpg'
    },
    "Ravensbourne University London": {
        "Name": "Ravensbourne University London",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Carácter y cualidades personales del Alumno ", "El nivel de interés del Alumno por la Universidad "],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Carácter y cualidades personales del Alumno ", "El nivel de interés del Alumno por la Universidad "]
        },
        "Logo": 'Ravensbourne University London.jpg'
    },
    "University of Reading": {
        "Name": "University of Reading",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Reading.jpg'
    },
    "University of Regina": {
        "Name": "University of Regina",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Regina.jpg'
    },
    "Robert Gordon University": {
        "Name": "Robert Gordon University",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Robert Gordon University.jpg'
    },
    "University of Roehampton": {
        "Name": "University of Roehampton",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 7.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 7.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Roehampton.jpg'
    },
    "Royal Academy of Dramatic Art": {
        "Name": "Royal Academy of Dramatic Art",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 5.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 5.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Royal Academy of Dramatic Art.jpg'
    },
    "Royal College of Art": {
        "Name": "Royal College of Art",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Royal College of Art.jpg'
    },
    "Royal Holloway, University of London": {
        "Name": "Royal Holloway, University of London",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Royal Holloway, University of London.jpg'
    },
    "Royal Welsh College of Music and Drama": {
        "Name": "Royal Welsh College of Music and Drama",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Royal Welsh College of Music and Drama.jpg'
    },
    "SAE Institute": {
        "Name": "SAE Institute",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'SAE Institute.jpg'
    },
    "University of Salford": {
        "Name": "University of Salford",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Essay y Redacción Personal "],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Essay y Redacción Personal "]
        },
        "Logo": 'University of Salford.jpg'
    },
    "University of South Wales": {
        "Name": "University of South Wales",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of South Wales.jpg'
    },
    "University of Southampton": {
        "Name": "University of Southampton",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Southampton.jpg'
    },
    "St. Mary's University, Twickenham": {
        "Name": "St. Mary's University, Twickenham",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": `St. Mary's University, Twickenham.jpg`
    },
    "Staffordshire University": {
        "Name": "Staffordshire University",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Staffordshire University.jpg'
    },
    "University of Suffolk": {
        "Name": "University of Suffolk",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Suffolk.jpg'
    },
    "University of Surrey": {
        "Name": "University of Surrey",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Surrey.jpg'
    },
    "Univesity of Sussex": {
        "Name": "Univesity of Sussex",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 7.0 o equivalente ", "Nota Media Global Expediente ", "Essay y Redacción Personal "],
        "EN": ["Nivel de IELTS de 7.0 o equivalente ", "Nota Media Global Expediente ", "Essay y Redacción Personal "]
        },
        "Logo": 'Univesity of Sussex.jpg'
    },
    "Swansea University": {
        "Name": "Swansea University",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 5.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 5.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Swansea University.jpg'
    },
    "Teesside University": {
        "Name": "Teesside University",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 5.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 5.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Teesside University.jpg'
    },
    "UCL": {
        "Name": "UCL",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Título de Bachiller 9.6", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Título de Bachiller 9.6", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'UCL.jpg'
    },
    "Ulster University": {
        "Name": "Ulster University",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Nota de SAT"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Nota de SAT"]
        },
        "Logo": 'Ulster University.jpg'
    },
    "University of East London": {
        "Name": "University of East London",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of East London.jpg'
    },
    "University of King's College": {
        "Name": "University of King's College",
        "País": "UK",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": `University of King's College.jpg`
    },
    "University of Bath": {
        "Name": "University of Bath",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Bath.jpg'
    },
    "University of Bradford": {
        "Name": "University of Bradford",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Bradford.jpg'
    },
    "University of Cambridge": {
        "Name": "University of Cambridge",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 7.5 o equivalente ", "Título de Bachiller 9.6", "El nivel de interés del Alumno por la Universidad "],
        "EN": ["Nivel de IELTS de 7.5 o equivalente ", "Título de Bachiller 9.6", "El nivel de interés del Alumno por la Universidad "]
        },
        "Logo": 'University of Cambridge.jpg'
    },
    "University of Dundee": {
        "Name": "University of Dundee",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global de 7.5 o más ", "Entrevista Personal "],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global de 7.5 o más ", "Entrevista Personal "]
        },
        "Logo": 'University of Dundee.jpg'
    },
    "University of Hertfordshire": {
        "Name": "University of Hertfordshire",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Hertfordshire.jpg'
    },
    "University of Huddersfield": {
        "Name": "University of Huddersfield",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Huddersfield.jpg'
    },
    "University of Lincoln": {
        "Name": "University of Lincoln",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Lincoln.jpg'
    },
    "University of Oxford": {
        "Name": "University of Oxford",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 7.0 o equivalente ", "Nota Media Global Expediente ", "Essay y Redacción Personal "],
        "EN": ["Nivel de IELTS de 7.0 o equivalente ", "Nota Media Global Expediente ", "Essay y Redacción Personal "]
        },
        "Logo": 'University of Oxford.jpg'
    },
    "University of Salford": {
        "Name": "University of Salford",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Essay y Redacción Personal "],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Essay y Redacción Personal "]
        },
        "Logo": 'University of Salford.jpg'
    },
    "University of South Wales": {
        "Name": "University of South Wales",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of South Wales.jpg'
    },
    "University of Sunderland": {
        "Name": "University of Sunderland",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Sunderland.jpg'
    },
    "University of Surrey": {
        "Name": "University of Surrey",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Surrey.jpg'
    },
    "University of the Highlands and Islands": {
        "Name": "University of the Highlands and Islands",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 5.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 5.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of the Highlands and Islands.jpg'
    },
    "University of Wales Trinity Saint David": {
        "Name": "University of Wales Trinity Saint David",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Wales Trinity Saint David.jpg'
    },
    "University of Warwick": {
        "Name": "University of Warwick",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Essay y redacción personal ", "El nivel de interés del Alumno por la Universidad "],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Essay y redacción personal ", "El nivel de interés del Alumno por la Universidad "]
        },
        "Logo": 'University of Warwick.jpg'
    },
    "University of Birmingham": {
        "Name": "University of Birmingham",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Birmingham.jpg'
    },
    "University of Exeter": {
        "Name": "University of Exeter",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 5.5 o equivalente ", "Título de Bachiller con nota 8 ", "Essay y Redacción Personal "],
        "EN": ["Nivel de IELTS de 5.5 o equivalente ", "Título de Bachiller con nota 8 ", "Essay y Redacción Personal "]
        },
        "Logo": 'University of Exeter.jpg'
    },
    "University of Wolverhampton": {
        "Name": "University of Wolverhampton",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 5.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 5.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Wolverhampton.jpg'
    },
    "University of York": {
      "Name": "University of York",
      "País": "UK ",
      "Value": {
      "ES": ["", "", ""],
      "EN": ["", "", ""]
      },
      "Logo": 'University of York.jpg'
  },
    "Wrexham Glyndwr University": {
        "Name": "Wrexham Glyndwr University",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Wrexham Glyndwr University.jpg'
    },
    "Writtle University College": {
        "Name": "Writtle University College",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Cartas de Recomendación y Referencias "],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Cartas de Recomendación y Referencias "]
        },
        "Logo": 'Writtle University College.jpg'
    },
    "York St. John University": {
        "Name": "York St. John University",
        "País": "UK ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'York St. John University.jpg'
    }
  },
  "Canadá": {
    "Alberta University of the Arts": {
      "Name": "Alberta University of the Arts",
      "País": "Canadá ",
      "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y  Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y  Extracurricular"]
        },
      "Logo": 'Alberta University of the Arts.jpg'
    },
    "Alexander College": {
        "Name": "Alexander College",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Alexander College.jpg'
    },
    "Athabasca University": {
        "Name": "Athabasca University",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Athabasca University.jpg'
    },
    "Aurora College": {
        "Name": "Aurora College",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Aurora College.jpg'
    },
    "Bishop's University": {
        "Name": "Bishop's University",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": `Bishop's University.jpg`
    },
    "Brandon University": {
        "Name": "Brandon University",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Brandon University.jpg'
    },
    "Brock University": {
        "Name": "Brock University",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Brock University.jpg'
    },
    "University of Calgary": {
        "Name": "University of Calgary",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Calgary.jpg'
    },
    "Camosun College": {
        "Name": "Camosun College",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Camosun College.jpg'
    },
    "Canadian Mennonite University": {
        "Name": "Canadian Mennonite University",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Canadian Mennonite University.jpg'
    },
    "Capilano University": {
        "Name": "Capilano University",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Capilano University.jpg'
    },
    "Carleton University": {
        "Name": "Carleton University",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 7.0 o equivalente ", "Nota Media Global Expediente ", "El Ranking de clase del Alumno"],
        "EN": ["Nivel de IELTS de 7.0 o equivalente ", "Nota Media Global Expediente ", "El Ranking de clase del Alumno"]
        },
        "Logo": 'Carleton University.jpg'
    },
    "Concordia University Quebec": {
        "Name": "Concordia University Quebec",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 7.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 7.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Concordia University Quebec.jpg'
    },
    "Crandall University": {
        "Name": "Crandall University",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Crandall University.jpg'
    },
    "Dalhousie University": {
        "Name": "Dalhousie University",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Rigor de Expediente Académico y Extracurricular", "Nota de SAT"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Rigor de Expediente Académico y Extracurricular", "Nota de SAT"]
        },
        "Logo": 'Dalhousie University.jpg'
    },
    "Dawson College Westmount Montreal": {
        "Name": "Dawson College Westmount Montreal",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Dawson College Westmount Montreal.jpg'
    },
    "Douglas College": {
        "Name": "Douglas College",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 7.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 7.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Douglas College.jpg'
    },
    "Durham College": {
        "Name": "Durham College",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Durham College.jpg'
    },
    "Emily Carr University of Art and Design": {
        "Name": "Emily Carr University of Art and Design",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 5.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 5.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Emily Carr University of Art and Design.jpg'
    },
    "Fanshawe College": {
        "Name": "Fanshawe College",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 5.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 5.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Fanshawe College.jpg'
    },
    "George Brown College": {
        "Name": "George Brown College",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'George Brown College.jpg'
    },
    "Herzing College": {
        "Name": "Herzing College",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Herzing College.jpg'
    },
    "Huron University College at Western": {
        "Name": "Huron University College at Western",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Huron University College at Western.jpg'
    },
    "Lakehead University": {
        "Name": "Lakehead University",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Lakehead University.jpg'
    },
    "Lambton College": {
        "Name": "Lambton College",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Lambton College.jpg'
    },
    "Laval University": {
        "Name": "Laval University",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 5.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 5.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Laval University.jpg'
    },
    "Marianopolis College": {
        "Name": "Marianopolis College",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Marianopolis College.jpg'
    },
    "Max the Mutt College of Animation, Art & Design": {
        "Name": "Max the Mutt College of Animation, Art & Design",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 5.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 5.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Max the Mutt College of Animation, Art & Design.jpg'
    },
    "McGill University": {
        "Name": "McGill University",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 7.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 7.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'McGill University.jpg'
    },
    "McMaster University": {
        "Name": "McMaster University",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Carácter y Cualidades Personales "],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Carácter y Cualidades Personales "]
        },
        "Logo": 'McMaster University.jpg'
    },
    "Memorial University of Newfoundland": {
        "Name": "Memorial University of Newfoundland",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Memorial University of Newfoundland.jpg'
    },
    "Mohawk College": {
        "Name": "Mohawk College",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Mohawk College.jpg'
    },
    "New Brunswick College of Craft and Design": {
        "Name": "New Brunswick College of Craft and Design",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'New Brunswick College of Craft and Design.jpg'
    },
    "Nova Scotia College of Art and Design": {
        "Name": "Nova Scotia College of Art and Design",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Nova Scotia College of Art and Design.jpg'
    },
    "Ontario College of Art and Design": {
        "Name": "Ontario College of Art and Design",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Ontario College of Art and Design.jpg'
    },
    "Ottawa School of Art": {
        "Name": "Ottawa School of Art",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Ottawa School of Art.jpg'
    },
    "Queen's University": {
        "Name": "Queen's University",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 5.5 o equivalente ", "Nota Media Global Expediente ", "Essay y Redacción Personal "],
        "EN": ["Nivel de IELTS de 5.5 o equivalente ", "Nota Media Global Expediente ", "Essay y Redacción Personal "]
        },
        "Logo": `Queen's University.jpg`
    },
    "Red River College": {
        "Name": "Red River College",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Red River College.jpg'
    },
    "Ryerson University": {
        "Name": "Ryerson University",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Nota de SAT "],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Nota de SAT "]
        },
        "Logo": 'Ryerson University.jpg'
    },
    "Saint Paul University": {
        "Name": "Saint Paul University",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Saint Paul University.jpg'
    },
    "Seneca College": {
        "Name": "Seneca College",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Seneca College.jpg'
    },
    "Sheridan College": {
        "Name": "Sheridan College",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Sheridan College.jpg'
    },
    "Simon Fraser University": {
        "Name": "Simon Fraser University",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Simon Fraser University.jpg'
    },
    "St. Stephen's University": {
        "Name": "St. Stephen's University",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": `St. Stephen's University.jpg`
    },
    "St. Thomas More College": {
        "Name": "St. Thomas More College",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'St. Thomas More College.jpg'
    },
    "The Center for Digital Media": {
        "Name": "The Center for Digital Media",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'The Center for Digital Media.jpg'
    },
    "The University of Western Ontario": {
        "Name": "The University of Western Ontario",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 7.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 7.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'The University of Western Ontario.jpg'
    },
    "Thompson Rivers University": {
        "Name": "Thompson Rivers University",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 7.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 7.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Thompson Rivers University.jpg'
    },
    "Toronto Film School": {
        "Name": "Toronto Film School",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 5.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 5.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Toronto Film School.jpg'
    },
    "Trent University": {
        "Name": "Trent University",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 5.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 5.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Trent University.jpg'
    },
    "University of Calgary": {
        "Name": "University of Calgary",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 7.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 7.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Calgary.jpg'
    },
    "University of Saskatchewan": {
        "Name": "University of Saskatchewan",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Saskatchewan.jpg'
    },
    "University of Toronto": {
        "Name": "University of Toronto",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Toronto.jpg'
    },
    "University of Alberta": {
        "Name": "University of Alberta",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Alberta.jpg'
    },
    "University of British Columbia": {
        "Name": "University of British Columbia",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Essay y redacción personal "],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Essay y redacción personal "]
        },
        "Logo": 'University of British Columbia.jpg'
    },
    "University of Calgary": {
        "Name": "University of Calgary",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 7.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 7.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Calgary.jpg'
    },
    "University of Guelph": {
        "Name": "University of Guelph",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Guelph.jpg'
    },
    "University of Lethbridge": {
        "Name": "University of Lethbridge",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Lethbridge.jpg'
    },
    "University of Manitoba": {
        "Name": "University of Manitoba",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 4.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 4.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Manitoba.jpg'
    },
    "University of New Brunswick": {
        "Name": "University of New Brunswick",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of New Brunswick.jpg'
    },
    "University of Northern British Columbia": {
        "Name": "University of Northern British Columbia",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Northern British Columbia.jpg'
    },
    "University of Ottawa": {
        "Name": "University of Ottawa",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 7.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 7.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Ottawa.jpg'
    },
    "University of Regina": {
        "Name": "University of Regina",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Regina.jpg'
    },
    "University of Sherbrooke": {
        "Name": "University of Sherbrooke",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Sherbrooke.jpg'
    },
    "University of the Faser Valley": {
        "Name": "University of the Faser Valley",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of the Faser Valley.jpg'
    },
    "University of Toronto": {
        "Name": "University of Toronto",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Toronto.jpg'
    },
    "University of Victoria": {
        "Name": "University of Victoria",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Victoria.jpg'
    },
    "University of Waterloo": {
        "Name": "University of Waterloo",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Essay y Redacción Personal "],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Essay y Redacción Personal "]
        },
        "Logo": 'University of Waterloo.jpg'
    },
    "University of Windsor": {
        "Name": "University of Windsor",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Windsor.jpg'
    },
    "University of Winnipeg": {
        "Name": "University of Winnipeg",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 4.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 4.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of Winnipeg.jpg'
    },
    "University of York": {
        "Name": "University of York",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 7.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 7.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'University of York.jpg'
    },
    "Vancouver Film School": {
        "Name": "Vancouver Film School",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Vancouver Film School.jpg'
    },
    "Vancouver Institute of Media Arts": {
        "Name": "Vancouver Institute of Media Arts",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Vancouver Institute of Media Arts.jpg'
    },
    "Visual College of Art and Design Vancouver": {
        "Name": "Visual College of Art and Design Vancouver",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Visual College of Art and Design Vancouver.jpg'
    },
    "Western University": {
        "Name": "Western University",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Western University.jpg'
    },
    "Wilfrid Laurier University": {
        "Name": "Wilfrid Laurier University",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 7.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 7.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Wilfrid Laurier University.jpg'
    },
    "York University": {
        "Name": "York University",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 7.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 7.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'York University.jpg'
    },
    "Yukon School of Visual Arts": {
        "Name": "Yukon School of Visual Arts",
        "País": "Canadá ",
        "Value": {
        "ES": ["Nivel de IELTS de 5.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 5.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Yukon School of Visual Arts.jpg'
    }
  },
  "Irlanda": {
    "Trinity College Dublin": {
      "Name": "Trinity College Dublin",
      "País": "Irlanda ",
      "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota de Selectividad mínima 7 ", "Notable Alto en mínimo 3 asignaturas "],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota de Selectividad mínima 7 ", "Notable Alto en mínimo 3 asignaturas "]
        },
      "Logo": 'Trinity College Dublin.jpg'
    },
    "University College Dublin": {
        "Name": "University College Dublin",
        "País": "Irlanda ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente", "Nota de Selectividad mínima 7 ", "Notable Alto en mínimo 3 asignaturas "],
        "EN": ["Nivel de IELTS de 6.5 o equivalente", "Nota de Selectividad mínima 7 ", "Notable Alto en mínimo 3 asignaturas "]
        },
        "Logo": 'University College Dublin.jpg'
    },
    "NUI Galway": {
        "Name": "NUI Galway",
        "País": "Irlanda ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente", "Nota de Selectividad mínima 7 ", "Notable Alto en mínimo 3 asignaturas"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente", "Nota de Selectividad mínima 7 ", "Notable Alto en mínimo 3 asignaturas"]
        },
        "Logo": 'NUI Galway.jpg'
    },
    "University College Cork": {
        "Name": "University College Cork",
        "País": "Irlanda ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente", "Nota de Selectividad mínima 7 ", "Notable Alto en mínimo 3 asignaturas"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente", "Nota de Selectividad mínima 7 ", "Notable Alto en mínimo 3 asignaturas"]
        },
        "Logo": 'University College Cork.jpg'
    },
    "Dublin City University": {
        "Name": "Dublin City University",
        "País": "Irlanda ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente", "Título de Bachillerato con mínimo 7 ", "Notable Alto en mínimo 3 asignaturas"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente", "Título de Bachillerato con mínimo 7 ", "Notable Alto en mínimo 3 asignaturas"]
        },
        "Logo": 'Dublin City University.jpg'
    },
    "University of Limerick": {
        "Name": "University of Limerick",
        "País": "Irlanda ",
        "Value": {
        "ES": ["Nivel de IELTS mínimo de 6.5 o equivalente", "Título de Bachillerato con mínimo 7 ", "Notable Alto en mínimo 3 asignaturas"],
        "EN": ["Nivel de IELTS mínimo de 6.5 o equivalente", "Título de Bachillerato con mínimo 7 ", "Notable Alto en mínimo 3 asignaturas"]
        },
        "Logo": 'University of Limerick.jpg'
    },
    "Maynooth University": {
        "Name": "Maynooth University",
        "País": "Irlanda ",
        "Value": {
        "ES": ["Nivel de IELTS mínimo de 6.5 o equivalente", "Título de Bachillerato con mínimo 7 ", "Notable Alto en mínimo 3 asignaturas"],
        "EN": ["Nivel de IELTS mínimo de 6.5 o equivalente", "Título de Bachillerato con mínimo 7 ", "Notable Alto en mínimo 3 asignaturas"]
        },
        "Logo": 'Maynooth University.jpg'
    },
    "Waterford Institute of Technology": {
        "Name": "Waterford Institute of Technology",
        "País": "Irlanda ",
        "Value": {
        "ES": ["Nivel de IELTS mínimo de 6.0 o equivalente", "Título de Bachillerato con mínimo 6", "Mínimo 3 asignaturas con nota 6"],
        "EN": ["Nivel de IELTS mínimo de 6.0 o equivalente", "Título de Bachillerato con mínimo 6", "Mínimo 3 asignaturas con nota 6"]
        },
        "Logo": 'Waterford Institute of Technology.jpg'
    },
    "Dun Laoghaire Institute of Art, Design and Technology": {
        "Name": "Dun Laoghaire Institute of Art, Design and Technology",
        "País": "Irlanda ",
        "Value": {
        "ES": ["Nivel de IELTS mínimo de 6.0 o equivalente", "Título de Bachillerato con mínimo 6", "Mínimo 3 asignaturas con nota 6"],
        "EN": ["Nivel de IELTS mínimo de 6.0 o equivalente", "Título de Bachillerato con mínimo 6", "Mínimo 3 asignaturas con nota 6"]
        },
        "Logo": 'Dun Laoghaire Institute of Art, Design and Technology.jpg'
    },
    "Institute of Technology Carlow": {
        "Name": "Institute of Technology Carlow",
        "País": "Irlanda ",
        "Value": {
        "ES": ["Nivel de IELTS mínimo de 6.0 o equivalente", "Título de Bachillerato con mínimo 6", "Mínimo 3 asignaturas con nota 6"],
        "EN": ["Nivel de IELTS mínimo de 6.0 o equivalente", "Título de Bachillerato con mínimo 6", "Mínimo 3 asignaturas con nota 6"]
        },
        "Logo": 'Institute of Technology Carlow.jpg'
    }
  },
  "Escocia": {
    "University of the Highlands and Islands": {
      "Name": "University of the Highlands and Islands",
      "País": "Escocia ",
      "Value": {
        "ES": ["Nivel de IELTS mínimo de 6.0 o equivalente", "Título de Bachillerato con mínimo 6.5", "Mínimo 2 asignaturas con nota 7"],
        "EN": ["Nivel de IELTS mínimo de 6.0 o equivalente", "Título de Bachillerato con mínimo 6.5", "Mínimo 2 asignaturas con nota 7"]
        },
      "Logo": 'University of the Highlands and Islands.jpg'
    },
    "University of the West of Scotland": {
        "Name": "University of the West of Scotland",
        "País": "Escocia ",
        "Value": {
        "ES": ["Nivel de IELTS mínimo de 6.0 o equivalente", "Título de Bachillerato con mínimo 6.5", "Mínimo 2 asignaturas con nota 7"],
        "EN": ["Nivel de IELTS mínimo de 6.0 o equivalente", "Título de Bachillerato con mínimo 6.5", "Mínimo 2 asignaturas con nota 7"]
        },
        "Logo": 'University of the West of Scotland.jpg'
    },
    "Abertay University": {
        "Name": "Abertay University",
        "País": "Escocia ",
        "Value": {
        "ES": ["Nivel de IELTS mínimo de 6.0 o equivalente", "Título de Bachillerato con mínimo 6.5", "Mínimo 2 asignaturas con nota 7"],
        "EN": ["Nivel de IELTS mínimo de 6.0 o equivalente", "Título de Bachillerato con mínimo 6.5", "Mínimo 2 asignaturas con nota 7"]
        },
        "Logo": 'Abertay University.jpg'
    },
    "Robert Gordon University": {
        "Name": "Robert Gordon University",
        "País": "Escocia ",
        "Value": {
        "ES": ["Nivel de IELTS mínimo de 6.0 o equivalente", "Título de Bachillerato con mínimo 6.5", "Mínimo 2 asignaturas con nota 7"],
        "EN": ["Nivel de IELTS mínimo de 6.0 o equivalente", "Título de Bachillerato con mínimo 6.5", "Mínimo 2 asignaturas con nota 7"]
        },
        "Logo": 'Robert Gordon University.jpg'
    },
    "Edinburgh Napier University": {
        "Name": "Edinburgh Napier University",
        "País": "Escocia ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente", "Título de Bachillerato con mínimo 7 ", "Mínimo 2 asignaturas con nota 7"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente", "Título de Bachillerato con mínimo 7 ", "Mínimo 2 asignaturas con nota 7"]
        },
        "Logo": 'Edinburgh Napier University.jpg'
    },
    "University of Stirling": {
        "Name": "University of Stirling",
        "País": "Escocia ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente", "Título de Bachillerato con mínimo 7 ", "Mínimo 2 asignaturas con nota 8"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente", "Título de Bachillerato con mínimo 7 ", "Mínimo 2 asignaturas con nota 8"]
        },
        "Logo": 'University of Stirling.jpg'
    },
    "Heriot-Watt University": {
        "Name": "Heriot-Watt University",
        "País": "Escocia ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente", "Bachillerato y PAU con mínimo 8 ", "Mínimo 2 asignaturas con nota 8"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente", "Bachillerato y PAU con mínimo 8 ", "Mínimo 2 asignaturas con nota 8"]
        },
        "Logo": 'Heriot-Watt University.jpg'
    },
    "University of Strathclyde": {
        "Name": "University of Strathclyde",
        "País": "Escocia ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente", "Título de Bachillerato con un 8 ", "Mínimo 2 asignaturas con nota 8"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente", "Título de Bachillerato con un 8 ", "Mínimo 2 asignaturas con nota 8"]
        },
        "Logo": 'University of Strathclyde.jpg'
    },
    "University of Aberdeen": {
        "Name": "University of Aberdeen",
        "País": "Escocia ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente", "Título de Bachillerato con un 8 ", "Mínimo 2 asignaturas con nota 9"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente", "Título de Bachillerato con un 8 ", "Mínimo 2 asignaturas con nota 9"]
        },
        "Logo": 'University of Aberdeen.jpg'
    },
    "University of Edinburgh": {
        "Name": "University of Edinburgh",
        "País": "Escocia ",
        "Value": {
        "ES": ["Nivel de IELTS de 7 o equivalente", "Título de Bachillerato con un 8.5", "Mínimo 3 asignaturas con nota 8.5"],
        "EN": ["Nivel de IELTS de 7 o equivalente", "Título de Bachillerato con un 8.5", "Mínimo 3 asignaturas con nota 8.5"]
        },
        "Logo": 'University of Edinburgh.jpg'
    },
    "University of Glasgow": {
        "Name": "University of Glasgow",
        "País": "Escocia ",
        "Value": {
        "ES": ["Nivel de IELTS de 7 o equivalente", "12 en PAU y Título de Bachillerato con un 9", "Mínimo 3 asignaturas con nota 9"],
        "EN": ["Nivel de IELTS de 7 o equivalente", "12 en PAU y Título de Bachillerato con un 9", "Mínimo 3 asignaturas con nota 9"]
        },
        "Logo": 'University of Glasgow.jpg'
    },
    "University of St Andrews": {
        "Name": "University of St Andrews",
        "País": "Escocia ",
        "Value": {
        "ES": ["Nivel de IELTS de 7 o equivalente", "12 en PAU y Título de Bachillerato con un 9", "Mínimo 3 asignaturas con nota 9"],
        "EN": ["Nivel de IELTS de 7 o equivalente", "12 en PAU y Título de Bachillerato con un 9", "Mínimo 3 asignaturas con nota 9"]
        },
        "Logo": 'University of St Andrews.jpg'
    },
    "University of Dundee": {
        "Name": "University of Dundee",
        "País": "Escocia ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global de 7.5 o más ", "Entrevista Personal "],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global de 7.5 o más ", "Entrevista Personal "]
        },
        "Logo": 'University of Dundee.jpg'
    },
    "The University of Edinburgh": {
        "Name": "The University of Edinburgh",
        "País": "Escocia ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Essay y redacción personal ", "Cartas de recomendación y referencias "],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Essay y redacción personal ", "Cartas de recomendación y referencias "]
        },
        "Logo": 'The University of Edinburgh.jpg'
    },
    "Glasgow Caledonian University": {
        "Name": "Glasgow Caledonian University",
        "País": "Escocia ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Glasgow Caledonian University.jpg'
    },
    "Queen Margaret University": {
        "Name": "Queen Margaret University",
        "País": "Escocia ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.0 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Queen Margaret University.jpg'
    },
    "Robert Gordon University": {
        "Name": "Robert Gordon University",
        "País": "Escocia ",
        "Value": {
        "ES": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"],
        "EN": ["Nivel de IELTS de 6.5 o equivalente ", "Nota Media Global Expediente ", "Rigor de Expediente Académico y Extracurricular"]
        },
        "Logo": 'Robert Gordon University.jpg'
    }
  }
}

export const satScoreAmerica = {
  "Académica": {
    "500-800": {
      "Biology": [
        "California State University Eastbay", 
        "Holy Names University", 
        "Albertus Magnus College", 
        "Alcorn State University",
        "Allen University"
      ],
      "Humanities": [
        "California State University Eastbay",
        "CIS-Endicott University",
        "University of Redlands",
        "Upper Iowa University",
        "Young Harris College"
      ],
      "Engineering": [
        "Cameron University",
        "College of the Ozarks",
        "Colorado State University Pueblo",
        "South Dakota School of Mines and Technology",
        "University of Illinois at Urbana-Champaign"
      ],
      "Business": [
        "Amridge University",
        "Ashford University",
        "City University of New York Medgar Evers College",
        "D'Youville College",
        "D'Youville College"
      ],
      "Sports Related": [
        "California State University Eastbay",
        "Defiance College",
        "Tusculum College",
        "Washburn University",
        "Marywood University"
      ],
    },
    "800-1000": {
      "Biology": [
        "Adams State University", 
        "Alabama State University", 
        "Albany State University", 
        "American International College",
        "Angelo State University"
      ],
      "Humanities": [
        "California State: Los Angeles",
        "Delaware State",
        "Brevard College",
        "University of Mount Olive",
        "Sterling College"
      ],
      "Engineering": [
        "California State University Long Beach",
        "University of Maryland Eastern Shore",
        "California State University Los Angeles",
        "SUNY College of Agriculture and Technology at Morrisville",
        "Penn State Shuylkill"
      ],
      "Business": [
        "Bowie State University",
        "Johnson C. Smith University",
        "Lenoir-Rhyne University",
        "Barton College",
        "Emmanuel College"
      ],
      "Sports Related": [
        "North Carolina Central University",
        "Limestone College",
        "Tabor College",
        "North Central College",
        "Chowan University"
      ]
    },
    "1000-1150": {
      "Biology": [
        "Abilene Christian University", 
        "Adelphi University", 
        "Adrian College", 
        "Alaska Pacific University",
        "Alfred University"
      ],
      "Humanities": [
        "Berea College",
        "North Carolina Central University",
        "Avila University",
        "Andrews University",
        "Brooklyn College"
      ],
      "Engineering": [
        "San Diego State University",
        "Hampton University",
        "Andrews University",
        "Spelman College",
        "LeTourneau University"
      ],
      "Business": [
        "Lehman College",
        "Atlanta Christian College",
        "San Diego State University",
        "Hampton University",
        "Liberty University"
      ],
      "Sports Related": [
        "Greensboro College",
        "Culver-Stockton College",
        "Vanguard University",
        "SUNY College at Brockport",
        "University of Mobile"
      ]
    },
    "1150-1250": {
      "Biology": [
        "Apalachian State University", 
        "Baylor University", 
        "Drew University", 
        "University of South Florida",
        "University of Scranton"
      ],
      "Humanities": [
        "California State Long Beach",
        "American University",
        "San Diego State University",
        "Hampton University",
        "Bowie State University"
      ],
      "Engineering": [
        "Baylor University",
        "Texas Christian University",
        "LaGrange College",
        "Penn State University Park",
        "University of New Mexico"
      ],
      "Business": [
        "Hope International University",
        "Huston-Tillotson University",
        "Texas Wesleyan University",
        "Texas A&M University- Commerce",
        "Trinity Baptist College"
      ],
      "Sports Related": [
        "Texas Wesleyan University",
        "Western Carolina University",
        "Liberty University",
        "Marist College",
        "Bethel College"
      ]
    },
    "1250-1350": {
      "Biology": [
        "University of Southern California", 
        "Bowdoin University", 
        "University of California Berkeley", 
        "Boston University",
        "New York University"
      ],
      "Humanities": [
        "Davidson College",
        "Bates College",
        "Lehigh University",
        "University of Virginia",
        "University of Miami"
      ],
      "Engineering": [
        "Bates College",
        "University of Virginia",
        "New York University",
        "Lafayette College",
        "George Washington University"
      ],
      "Business": [
        "University of North Carolina at Chapel Hill",
        "Babson College",
        "New York University",
        "Baruch College",
        "Bucknell University",
      ],
      "Sports Related": [
        "University of Miami",
        "University of Texas Austin",
        "University of Tulsa",
        "SUNY University at Stonybrook",
        "Texas State University"
      ]
    },
    "1350-1450": {
      "Biology": [
        "Stanford University", 
        "Brown University", 
        "Northwestern University", 
        "University of Pennsylvania",
        "Cornell University"
      ],
      "Humanities": [
        "University of Southern California",
        "UCLA",
        "Pitzer College",
        "University of California Berkeley",
        "Tulane University"
      ],
      "Engineering": [
        "Rennselaer Polytechnic Institute",
        "Washington and Lee University",
        "University of Michigan",
        "Bates University",
        "Boston University"
      ],
      "Business": [
        "University of Pennsylvania",
        "Tulane University",
        "Washington and Lee University",
        "Emory University",
        "Georgia Institute of Technology"
      ],
      "Sports Related": [
        "University of Michigan",
        "Boston University",
        "University of Connecticutt",
        "Penn State University Park",
        "University of Central Florida"
      ]
    },
    "1450-1650": {
      "Biology": [
        "Harvard University", 
        "Columbia University", 
        "Yale University", 
        "Massachussetts Institute of Technology",
        "The University of Chicago"
      ],
      "Humanities": [
        "Stanford University",
        "Yale University",
        "Georgetown University",
        "Duke University",
        "Rice University"
      ],
      "Engineering": [
        "Stanford University",
        "California Institute of Technology",
        "Brown University",
        "Cornell University",
        "Johns Hopkins University"
      ],
      "Business": [
        "Notre Dame University",
        "University of Pennsylvania",
        "Rice University",
        "Georgetown University",
        "University of Southern California"
      ],
      "Sports Related": [
        "New York University",
        "Simmons University",
        "Rice University",
        "Corban University",
        "University of Miami"
      ]
    },
  },
  "Deportiva": {
    "NCAA DIV 3 / NAIA DIV 2": {
      "500-1000": {
        "Biology": [
          "Briar Cliff University",
          "Langston University",
          "Texas College"
        ],
        "Humanities": [
          "Peru State College",
          "Mayville State University",
          "Illinois College"
        ],
        "Engineering": [
          "Briar Cliff University",
          "Langston University",
          "Texas College"
        ],
        "Business": [
          "Gallaudet University",
          "Brevard College",
          "Florida College"
        ],
        "Sports Related": [
          "Mount Mary University",
          "D'Youville College",
          "Dakota Wesleyan University"
        ],
      },
      "1000-1300": {
        "Biology": [
          "MidAmerica Nazarene University",
          "Rocky Mountain College",
          "University of the Cumberlands"
        ],
        "Humanities": [
          "Graceland University",
          "Indiana Wesleyan University",
          "Huntingdon College"
        ],
        "Engineering": [
          "Milwaukee School of Engineering",
          "Ohio Northern University",
          "Brigham Young University Idaho"
        ],
        "Business": [
          "Hendrix College",
          "University of Redlands",
          "Albertus Magnus College"
        ],
        "Sports Related": [
          "Buena Vista University",
          "Luther College",
          "Rockford University"
        ],
      },
      "1300-1450": {
        "Biology": [
          "Washington and Lee University",
          "Hamilton College",
          "Babson College"
        ],
        "Humanities": [
          "Oberlin College",
          "Bates College",
          "Wesleyan University"
        ],
        "Engineering": [
          "The University of Chicago",
          "University of Rochester",
          "Case Western Reserve University"
        ],
        "Business": [
          "Trinity University Texas",
          "Chapman University",
          "Washington University in Saint Louis"
        ],
        "Sports Related": [
          "Middlebury College",
          "Colby College",
          "Williams College"
        ],
      },
      "1450-1650": {
        "Biology": [
          "Amherst College",
          "Bates College",
          "Bowdoin University"
        ],
        "Humanities": [
          "Carleton College",
          "Carnegie Mellon University",
          "Colby College"
        ],
        "Engineering": [
          "California Institute of Technology",
          "Harvey Mudd College",
          "Rose Hulman Institute of Technology"
        ],
        "Business": [
          "New York University",
          "Johns Hopkins University",
          "Massachussetts Institute of Technology"
        ],
        "Sports Related": [
          "California Institute of Technology",
          "Haverford College",
          "Tufts University"
        ],
      },
    },
    "NCAA DIV 2 / NAIA DIV 1": {
      "500-1000": {
        "Biology": [
          "Edward Waters College",
          "Warner University",
          "William Penn University"
        ],
        "Humanities": [
          "Point University",
          "Dickinson State University",
          "Univesity of Bridgeport"
        ],
        "Engineering": [
          "Montana Tech of the University of Montana",
          "Rowan University",
          "Ohio Northern University"
        ],
        "Business": [
          "University of New Haven",
          "Post University",
          "Upper Iowa University"
        ],
        "Sports Related": [
          "Sterling College Kansas",
          "Miles College",
          "Spring Hill College"
        ],
      },
      "1000-1300": {
        "Biology": [
          "Piedmont College",
          "Salve Regina University",
          "Stevenson University"
        ],
        "Humanities": [
          "Huntingdon College",
          "William Patterson University",
          "Bard College"
        ],
        "Engineering": [
          "Valparaiso University",
          "Bradley University",
          "James Madison University"
        ],
        "Business": [
          "Connecticut College",
          "DePauw College",
          "Gettysburg College"
        ],
        "Sports Related": [
          "The College of New Jersey",
          "Stevens Institute of Technology",
          "Rhodes College"
        ],
      },
      "1300-1450": {
        "Biology": [
          "Seattle Pacific University",
          "University of Tampa",
          "Drury University"
        ],
        "Humanities": [
          "Flagler College",
          "Hillsdale College",
          "Florida Southern University"
        ],
        "Engineering": [
          "University of California San Diego",
          "Colorado School of Mines",
          "Ashland University"
        ],
        "Business": [
          "Stonehill College",
          "Rollins College",
          "Truman State University"
        ],
        "Sports Related": [
          "Augstana College (SD)",
          "Adelphi University",
          "Bellarmine University"
        ],
      },
      "1450-1650": {
        "Biology": [
          "Scripps College",
          "Colby College",
          "Emory University"
        ],
         "Humanities": [
          "Haverford College",
          "Johns Hopkins University",
          "Academy of Art University"
        ],
         "Engineering": [
          "Swarthmore College",
          "University of Portland",
          "Union College"
        ],
         "Business": [
          "Adams State University",
          "Franklin Pierce University",
          "Georgian Court University"
        ],
         "Sports Related": [
          "University of the Sciences in Philadelphia",
          "Saint Michael's College",
          "Bentley University"
        ],
      },
    },
    "NCAA DIV 1 / NCAA DIV 2": {
      "500-1000": {
        "Biology": [
          "Ohio University",
          "East Tennessee University",
          "Clemson University"
        ],
         "Humanities": [
          "Weber State",
          "University of Wyoming",
          "Alabama State"
        ],
         "Engineering": [
          "Virginia Tech",
          "University of Wyoming",
          "Clemson University"
        ],
         "Business": [
          "Central Connecticut State",
          "Delaware State",
          "Bethune-Cookman University"
        ],
         "Sports Related": [
          "Georgia State University",
          "Morehead State University",
          "Gambling State University"
        ],
      },
      "1000-1300": {
        "Biology": [
          "Utah State",
          "Indiana University Bloomington",
          "University of Colorado"
        ],
         "Humanities": [
          "University of Connecticut",
          "University of Minnesota",
          "University of Alabama"
        ],
         "Engineering": [
          "University of San Diego",
          "Gonzaga University",
          "Loyola Marymount University"
        ],
         "Business": [
          "Jacksonville State University",
          "University of South Alabama",
          "Troy University"
        ],
         "Sports Related": [
          "University of Arizona",
          "University of Arizona",
          "Colorado State University"
        ],
      },
      "1300-1450": {
        "Biology": [
          "Duke University",
          "Dartmouth College",
          "Wake Forest University"
        ],
         "Humanities": [
          "University of Virginia",
          "Vanderbilt University",
          "University of North Carolina Chapel Hill"
        ],
         "Engineering": [
          "Princeton University",
          "Dartmouth College",
          "Northwestern University"
        ],
         "Business": [
          "Notre Dame University",
          "Georgetown University",
          "Boston College"
        ],
         "Sports Related": [
          "Davidson College",
          "University of Florida",
          "Rice University"
        ],
      },
      "1450-1650": {
        "Biology": [
          "Stanford University",
          "University of California Los Angeles",
          "University of Southern California"
        ],
         "Humanities": [
          "University of Texas Austin",
          "University of Michigan",
          "Columbia University"
        ],
         "Engineering": [
          "Stanford University",
          "Yale University",
          "University of Michigan"
        ],
         "Business": [
          "Harvard University",
          "Yale University",
          "University of Pennsylvania"
        ],
         "Sports Related": [
          "Brown University",
          "Bucknell University",
          "Cornell University"
        ],
      },
    }
  }
}

export const satScoreEuropa = {
  "Inglaterra": {
    "5-6.99": {
      "Business": [
        "Newman University",
        "University of the Highlands and Islands",
        "Wrexham Glyndwr University",
        "Falmouth University"
      ],
      "Humanities": [
        "University of South Wales",
        "Robert Gordon University",
        "London South Bank University",
        "Edge Hill University"
      ],
      "Communication": [
        "University of Bradford",
        "Buckinghamshire New University",
        "University of Northampton",
        "University of Chichester"
      ],
      "Arts": [
        "York St. John University",
        "University of Bedfordshire",
        "University of Bolton"
      ],
      "Design": [
        "SAE Institute",
        "Blackburn College",
        "London Metropolitan University"
      ],
      "Biology": [
        "University of Cumbria",
        "University of Suffolk",
        "University of Wolverhampton"
      ],
      "Mathematics": [
        "Kingston University London",
        "University of Chichester",
        "University of Central Lancashire"
      ],
      "Engineering": [
        "University of Northampton",
        "University of Wolverhampton",
        "University of Bolton",
        "Glasgow Caledonian University"
      ],
      "Sport": [
        "University of Plymouth",
        "University of Cumbria",
        "University of Wales Trinity Saint David",
        "University of Bolton"
      ],
      "Juridical": [
        "UAX"
      ]
    },
    "7-7.99": {
      "Business": [
        "Leeds Beckett university",
        "University of South Wales",
        "University of Greenwich",
        "Liverpool Hope University"
      ],
      "Humanities": [
        "Manchester Metropolitan University",
        "University of Lincoln",
        "University of Huddersfield",
        "University of Hertfordshire"
      ],
      "Communication": [
        "University of Derby",
        "Staffordshire University",
        "Bournemouth University",
        "University of Roehampton"
      ],
      "Arts": [
        "Queen Margaret University",
        "Birmingham City University",
        "University of South Wales"
      ],
      "Design": [
        "Ravensbourne University London",
        "University of East London",
        "University of Salford"
      ],
      "Biology": [
        "University of Greenwich",
        "Brunel University London",
        "University of Hertfordshire"
      ],
      "Mathematics": [
        "Swansea University",
        "University of Reading",
        "University of Hull"
      ],
      "Engineering": [
        "City, University of London",
        "Coventry University",
        "University of Bradford",
        "University of Hull"
      ],
      "Sport": [
        "York St. John University",
        "University of South Wales",
        "Writtle University College",
        "University of Bedfordshire"
      ],
      "Juridical": [
        "UAX"
      ]
    },
    "8-8.99": {
      "Business": [
        "City University of London",
        "The University of Edinburgh",
        "Cardiff University",
        "Univesity of Sussex"
      ],
      "Humanities": [
        "Birbeck, University of London",
        "Anglia Ruskin University",
        "University of Surrey",
        "Royal Holloway, University of London"
      ],
      "Communication": [
        "The University of Liverpool",
        "University of Surrey",
        "Oxford Brookes University",
        "Keele University"
      ],
      "Arts": [
        "University of Lincoln",
        "Ulster University",
        "Coventry University"
      ],
      "Design": [
        "Staffordshire University",
        "University of Bradford",
        "University of Sunderland"
      ],
      "Biology": [
        "Aston University",
        "University of Leeds",
        "University of Southampton"
      ],
      "Mathematics": [
        "University of Essex",
        "University of Surrey",
        "University of York"
      ],
      "Engineering": [
        "Aston University",
        "University of Central Lancashire",
        "Cardiff University",
        "Ulster University"
      ],
      "Sport": [
        "University of Brighton",
        "St. Mary's University, Twickenham",
        "University of Salford",
        "University of Chester"
      ],
      "Juridical": [
        "UAX"
      ]
    },
    "9-11": {
      "Business": [
        "University of Oxford",
        "University of Bath",
        "Loughborough University",
        "University of Warwick"
      ],
      "Humanities": [
        "University of Cambridge",
        "University of Oxford",
        "UCL",
        "King's College London"
      ],
      "Communication": [
        "London School of Economics and Political Science (LSE)",
        "Goldsmiths University of London",
        "Cardiff University",
        "King's College London"
      ],
      "Arts": [
        "Royal Academy of Dramatic Art",
        "Royal Welsh College of Music and Drama",
        "Oxford School of Drama"
      ],
      "Design": [
        "Bournemouth University",
        "Royal College of Art",
        "Teesside University"
      ],
      "Biology": [
        "University of Dundee",
        "University of Cambridge",
        "University of York"
      ],
      "Mathematics": [
        "University of Oxford",
        "University of Cambridge",
        "Imperial College London"
      ],
      "Engineering": [
        "University of Cambridge",
        "University of Oxford",
        "Imperial College of London",
        "University of Warwick"
      ],
      "Sport": [
        "Loughborough University",
        "Unviersity of Birmingham",
        "University of Bath",
        "Unviersity of Exeter"
      ],
      "Juridical": [
        "UAX"
      ]
    }
  },
  "Canadá": {
    "5-6.99": {
      "Business": [
        "Alexander College",
        "Huron University College at Western",
        "Aurora College",
        "St. Thomas More College"
      ],
      "Humanities": [
        "University of Lethbridge",
        "University of Northern British Columbia",
        "University of Windsor",
        "Ryerson Univesrity"
      ],
      "Communication": [
        "Brandon University",
        "Saint Paul University",
        "Athabasca University",
        "University of Manitoba"
      ],
      "Arts": [
        "Yukon School of Visual Arts",
        "New Brunswick College of Craft and Design",
        "Ottawa School of Art"
      ],
      "Design": [
        "Ontario College of Art and Design",
        "Fanshawe College",
        "Herzing College"
      ],
      "Biology": [
        "Queen's University",
        "University of Victoria",
        "University of Sherbrooke"
      ],
      "Mathematics": [
        "Brandon University",
        "University of Alberta",
        "Simon Frasier University"
      ],
      "Engineering": [
        "University of Windsor",
        "University of Sherbrooke",
        "University of Regina",
        "Simon Frasier University"
      ],
      "Sport": [
        "University of Victoria",
        "Western University",
        "Memorial University of Newfoundland",
        "University of Calgary"
      ],
      "Juridical": [
        "UAX"
      ]
    },
    "7-7.99": {
      "Business": [
        "Camosun College",
        "Dawson College Westmount Montreal",
        "Marianopolis College",
        "Red River College"
      ],
      "Humanities": [
        "University of Calgary",
        "University of Waterloo",
        "Western University",
        "Lakehead University"
      ],
      "Communication": [
        "University of Saskatchewan",
        "University of Winnipeg",
        "University of Ottawa",
        "Canadian Mennonite University"
      ],
      "Arts": [
        "George Brown College",
        "Nova Scotia College of Art and Design",
        "Ontario College of Art and Design University"
      ],
      "Design": [
        "Capilano University",
        "Seneca College",
        "Vancouver Institute of Media Arts"
      ],
      "Biology": [
        "University of Manitoba",
        "University of Guelph",
        "University of Waterloo"
      ],
      "Mathematics": [
        "McMaster University",
        "Western University",
        "Queen's University"
      ],
      "Engineering": [
        "York University",
        "Carleton University",
        "Laval University",
        "Western University"
      ],
      "Sport": [
        "University of Lethbridge",
        "Douglas College",
        "University of New Brunswick",
        "University of Lethbridge"
      ],
      "Juridical": [
        "UAX"
      ]
    },
    "8-8.99": {
      "Business": [
        "Regina University",
        "Dalhousie University",
        "Wilfrid Laurier University",
        "University of Victoria"
      ],
      "Humanities": [
        "St. Stephen's University",
        "Crandall University",
        "McMaster University",
        "Queen's University"
      ],
      "Communication": [
        "Carleton University",
        "University of the Faser Valley",
        "Mohawk College",
        "Trent University"
      ],
      "Arts": [
        "Alberta University of the Arts",
        "University of Alberta",
        "Emily Carr University of Art and Design"
      ],
      "Design": [
        "Sheridan College",
        "Toronto Film School",
        "Max the Mutt College of Animation, Art & Design"
      ],
      "Biology": [
        "Western University",
        "Dalhousie University",
        "Laval University"
      ],
      "Mathematics": [
        "University of Calgary",
        "Laval University",
        "Concordia University Quebec"
      ],
      "Engineering": [
        "University of Calgary",
        "The University of Western Ontario",
        "McMaster University",
        "Queen's University"
      ],
      "Sport": [
        "Brock University",
        "Lakehead University",
        "University of Alberta",
        "University of Manitoba"
      ],
      "Juridical": [
        "UAX"
      ]
    },
    "9-11": {
      "Business": [
        "University of British Columbia",
        "University of Toronto",
        "University of Alberta",
        "Western University"
      ],
      "Humanities": [
        "University of Toronto",
        "McGill University",
        "University of British Columbia",
        "York University"
      ],
      "Communication": [
        "Ryerson University",
        "Carleton University",
        "University of British Columbia",
        "University of King's College"
      ],
      "Arts": [
        "University of Saskatchewan",
        "Concordia University Quebec",
        "York University Ontario"
      ],
      "Design": [
        "Visual College of Art and Design Vancouver",
        "Vancouver Film School",
        "The Center for Digital Media"
      ],
      "Biology": [
        "University of Toronto",
        "University of British Columbia",
        "McGill University"
      ],
      "Mathematics": [
        "University of British Columbia",
        "University of Toronto",
        "University of Waterloo"
      ],
      "Engineering": [
        "University of Toronto",
        "University of British Columbia",
        "McGill University",
        "University of Waterloo"
      ],
      "Sport": [
        "Bishop's University",
        "Thompsons Rivers University",
        "Lambton College",
        "Durham College"
      ],
      "Juridical": [
        "UAX"
      ]
    }
  },
  "Irlanda": {
    "5-6.99": {
      "Business": [
        "Waterford Institute of Technology",
        "Dun Laoghaire Institute of Art, Design and Technology",
        "Maynooth University"
      ],
      "Humanities": [
        "Waterford Institute of Technology",
        "Dun Laoghaire Institute of Art, Design and Technology",
        "Maynooth University"
      ],
      "Communication": [
        "Waterford Institute of Technology",
        "Dun Laoghaire Institute of Art, Design and Technology",
        "Maynooth University"
      ],
      "Arts": [
        "Dun Laoghaire Institute of Art, Design and Technology",
        "Maynooth University",
        "Maynooth University"
      ],
      "Design": [
        "Dun Laoghaire Institute of Art, Design and Technology",
        "Maynooth University",
        "Institute of Technology Carlow"
      ],
      "Biology": [
        "Maynooth University",
        "Institute of Technology Carlow"
      ],
      "Mathematics": [
        "Waterford Institute of Technology",
        "Maynooth University"
      ],
      "Engineering": [
        "Waterford Institute of Technology",
        "Maynooth University",
        "Institute of Technology Carlow"
      ],
      "Sport": [
        "Waterford Institute of Technology",
        "Maynooth University",
        "Institute of Technology Carlow"
      ],
      "Juridical": [
        "UAX"
      ]
    },
    "7-7.99": {
      "Business": [
        "Dublin City University",
        "University of Limerick",
        "Maynooth University"
      ],
      "Humanities": [
        "Dublin City University",
        "University of Limerick",
        "Maynooth University"
      ],
      "Communication": [
        "Dublin City University",
        "University of Limerick",
        "Maynooth University"
      ],
      "Arts": [
        "Dublin City University",
        "University of Limerick",
        "Maynooth University"
      ],
      "Design": [
        "Dublin City University",
        "University of Limerick",
        "Maynooth University"
      ],
      "Biology": [
        "Dublin City University",
        "University of Limerick",
        "Maynooth University"
      ],
      "Mathematics": [
        "Dublin City University",
        "University of Limerick",
        "Maynooth University"
      ],
      "Engineering": [
        "Dublin City University",
        "University of Limerick",
        "Maynooth University"
      ],
      "Sport": [
        "Dublin City University",
        "University of Limerick",
        "Maynooth University"
      ],
      "Juridical": [
        "UAX"
      ]
    },
    "8-8.99": {
      "Business": [
        "Trinity College Dublin",
        "University College Dublin",
        "University College Cork"
      ],
      "Humanities": [
        "Trinity College Dublin",
        "University College Dublin",
        "University College Cork"
      ],
      "Communication": [
        "Trinity College Dublin",
        "University College Dublin",
        "University College Cork"
      ],
      "Arts": [
        "Trinity College Dublin",
        "University College Dublin",
        "University College Cork"
      ],
      "Design": [
        "Trinity College Dublin",
        "University College Dublin",
        "University College Cork"
      ],
      "Biology": [
        "Trinity College Dublin",
        "University College Dublin",
        "University College Cork"
      ],
      "Mathematics": [
        "Trinity College Dublin",
        "University College Dublin",
        "University College Cork"
      ],
      "Engineering": [
        "Trinity College Dublin",
        "University College Dublin",
        "University College Cork"
      ],
      "Sport": [
        "Trinity College Dublin",
        "University College Dublin",
        "University College Cork"
      ],
      "Juridical": [
        "UAX"
      ]
    },
    "9-11": {
      "Business": [
        "Trinity College Dublin",
        "University College Dublin",
        "NUI Galway"
      ],
      "Humanities": [
        "Trinity College Dublin",
        "University College Dublin",
        "NUI Galway"
      ],
      "Communication": [
        "Trinity College Dublin",
        "University College Dublin",
        "NUI Galway"
      ],
      "Arts": [
        "Trinity College Dublin",
        "University College Dublin",
        "NUI Galway"
      ],
      "Design": [
        "Trinity College Dublin",
        "University College Dublin",
        "NUI Galway"
      ],
      "Biology": [
        "Trinity College Dublin",
        "University College Dublin",
        "NUI Galway"
      ],
      "Mathematics": [
        "Trinity College Dublin",
        "University College Dublin",
        "NUI Galway"
      ],
      "Engineering": [
        "Trinity College Dublin",
        "University College Dublin",
        "NUI Galway"
      ],
      "Sport": [
        "Trinity College Dublin",
        "University College Dublin",
        "NUI Galway"
      ],
      "Juridical": [
        "UAX"
      ]
    }
  },
  "Escocia": {
    "5-6.99": {
      "Business": [
        "University of the Highlands and Islands",
        "University of the West of Scotland",
        "Glasgow Caledonian University"
      ],
      "Humanities": [
        "University of the Highlands and Islands",
        "University of the West of Scotland",
        "Abertay University"
      ],
      "Communication": [
        "University of the Highlands and Islands",
        "University of the West of Scotland",
        "Robert Gordon University"
      ],
      "Arts": [
        "University of the Highlands and Islands",
        "University of the West of Scotland",
        "Robert Gordon University"
      ],
      "Design": [
        "University of the Highlands and Islands",
        "University of the West of Scotland",
        "Abertay University"
      ],
      "Biology": [
        "University of the Highlands and Islands",
        "University of the West of Scotland",
        "Edinburgh Napier University"
      ],
      "Mathematics": [
        "University of the Highlands and Islands",
        "University of the West of Scotland"
      ],
      "Engineering": [
        "University of the Highlands and Islands",
        "University of the West of Scotland",
        "Robert Gordon University"
      ],
      "Sport": [
        "University of the Highlands and Islands",
        "University of the West of Scotland",
        "Abertay University"
      ],
      "Juridical": [
        "UAX"
      ]
    },
    "7-7.99": {
      "Business": [
        "Robert Gordon University",
        "Edinburgh Napier University",
        "University of Stirling"
      ],
      "Humanities": [
        "Robert Gordon University",
        "Edinburgh Napier University",
        "University of Stirling"
      ],
      "Communication": [
        "Robert Gordon University",
        "Edinburgh Napier University",
        "University of Stirling"
      ],
      "Arts": [
        "Edinburgh Napier University",
        "University of the Highlands and Islands",
        "University of Aberdeen"
      ],
      "Design": [
        "Edinburgh Napier University",
        "University of Stirling",
        "University of Dundee"
      ],
      "Biology": [
        "Edinburgh Napier University",
        "University of Stirling",
        "University of Dundee"
      ],
      "Mathematics": [
        "University of Stirling",
        "University of Dundee",
        "University of Strathclyde"
      ],
      "Engineering": [
        "Robert Gordon University",
        "Edinburgh Napier University",
        "University of Stirling"
      ],
      "Sport": [
        "Edinburgh Napier University",
        "University of Stirling",
        "University of Strathclyde"
      ],
      "Juridical": [
        "UAX"
      ]
    },
    "8-8.99": {
      "Business": [
        "University of Dundee",
        "Heriot-Watt University",
        "University of Strathclyde"
      ],
      "Humanities": [
        "University of Dundee",
        "University of Strathclyde",
        "University of Aberdeen"
      ],
      "Communication": [
        "University of Strathclyde",
        "University of Aberdeen",
        "University of Edinburgh"
      ],
      "Arts": [
        "University of Aberdeen",
        "University of Edinburgh"
      ],
      "Design": [
        "Heriot-Watt University",
        "Edinburgh Napier University",
        "University of Edinburgh"
      ],
      "Biology": [
        "University of Dundee",
        "Heriot-Watt University",
        "University of Strathclyde"
      ],
      "Mathematics": [
        "University of Dundee",
        "Heriot-Watt University",
        "University of Strathclyde"
      ],
      "Engineering": [
        "Heriot-Watt University",
        "University of Strathclyde",
        "University of Aberdeen"
      ],
      "Sport": [
        "University of Strathclyde",
        "University of Aberdeen",
        "University of Edinburgh"
      ],
      "Juridical": [
        "UAX"
      ]
    },
    "9-11": {
      "Business": [
        "University of Aberdeen",
        "University of Edinburgh",
        "University of St Andrews"
      ],
      "Humanities": [
        "University of Edinburgh",
        "University of Glasgow",
        "University of St Andrews"
      ],
      "Communication": [
        "University of Edinburgh",
        "University of Glasgow",
        "University of St Andrews"
      ],
      "Arts": [
        "University of Edinburgh",
        "University of Glasgow",
        "University of St Andrews"
      ],
      "Design": [
        "Glasgow Caledonian University",
        "University of Edinburgh",
        "University of Dundee"
      ],
      "Biology": [
        "University of Edinburgh",
        "University of Glasgow",
        "University of St Andrews"
      ],
      "Mathematics": [
        "University of Edinburgh",
        "University of Glasgow",
        "University of St Andrews"
      ],
      "Engineering": [
        "University of Edinburgh",
        "University of Glasgow",
        "University of Aberdeen"
      ],
      "Sport": [
        "University of Stirling",
        "University of Aberdeen",
        "University of Edinburgh"
      ],
      "Juridical": [
        "UAX"
      ]
    }
  }
}

export const profesionesRama = {
  "Empresa": [
    "Marketing y Ventas",
    "Consultoría en Estrategia de inversión",
    "Contabilidad y Finanzas",
    "Análisis de Mercados",
    "RR.HH, Management y Dirección estratégica"
  ],
  "Atención a personas": [
    "Marketing y Ventas",
    "Consultoría en Estrategia de inversión",
    "Contabilidad y Finanzas",
    "Análisis de Mercados",
    "RR.HH, Management y Dirección estratégica"
  ],
  "Historia": [
    "Profesor Universitario y conferenciante",
    "Traducción e Interpretación en organismos internacionales",
    "Psicología y sociología",
    "Escritor o Editor de libros",
    "Manager en industria hotelera y Turismo"
  ],
  "Filología/Idiomas": [
    "Profesor Universitario y conferenciante",
    "Traducción e Interpretación en organismos internacionales",
    "Psicología y sociología",
    "Escritor o Editor de libros",
    "Manager en industria hotelera y Turismo"
  ],
  "Comunicación": [
    "Especialista en Redes Sociales (IG, FB, TikTok, Snapchat)",
    "Reportero Internacional",
    "Responsable de Recursos Humanos",
    "Manager Agencia de Comunicación y Contenido Digital",
    "Medios Audiovisuales (Televisión, Radio, Cine)"
  ],
  "Plásticas": [
    "Diseñador de Moda",
    "Técnico de iluminación de Espectáculos y Conciertos",
    "Intérprete",
    "Conservador de Museo y Galerías de Arte",
    "Experto en Terapia Artística"
  ],
  "Escénica/Música": [
    "Diseñador de Moda",
    "Técnico de iluminación de Espectáculos y Conciertos",
    "Intérprete",
    "Conservador de Museo y Galerías de Arte",
    "Experto en Terapia Artística"
  ],
  "Digitales": [
    "Expografía",
    "Diseñador de Juegos",
    "Editor de Películas y Vídeos",
    "Diseñador de Experiencia de Usuario",
    "Diseñador Gráfico"
  ],
  "Salud": [
    "Médico o cirujano",
    "Epidemiólgo",
    "Ingeniero Industrial en Alimentación y Fermentación",
    "Veterinaria",
    "Científico Forense"
  ],
  "Ciencias Aplicadas": [
    "Economista",
    "Consultor de Telecomunicaciones",
    "Actuario/a",
    "Ingeniero Aeronáutico o Aeroespacial",
    "Especialista Técnico"
  ],
  "Ciencias Específicas": [
    "Economista",
    "Consultor de Telecomunicaciones",
    "Actuario/a",
    "Ingeniero Aeronáutico o Aeroespacial",
    "Especialista Técnico"
  ],
  "Ingeniería/Arquitectura": [
    "Programador de Robots Industriales",
    "Desarrollador de Software",
    "Diseñador de Extremidades Biónicas y Prótesis Robóticas",
    "Diseñador de automóviles",
    "Analista Softwere Telecomunicaciones"
  ],
  "Deporte": [
    "Entrenador Personal",
    "Preparador Físico",
    "Ojeador Deportivo",
    "Especialista en Rehabilitación Cardíaca",
    "Director de Campus Deportivo"
  ],
  "Jurídica": [
    "Abogado",
    "Juez",
    "Notario",
    "Diplomático",
    "Funcionario de Administración de Justicia"
  ]
}

export const ramasGenerales = {
  'Rama jurídica, abogado, juez...': 'Ciencias Sociales',
  'Rama empresa, economista, administración de empresas...': 'Ciencias Sociales',
  'Rama atención personas, psicología, trabajo social...': 'Ciencias Sociales',
  'Rama comunicación, periodismo, marketing...': 'Ciencias Sociales',
  'Artes escénicas, música...': 'Artes',
  'Artes plásticas, bellas artes...': 'Artes',
  'Artes digitales, diseño, animación...': 'Artes',
  'Filologías e idiomas...': 'Humanidades',
  'Historia...': 'Humanidades',
  'Salud, medicina, enfermería...': 'Ciencias',
  'Ciencias aplicadas, biología...': 'Ciencias',
  'Ciencias específicas, física, química, matemáticas...': 'Ciencias',
  'Ingeniería y arquitectura': 'Ciencias',
  'Deporte': 'Deporte'
}