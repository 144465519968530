import firebase, { db } from '../../utils/firebase';
import Alumno from '../../models/Alumno';
import Colegio from '../../models/Colegio';
import InscripcionAlumno from '../../models/InscripcionAlumno';
import { setColegios } from './colegiosActions';
import { cleanText, spaces, getNumber, getNotasSat } from '../../utils/functions';
import { provincias, opcionesSedes } from '../../utils/constans';
import axios from 'axios';
export const alumnosTypes = {
  SET_ALUMNOS: 'ALUMNOS_SET_ALUMNOS',
  SET_ALUMNO_SELECTED: 'ALUMNOS_SET_ALUMNO_SELECTED',
  SET_TAB_SELECTED: 'ALUMNOS_SET_TAB_SELECTED',
  SET_SORTED_DATA: 'ALUMNOS_SET_SORTED_DATA',
  CHANGE_HEADER: 'ALUMNOS_CHANGE_HEADER',
  SET_CURRENT_ALUMNO: 'ALUMNOS_SET_CURRENT_ALUMNO',
  MODIFY_CURRENT_ALUMNO: 'ALUMNOS_MODIFY_CURRENT_ALUMNO',
  MODIFY_CURRENT_ALUMNO_EXAMEN: 'ALUMNOS_MODIFY_CURRENT_ALUMNO_EXAMEN'
};

export function setCurrentAlumno(payload) {
  return { type: alumnosTypes.SET_CURRENT_ALUMNO, payload };
}
export function modifyCurrentAlumno(payload) {
  return { type: alumnosTypes.MODIFY_CURRENT_ALUMNO, payload };
}
export function modifyCurrentAlumnoExamen(payload) {
  return { type: alumnosTypes.MODIFY_CURRENT_ALUMNO_EXAMEN, payload };
}
export function setAlumnos(payload) {
  return { type: alumnosTypes.SET_ALUMNOS, payload };
}

export const getAlumnos = ({ sort }) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let { global } = getState();
      db.child('Alumnos')
        .orderByChild(`inscripciones/${global.inscripcion}`)
        .equalTo(true)
        .on('value', snapshot => {
          let { alumnos: alumnosStore } = getState();
          let alumnos = {};
          snapshot.forEach(alumno => {
            alumnos[alumno.key] = new Alumno({ ...alumnosStore.alumnos[alumno.key], ...alumno.val() });
          });
          dispatch(setAlumnos(alumnos));
          if (sort) {
            dispatch(sortData());
          }
          return resolve();
        });
    });
  };
};

export const getInscripcionesAlumnos = () => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let { global } = getState();
      let multiPath = {};
      db.child(`Inscripciones/Alumnos/${global.inscripcion}`).on('value', snapshot => {
        let { alumnos, colegios } = getState();
        alumnos = { ...alumnos.alumnos };
        colegios = { ...colegios.colegios };

        snapshot.forEach(data => {
          let idAlumno = data.key;
          let inscripcionAlumno = data.val();
          if (alumnos[idAlumno]) {
            alumnos[idAlumno] = new Alumno({ ...alumnos[idAlumno], inscripcionAlumno });
          }
          let idColegio = inscripcionAlumno?.datosColegio?.colegio?.id;
          if (idColegio) {
            colegios[idColegio] = new Colegio({
              ...colegios[idColegio],
              alumnos: {
                ...colegios[idColegio].alumnos,
                [idAlumno]: alumnos[idAlumno].informacionAlumno.dni
              }
            });
          }
          //if (!inscripcionAlumno?.sat?.notas && inscripcionAlumno?.sat?.examen && alumnos?.[idAlumno]?.inscripcionAlumno?.isSatDone()) {
          if (inscripcionAlumno?.sat?.examen && alumnos?.[idAlumno]?.inscripcionAlumno?.isSatDone()) {
            multiPath[`Inscripciones/Alumnos/${global.inscripcion}/${idAlumno}/sat/notas`] = getNotasSat(inscripcionAlumno?.sat?.examen);
          }
        });
        if (Object.keys(multiPath).length > 0) {
          //console.log(multiPath)
          db.update(multiPath)
            .then(() => console.log('Se han corregido nuevos examenes'))
            .catch(err => console.log('Hubo errores al corregir los examenes', err));
        }
        dispatch(setAlumnos(alumnos));
        dispatch(setColegios(colegios));
        return resolve();
      });
    });
  };
};

export const getAlumno = () => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      db.child(`Alumnos/${getState().alumnos.currentAlumno.id}`).on('value', snapshot => {
        if (!snapshot.exists()) return reject();
        //dispatch(setCurrentAlumno({...getState().alumnos.currentAlumno, ...snapshot.val()}))
        dispatch(modifyCurrentAlumno({ value: { ...getState().alumnos.currentAlumno, ...snapshot.val() }, path: '' }));
        return resolve();
      });
    });
  };
};

export const getAlumnoInscripcion = () => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      db.child(`Inscripciones/Alumnos/${getState().global.inscripcion}/${getState().alumnos.currentAlumno.id}`).on('value', snapshot => {
        if (!snapshot.exists()) return reject();
        dispatch(modifyCurrentAlumno({ value: snapshot.val(), path: 'inscripcionAlumno' }));
        return resolve(snapshot.val());
      });
    });
  };
};

export const setSortedData = payload => {
  return { type: alumnosTypes.SET_SORTED_DATA, payload };
};
export const sortData = () => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { alumnos, tabSelected, tabs } = getState().alumnos;
      if (!tabs[tabSelected]) {
        return null;
      }
      const { sortBy, des, search, searchBy, filters, viewSelected } = tabs[tabSelected].header;
      let result = Object.entries(alumnos);

      if (search.trim() !== '') {
        result = result.filter(([idAlumno, alumno]) => {
          if (searchBy === 'nombreCompleto' || searchBy === 'dni') {
            return alumno.informacionAlumno[searchBy] && cleanText(alumno.informacionAlumno[searchBy]).includes(cleanText(search));
          }
          return false;
        });
      }
      result = result
        .filter(([idAlumno, alumno]) => {
          if (!(filters.tipoInscripcion.todos.checked || filters.tipoInscripcion.items[alumno.inscripcionAlumno.inscripcion.tipo].checked)) return false;
          if (!(filters.curso.todos.checked || filters.curso.items[alumno.inscripcionAlumno.cursoActual.curso].checked)) return false;
          if (!(filters.cuestionario.todos.checked || filters.cuestionario.items[alumno.inscripcionAlumno.isValidCuestionario() ? 'si' : 'no'].checked))
            return false;
          if (!(filters.bachillerato_dual.todos.checked || filters.bachillerato_dual.items[alumno.inscripcionAlumno.bachilleratoDual ? 'si' : 'no'].checked))
            return false;
          if (!(filters.endesa.todos.checked || filters.endesa.items[alumno.inscripcionAlumno.endesa ? 'si' : 'no'].checked)) return false;

          const tipoInscripcion = alumno.inscripcionAlumno.inscripcion.tipo === 'Deportiva' ? 'deportiva' : 'academica';
          const colaboraciones = alumno.inscripcionAlumno.inscripcion.beca[tipoInscripcion].colaboraciones;
          if (!(filters.autorizacion.todos.checked || filters.autorizacion.items[colaboraciones ? 'si' : 'no'].checked)) return false;

          if (viewSelected === 'deportiva' && alumno.inscripcionAlumno.inscripcion.tipo !== 'Deportiva') return false;

          return true;
        })
        .sort(([idA, a], [idB, b]) => {
          if (sortBy === 'status' && viewSelected === 'deportiva') {
            const statusA = a.inscripcionAlumno.inscripcion.beca.deportiva.evaluacionW2A.status;
            const statusB = b.inscripcionAlumno.inscripcion.beca.deportiva.evaluacionW2A.status;
            if (statusA > statusB) {
              return 1;
            }
            if (statusA < statusB) {
              return -1;
            }
          } else if (sortBy === 'deporte' && viewSelected === 'deportiva') {
            const deporteA = a.inscripcionAlumno.inscripcion.beca.deportiva.deporte;
            const deporteB = b.inscripcionAlumno.inscripcion.beca.deportiva.deporte;
            if (deporteA > deporteB) {
              return 1;
            }
            if (deporteA < deporteB) {
              return -1;
            }
          } else if (sortBy === 'club' && viewSelected === 'deportiva') {
            const clubA = a.inscripcionAlumno.inscripcion.beca.deportiva.club;
            const clubB = b.inscripcionAlumno.inscripcion.beca.deportiva.club;
            if (cleanText(clubA) > cleanText(clubB)) {
              return 1;
            }
            if (cleanText(clubA) < cleanText(clubB)) {
              return -1;
            }
          } else if (sortBy === 'nombreCompleto') {
            if (cleanText(a.informacionAlumno.nombreCompleto) > cleanText(b.informacionAlumno.nombreCompleto)) {
              return 1;
            }
            if (cleanText(a.informacionAlumno.nombreCompleto) < cleanText(b.informacionAlumno.nombreCompleto)) {
              return -1;
            }
          } else if (sortBy === 'tipoInscripcion') {
            if (a.inscripcionAlumno.inscripcion.tipo > b.inscripcionAlumno.inscripcion.tipo) {
              return 1;
            }
            if (a.inscripcionAlumno.inscripcion.tipo < b.inscripcionAlumno.inscripcion.tipo) {
              return -1;
            }
          } else if (sortBy === 'cuestionarioDone') {
            if (a.inscripcionAlumno.isValidCuestionario() > b.inscripcionAlumno.isValidCuestionario()) {
              return 1;
            }
            if (a.inscripcionAlumno.isValidCuestionario() < b.inscripcionAlumno.isValidCuestionario()) {
              return -1;
            }
          } else if (sortBy === 'endesa') {
            if (a.inscripcionAlumno.endesa > b.inscripcionAlumno.endesa) {
              return 1;
            }
            if (a.inscripcionAlumno.endesa < b.inscripcionAlumno.endesa) {
              return -1;
            }
          } else {
            if (a.informacionAlumno[sortBy] > b.informacionAlumno[sortBy]) {
              return 1;
            }
            if (a.informacionAlumno[sortBy] < b.informacionAlumno[sortBy]) {
              return -1;
            }
          }
          return 0;
        })
        .map(([idAlumno]) => idAlumno);
      if (des) {
        result.reverse();
      }
      dispatch(setSortedData(result));
      return resolve();
    });
  };
};

export const setAlumnoSelected = payload => {
  return { type: alumnosTypes.SET_ALUMNO_SELECTED, payload };
};
export const setTabSelected = payload => {
  return { type: alumnosTypes.SET_TAB_SELECTED, payload };
};
export const setNewData = payload => {
  return { type: alumnosTypes.CHANGE_HEADER, payload };
};
export const changeHeader = payload => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setNewData(payload));
      if (!payload.skipSort) dispatch(sortData());
    });
  };
};

export const saveAlumno = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let { currentAlumno } = getState().alumnos;
      currentAlumno = JSON.parse(JSON.stringify(currentAlumno));
      let multiPath = {};
      const id = await db.child(`Alumnos`).push().key;
      multiPath[`Alumnos/${id}`] = {
        ...currentAlumno,
        id,
        informacionAlumno: {
          ...currentAlumno.informacionAlumno,
          password: '',
          repetirPassword: ''
        },
        isAuthenticated: null,
        datosValidados: null,
        inscripcionAlumno: null,
        timestamp: +new Date(),
        inscripciones: {
          [getState().global.inscripcion]: true
        }
      };
      multiPath[`Inscripciones/Alumnos/${getState().global.inscripcion}/${id}`] = currentAlumno.inscripcionAlumno;
      multiPath[`Logs/Alumnos/${id}/inscripciones`] = {
        [getState().global.inscripcion]: +Date.now()
      };

      firebase
        .auth()
        .createUserWithEmailAndPassword(currentAlumno.informacionAlumno.email, currentAlumno.informacionAlumno.password)
        .then(() => {
          db.update(multiPath)
            .then(() => {
              dispatch(modifyCurrentAlumno({ value: id, path: 'id' }));
              return resolve(true);
            })
            .catch(() => {
              return reject(false);
            });
        })
        .catch(function (error) {
          console.log(error);
          return reject(false);
        });
    });
  };
};

export const saveCuestionario = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let { currentAlumno } = getState().alumnos;
      currentAlumno = JSON.parse(JSON.stringify(currentAlumno));
      let multiPath = {};
      multiPath[`Inscripciones/Alumnos/${getState().global.inscripcion}/${currentAlumno.id}/cuestionario`] = currentAlumno.inscripcionAlumno.cuestionario;
      db.update(multiPath)
        .then(() => {
          dispatch(modifyCurrentAlumno({ value: currentAlumno.inscripcionAlumno.cuestionario, path: 'inscripcionAlumno.cuestionario' }));
          return resolve(true);
        })
        .catch(() => {
          return reject(false);
        });
    });
  };
};

export const modifyAlumno = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let multiPath = {};
      let { currentAlumno } = getState().alumnos;
      currentAlumno = JSON.parse(JSON.stringify(currentAlumno));
      const id = currentAlumno.id;
      multiPath[`Alumnos/${id}/informacionAlumno`] = currentAlumno.informacionAlumno;
      multiPath[`Alumnos/${id}/informacionTutor`] = currentAlumno.informacionTutor;
      multiPath[`Inscripciones/Alumnos/${getState().global.inscripcion}/${id}/bachilleratoDual`] = currentAlumno.inscripcionAlumno.bachilleratoDual;
      multiPath[`Inscripciones/Alumnos/${getState().global.inscripcion}/${id}/endesa`] = currentAlumno.inscripcionAlumno.endesa;
      multiPath[`Inscripciones/Alumnos/${getState().global.inscripcion}/${id}/estudiarFueraSpain`] = currentAlumno.inscripcionAlumno.estudiarFueraSpain;
      multiPath[`Inscripciones/Alumnos/${getState().global.inscripcion}/${id}/tipoCarrera`] = currentAlumno.inscripcionAlumno.tipoCarrera;

      multiPath[`Inscripciones/Alumnos/${getState().global.inscripcion}/${id}/cursoActual`] = currentAlumno.inscripcionAlumno.cursoActual;
      multiPath[`Inscripciones/Alumnos/${getState().global.inscripcion}/${id}/cursoAnterior`] = currentAlumno.inscripcionAlumno.cursoAnterior;
      multiPath[`Inscripciones/Alumnos/${getState().global.inscripcion}/${id}/datosColegio`] = currentAlumno.inscripcionAlumno.datosColegio;
      multiPath[`Inscripciones/Alumnos/${getState().global.inscripcion}/${id}/ingles`] = currentAlumno.inscripcionAlumno.ingles;
      multiPath[`Inscripciones/Alumnos/${getState().global.inscripcion}/${id}/inscripcion`] = currentAlumno.inscripcionAlumno.inscripcion;
      multiPath[`Inscripciones/Alumnos/${getState().global.inscripcion}/${id}/preferenciaPaises`] = currentAlumno.inscripcionAlumno.preferenciaPaises;

      // multiPath[`Logs/Alumnos/${id}/inscripciones`]={
      //   [getState().global.inscripcion]:(+Date.now())
      // }
      db.update(multiPath)
        .then(() => {
          dispatch(modifyCurrentAlumno({ value: id, path: 'id' }));
          return resolve(true);
        })
        .catch(() => {
          return reject(false);
        });
    });
  };
};

export const downloadAlumnos = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      const { alumnos } = getState().alumnos;
      const { colegios } = getState().colegios;

      var listAlumnos = [];

      var alumnosDeportivos = [];
      var alumnosAcademicos = [];

      Object.entries(alumnos).forEach(([idAlumno, alumno]) => {
        let colegio = alumno.inscripcionAlumno.datosColegio.colegio.id ? colegios[alumno.inscripcionAlumno.datosColegio.colegio.id] : {};
        if (alumno.inscripcionAlumno.datosColegio.colegio.id === 'nuevo') {
          colegio = {
            id: 'No registrado',
            nombre: alumno.inscripcionAlumno.datosColegio.colegio.nombre,
            colegioAcademica: false,
            provincia: alumno.inscripcionAlumno.datosColegio.colegio.ciudad,
            sedeSat: alumno.inscripcionAlumno.datosColegio.colegio.sedeSat,
            tipoColegio: alumno.inscripcionAlumno.datosColegio.colegio.tipoColegio
          };
        }
        var fase = alumno.inscripcionAlumno.inscripcion.tipo === 'Deportiva' ? 'deportiva' : 'academica';

        let respuestasCuestionario = alumno.inscripcionAlumno.isValidCuestionario() ? alumno.inscripcionAlumno.getValoresCuestionario() : {};
        var becaAcademica = {
          'Tipo de actividad':
            alumno.inscripcionAlumno.inscripcion.tipo !== 'Deportiva' ? alumno.inscripcionAlumno.inscripcion.beca.academica.tipoActividad : '',
          'Nombre de actividad':
            alumno.inscripcionAlumno.inscripcion.tipo !== 'Deportiva' ? alumno.inscripcionAlumno.inscripcion.beca.academica.nombreActividad : '',
          Centro: alumno.inscripcionAlumno.inscripcion.tipo !== 'Deportiva' ? alumno.inscripcionAlumno.inscripcion.beca.academica.centroActividad : '',
          'Años dedicación':
            alumno.inscripcionAlumno.inscripcion.tipo !== 'Deportiva' ? alumno.inscripcionAlumno.inscripcion.beca.academica.dedicationYears : '',
          'Sigue practicándolo':
            alumno.inscripcionAlumno.inscripcion.tipo !== 'Deportiva'
              ? alumno.inscripcionAlumno.inscripcion.beca.academica.siguePracticando
                ? 'SI'
                : 'NO'
              : '',
          'Horas semanales de prácticas':
            alumno.inscripcionAlumno.inscripcion.tipo !== 'Deportiva' ? alumno.inscripcionAlumno.inscripcion.beca.academica.horasSemanales : '',
          'Experiencia extranjero':
            alumno.inscripcionAlumno.inscripcion.tipo !== 'Deportiva'
              ? alumno.inscripcionAlumno.inscripcion.beca.academica.experienciaExtranjero
                ? 'SI'
                : 'NO'
              : '',
          'Lugar de la experiencia':
            alumno.inscripcionAlumno.inscripcion.tipo !== 'Deportiva' ? alumno.inscripcionAlumno.inscripcion.beca.academica.lugarExperiencia : '',
          'Consistió en ':
            alumno.inscripcionAlumno.inscripcion.tipo !== 'Deportiva' ? alumno.inscripcionAlumno.inscripcion.beca.academica.objetivoExperiencia : '',
          'Meses experiencia':
            alumno.inscripcionAlumno.inscripcion.tipo !== 'Deportiva' ? alumno.inscripcionAlumno.inscripcion.beca.academica.mesesExperiencia : '',
          Voluntariado:
            alumno.inscripcionAlumno.inscripcion.tipo !== 'Deportiva' ? (alumno.inscripcionAlumno.inscripcion.beca.academica.voluntariado ? 'SI' : 'NO') : '',
          'Descripción del voluntariado:':
            alumno.inscripcionAlumno.inscripcion.tipo !== 'Deportiva' ? alumno.inscripcionAlumno.inscripcion.beca.academica.voluntariadoDescripcion : '',
          Prácticas:
            alumno.inscripcionAlumno.inscripcion.tipo !== 'Deportiva' ? (alumno.inscripcionAlumno.inscripcion.beca.academica.practicas ? 'SI' : 'NO') : '',
          'Información adicional académica':
            alumno.inscripcionAlumno.inscripcion.tipo !== 'Deportiva' ? alumno.inscripcionAlumno.inscripcion.beca.academica.informacionAdicional : '',

          [spaces(10)]: '',
          [spaces(11)]: ''
        };
        var becaDeportiva = {
          Deporte: alumno.inscripcionAlumno.inscripcion.tipo === 'Deportiva' ? alumno.inscripcionAlumno.inscripcion.beca.deportiva.deporte : '',
          Club: alumno.inscripcionAlumno.inscripcion.tipo === 'Deportiva' ? alumno.inscripcionAlumno.inscripcion.beca.deportiva.club : '',
          Categoría: alumno.inscripcionAlumno.inscripcion.tipo === 'Deportiva' ? alumno.inscripcionAlumno.inscripcion.beca.deportiva.categoria : '',
          Federado:
            alumno.inscripcionAlumno.inscripcion.tipo === 'Deportiva' ? (alumno.inscripcionAlumno.inscripcion.beca.deportiva.federado ? 'SI' : 'NO') : '',
          'Tipo deporte': alumno.inscripcionAlumno.inscripcion.tipo === 'Deportiva' ? alumno.inscripcionAlumno.inscripcion.beca.deportiva.tipoDeporte : '',
          División: alumno.inscripcionAlumno.inscripcion.tipo === 'Deportiva' ? alumno.inscripcionAlumno.inscripcion.beca.deportiva.division : '',
          'Marca/handicap/ranking':
            alumno.inscripcionAlumno.inscripcion.tipo === 'Deportiva' ? alumno.inscripcionAlumno.inscripcion.beca.deportiva.ranking : '',
          'Deportista CSD':
            alumno.inscripcionAlumno.inscripcion.tipo === 'Deportiva' ? (alumno.inscripcionAlumno.inscripcion.beca.deportiva.csd ? 'SI' : 'NO') : '',
          'Perfil deportivo':
            alumno.inscripcionAlumno.inscripcion.tipo === 'Deportiva' && alumno.inscripcionAlumno.inscripcion.beca.deportiva.informacionAdicional
              ? alumno.inscripcionAlumno.inscripcion.beca.deportiva.informacionAdicional
              : '',
          'Valoración W2A':
            alumno.inscripcionAlumno.inscripcion.tipo === 'Deportiva' && alumno.inscripcionAlumno.inscripcion.beca.deportiva.evaluacionW2A.categoria
              ? alumno.inscripcionAlumno.inscripcion.beca.deportiva.evaluacionW2A.categoria
              : '',

          [spaces(12)]: '',
          [spaces(13)]: ''
        };
        var datosAlumno = {
          Nombre: alumno.informacionAlumno.nombre,
          Apellidos: alumno.informacionAlumno.apellidos,
          'Dirección de correo': alumno.informacionAlumno.email,
          Teléfono: alumno.informacionAlumno.telefono ? alumno.informacionAlumno.telefono : '',
          DNI: alumno.informacionAlumno.dni,
          'Fecha de nacimiento': alumno.informacionAlumno.fechaNacimiento ? alumno.informacionAlumno.fechaNacimiento : '',
          Sexo: alumno.informacionAlumno.sexo ? alumno.informacionAlumno.sexo : '',
          'Autorización comunicaciones comerciales': alumno.inscripcionAlumno.inscripcion.beca[fase].colaboraciones ? 'SI' : 'NO',
          'Autorización imagen': alumno.inscripcionAlumno.inscripcion.beca[fase].imagen ? 'SI' : 'NO',

          [spaces(0)]: '',
          [spaces(1)]: '',

          Dirección: alumno.informacionAlumno.direccion ? alumno.informacionAlumno.direccion : '',
          Piso: alumno.informacionAlumno.piso ? alumno.informacionAlumno.piso : '',
          Número: alumno.informacionAlumno.numero ? alumno.informacionAlumno.numero : '',
          Letra: alumno.informacionAlumno.letra ? alumno.informacionAlumno.letra : '',
          'Código postal': alumno.informacionAlumno.codigoPostal ? alumno.informacionAlumno.codigoPostal : '',
          'Ciudad dirección': alumno.informacionAlumno.ciudad ? alumno.informacionAlumno.ciudad : '',
          Provincia: alumno.informacionAlumno.provincia ? alumno.informacionAlumno.provincia : '',

          [spaces(2)]: '',
          [spaces(3)]: '',

          'Nombre PADRE': alumno.informacionTutor.nombre,
          'Apellidos PADRE': alumno.informacionTutor.apellidos,
          'Dirección de correo PADRE': alumno.informacionTutor.email,
          'Teléfono PADRE': alumno.informacionTutor.telefono,

          [spaces(4)]: '',
          [spaces(5)]: '',

          'Tipo inscripción': alumno.inscripcionAlumno.inscripcion.tipo,
          'Bachillerato dual': alumno.inscripcionAlumno.bachilleratoDual ? 'SI' : 'NO',
          'Curso actual alumno': alumno.inscripcionAlumno.cursoActual.curso,
          'Nota media global': alumno.inscripcionAlumno.cursoAnterior.mediaGlobal,
          'Nota media matemáticas': alumno.inscripcionAlumno.cursoAnterior.mediaMatematicas,
          'Nota media inglés': alumno.inscripcionAlumno.cursoAnterior.mediaIngles ? alumno.inscripcionAlumno.cursoActual.mediaIngles : '',
          'Nivel de inglés': alumno.inscripcionAlumno.ingles.nivel,
          'Titulación inglés': alumno.inscripcionAlumno.ingles.titulacion,
          'Estudiar fuera de españa?': alumno.inscripcionAlumno.estudiarFueraSpain,
          'Tipo de carrera': alumno.inscripcionAlumno.tipoCarrera,
          Cuestionario: alumno.inscripcionAlumno.isValidCuestionario() ? 'SI' : 'NO',

          [spaces(6)]: '',
          [spaces(7)]: '',

          Código: colegio.id,
          Colegio: colegio.nombre,
          '¿Es colegio ACADEMICA': colegio.colegioAcademica ? 'Si' : 'No',
          Provincia: alumno.inscripcionAlumno.datosColegio.colegio.id !== 'nuevo' ? provincias[colegio.provincia].texto : '',
          'Ciudad colegio': alumno.inscripcionAlumno.datosColegio.ciudad !== 'nuevo' ? alumno.inscripcionAlumno.datosColegio.ciudad : '',
          'Sede SAT': alumno.inscripcionAlumno.datosColegio.colegio.id !== 'nuevo' ? opcionesSedes[colegio.sede] : colegio.sede,
          'Tipo de colegio': alumno.inscripcionAlumno.datosColegio.colegio.id === 'nuevo' ? alumno.inscripcionAlumno.datosColegio.colegio.tipoColegio : '',
          [spaces(8)]: '',
          [spaces(9)]: '',

          [spaces(12)]: '',
          [spaces(13)]: '',

          'SAT Inglés': alumno.inscripcionAlumno?.sat?.notas?.ingles ? +getNumber(alumno.inscripcionAlumno.sat.notas.ingles) : '',
          'SAT Matemáticas': alumno.inscripcionAlumno?.sat?.notas?.matematicas ? +getNumber(alumno.inscripcionAlumno.sat.notas.matematicas) : '',
          'SAT Total':
            alumno.inscripcionAlumno?.sat?.notas?.ingles && alumno.inscripcionAlumno?.sat?.notas?.matematicas
              ? +getNumber(alumno.inscripcionAlumno.sat.notas.matematicas) + +getNumber(alumno.inscripcionAlumno.sat.notas.ingles)
              : ''
        };
        listAlumnos.push({ ...datosAlumno, ...becaAcademica, ...becaDeportiva, ...respuestasCuestionario });
        if (alumno.inscripcionAlumno.inscripcion.tipo === 'Deportiva') {
          alumnosDeportivos.push({ ...datosAlumno, ...becaDeportiva, ...respuestasCuestionario });
        } else {
          alumnosAcademicos.push({ ...datosAlumno, ...becaAcademica, ...respuestasCuestionario });
        }
      });

      return resolve({ listAlumnos, alumnosDeportivos, alumnosAcademicos });
    });
  };
};

export const getTime = () => {
  return async dispatch => {
    return axios
      .get('/api/global/getTimeServer')
      .then(({ data }) => {
        if (!data.date) return Promise.resolve(false);
        return Promise.resolve(data.date);
      })
      .catch(err => {
        return Promise.resolve(false);
      });
  };
};
